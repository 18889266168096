import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { commonMessages } from '../../../Constants/Constants';
import { Errors } from '../../../Component/ErrorShow';
import { SpecializationValidation } from '../../../Validation/Validation';
import { SpecializationImageFilePath, } from '../../../services/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Loading from '../../../Component/Loading';
import { SPECIALIZATION_LIST, UPDATE_SPECIALIZATION } from '../../../Repository/Specialization';
import useUploadImage from '../../../Hooks/useUploadImage';
import DeleteIcons from '../../../Component/DeleteIcons';
import { TextInput } from '../../../Component/Inputs';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { FaArrowLeft } from 'react-icons/fa6';

const Edit = () => {
    const [loading, setLoading] = useState(false)
    const [imgUrl, setImgUrl] = useState(null)
    const navigate = useNavigate()
    const { UploadImage } = useUploadImage("SPECIALIZATION", setLoading, setImgUrl)
    const FileRef = useRef()
    const { id } = useParams()

    useEffect(() => {
        getspecializationbyidData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getspecializationbyidData() {
        setLoading(true)
        let payload = {
            specializationId: id,
            activeStatus: true
        }
        try {
            let response = await SPECIALIZATION_LIST(payload)
            formik.setFieldValue('specialization', response.data.specializationName)
            setImgUrl(response.data.image)
        } catch (error) {
            if (error.message === commonMessages?.NetworkError) {
                toast.error(error.message)
            }
        }
        finally {
            setLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            specialization: ""
        },
        validationSchema: SpecializationValidation,
        onSubmit: async (values) => {
            let payload = {
                specializationName: values.specialization,
                specializationId: id,
                image: imgUrl
            }
            setLoading(true)
            try {
                let response = await UPDATE_SPECIALIZATION(payload)
                toast.success(response.message)
                formik.resetForm()
                setTimeout(() => navigate(-1), 1000)
                setLoading(false)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })

    return (
        <>
            {loading && <Loading />}
            <section className='d-flex align-items-center' >
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'> Update Specialization</h3>
            </section>
            <div className="container-fluid shadow p-3">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 m-auto">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-4 col-xl-3 col-sm-6 col-md-4  col-8">
                                    <div className="bodrerer mb-4 d-flex justify-content-center align-items-center" style={{ border: `${!imgUrl ? "2px dashed #aaaaaa" : "none"}` }}>
                                        <label htmlFor="inputTag">
                                            <AiOutlineCloudUpload className="fs-4 " />
                                            {imgUrl && <img src={SpecializationImageFilePath + imgUrl} alt="" />}
                                            <input ref={FileRef} id="inputTag" type="file" name="file" onChange={(e) => UploadImage(e, FileRef, imgUrl, id)} />
                                            <br /> Upload an Image<br />
                                            <span id="imageName"></span>
                                        </label>
                                        {imgUrl && <DeleteIcons setLoading={setLoading} setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "SPECIALIZATION", id: null }} isDeleted={true} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1" className=''>Specialization<span className='Star_Mark'>*</span></label>
                            <TextInput
                                handleChange={(e) => formik.setFieldValue("specialization", e.target.value)}
                                maxLength={50}
                                name="specialization"
                                value={formik.values.specialization}
                                type="text"
                                size='small'
                            />
                            <Errors formikfun={formik} name={"specialization"} />
                        </div>
                        <div className="col-12 text-end">
                            <Button type="submit" className="my-3 mui_button_color save_btn">update</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Edit
