import axios from "axios"
import {  CreatePinApi, ForgotPinApi, ResendOtpApi, VerifyOtpApi, changePinApi, decline, loginApi, logoutApi, validateAuthToken } from "../services/Api"
import handleError from "../utils/handleError"

export const LOGIN = async (body) => {
    try {
        let response = await axios.post(loginApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const LOGOUT = async (body) => {
    try {
        let response = await axios.post(logoutApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const FORGOT_PIN = async (body) => {
    try {
        let response = await axios.post(ForgotPinApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const RESEND_OTP = async (body) => {
    try {
        let response = await axios.post(ResendOtpApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const VERIFY_OTP = async (body) => {
    try {
        let response = await axios.post(VerifyOtpApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const CREATE_PIN = async (body) => {
    try {
        let response = await axios.post(CreatePinApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CHANGE_PIN = async (body) => {
    try {
        let response = await axios.post(changePinApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const  VALIDATE_AUTH_TOKEN = async (body) => {
    try {
        let response = await axios.post(validateAuthToken(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const  DECLINE_INTITATION = async (body) => {
    try {
        let response = await axios.post(decline(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
