import { axiosInstance } from "../Context/CreateAxios"
import {locationList, providerbasicDetails, providerbusinessDetails, providreList, updateStaffStatus, updatebasicdetails, updatebusinessdetails } from "../services/Api"
import handleError from "../utils/handleError"

export const PROVIDER_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(providreList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const LOCATION_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(locationList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_PROVIDER = async (body) => {
    try {
        let response = await axiosInstance.post(updateStaffStatus(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_BUSINESS_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(providerbusinessDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_BASIC_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(providerbasicDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const UPDATE_BASIC_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(updatebasicdetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_BUSINESS_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(updatebusinessdetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

