import React from 'react'
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;;


const CustomAutocomplete = styled(Autocomplete)({
    width: "100%",
    borderRadius: "4px",
    border: "2px solid #f0f0f0",
    minHeight: "34px",
    fontWeight: "400",
    color: "#8D8B8B",
    fontSize: "12px ",
    padding: "0",
    backgroundColor: "#fff",
    '& .MuiOutlinedInput-notchedOutline': {
        borderStyle: 'none',
    },
    '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderStyle: 'none',
        },
    },
    '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderStyle: 'none',
        },
    },
    '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
        padding: "2px 0px 2px 0px",
        fontSize: "12px",
    },
    " &:hover": {
        borderColor: "none",
    },
    // eslint-disable-next-line no-dupe-keys
    "&.Mui-focused": {
        borderColor: "#5c6bc0",
    },
    ".MuiChip-root": {
        fontSize: "12px",
        color: "#fff !important",
        backgroundColor: "#38C4B9",
        "&:focus .MuiChip-deleteIcon": {
            color: "#fff !important",
            backgroundColor: "#38C4B9",
        },
        "&:hover .MuiChip-deleteIcon": {
            color: "#fff !important",
            backgroundColor: "#38C4B9",
        }
    },
    ".MuiChip-deleteIcon": {
        color: "#fff !important"
    },
    ".MuiCheckbox-root": {
        color: "red",
    },
    ".MuiFormControlLabel-label": {
        color: " #0000FF",
    }

})
export const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f0f0f0',
            borderWidth: '2px',
        },
        '&:hover fieldset': {
            borderColor: '#f0f0f0',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#5c6bc0',
        },
        height: '34px',
        fontWeight: '400',
        color: '#8D8B8B',
        fontSize: "12px  "
    },
});

const CustomSelect = styled(Select)({
    width: "100%",
    border: "2px solid #f0f0f0",
    borderRadius: "4px",
    height: '34px',
    fontWeight: '400',
    color: '#000',
    fontSize: "12px",
    paddingLeft: "10px",
    paddingTop: "5px",
    backgroundColor: "#fff",
    '&:hover': {
        borderColor: "none",
    },
    '&.Mui-focused': {
        borderColor: "#5c6bc0",
    },
    '& .MuiInputBase-root': {
        '&:hover fieldset': {
            borderColor: 'red !important',
            pending: "0 !important"
        },
        '&.Mui-focused fieldset': {

            borderColor: 'none !important',

        },
    },
});

const CustomMenuItem = styled(MenuItem)({
    fontSize: "12px",
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TextInput = ({ readOnly, size, handleChange, maxLength, name, value, type, placeholder, disable, PointMaxValue, minValue, className }) => {
    return (
        <CustomTextField className={className} disabled={disable} onChange={handleChange} name={name} value={value ?? ""} type={type} placeholder={placeholder} size={size} variant='outlined' style={{ width: "100%" }} color='primary'
            //  onInput={(e) => { if (e.target.value.length > maxLength) e.target.value = e.target.value.slice(0, maxLength); }
            onInput={(e) => {
                let maxValue;
                if (PointMaxValue) {
                    maxValue = e.target.value.includes('.') ? PointMaxValue : maxLength;
                } else {
                    maxValue = maxLength;
                }
                e.target.maxLength = maxValue;
                if (e.target.value.length > maxValue) {
                    e.target.value = e.target.value.slice(0, maxValue);
                }
                if (type === 'number' && parseFloat(e.target.value) <= 0) {
                    e.target.value = 0;
                }
            }}
            inputProps={{ readOnly: readOnly }}
        />
    )
}
const SelectBox = ({ placeholder, value, name, handleChange, option, keys, label, menuValeu, extraLable, disabled, className }) => {
    return (
        <CustomSelect
            disabled={disabled}
            className={`w-100 ${className ? className : ""}`}
            size='small'
            input={<InputBase />}
            value={(value === null || value === undefined || value === "") ? "" : value}
            onChange={handleChange}
            name={name}
            MenuProps={MenuProps}>
            {
                option?.map((cur, inndex) => {
                    return <CustomMenuItem key={cur?.[keys]} value={cur?.[menuValeu]}>{`${cur?.[label]} ${extraLable ? cur?.[extraLable] : ""}`}</CustomMenuItem>
                })
            }
        </CustomSelect>
    )
}

const SearchAbleSelectBox = ({ extraLable, multiple, option, getOptionLabel, isOptionEqualToValue, value, handalSearchAbleSelectBox, name, groupBy, disabled, getOptionDisabled }) => {
    return (
        <CustomAutocomplete
            getOptionDisabled={getOptionDisabled}
            disabled={disabled}
            value={multiple ? value : (value?.length > 0 ? value[0] : null)}
            multiple={multiple}
            className="w-100 "
            options={option}
            groupBy={(option) => groupBy && option[groupBy]}
            getOptionLabel={(option) => getOptionLabel && option[getOptionLabel]}
            size="small"
            disableCloseOnSelect={multiple ? true : false}
            onChange={(event, newValue) => { handalSearchAbleSelectBox(event, multiple ? newValue : !newValue ? [] : [newValue], name) }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {multiple && (
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, color: "#38C4B9" }} checked={selected} />
                    )
                    }
                    {`${getOptionLabel && option[getOptionLabel]} ${extraLable ? option?.[extraLable] : ""} `}
                </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label=" "
                    style={{ color: "red" }}
                    InputLabelProps={{ shrink: false }}
                />
            )}
            isOptionEqualToValue={(option, value) => option[isOptionEqualToValue] === value[isOptionEqualToValue]}
        />
    )
}


export { TextInput, SelectBox, SearchAbleSelectBox, }

