import { axiosInstance } from "../Context/CreateAxios"
import { deletefile, uploadfile } from "../services/Api"
import handleError from "../utils/handleError"

export const UPLOAD_FILE = async (body) => {
    try {
        let response = await axiosInstance.post(uploadfile(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_FILE = async (body) => {
    try {
        let response = await axiosInstance.post(deletefile(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}