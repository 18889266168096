import React from 'react'
import PaginationCom from '../../Component/pagination'
import Loading from '../../Component/Loading'
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material'
import useTableSorting from '../../Hooks/useTableSorting'
import useList from './Hooks/useList'
import NoRecordFound from '../../Component/NoRecordFound';
import { getlocalStorage, intialRole } from '../../utils';
import { permissionMessage } from '../../Constants/Constants';

const List = () => {
    const {
        reset,
        setController,
        handleChangeFilter,
        HandleSearchList,                    
        timeZone,
        controller,
        paginationTotalCount,
        headCells,
        loading,
        Filter,
    } = useList()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const userData = getlocalStorage("userInfodata") 
    return (
        <>
            {loading && <Loading />}
            <div className="container-fluid">
                <h3 className='PAGE-NAME'>Time Zone</h3>

                {
                    intialRole({ permission: "Time Zones", userData: userData, section: 'AbletoViewTimeZones' }) ?
                        <>
                            <section className='d-flex justify-content-between row'>
                                <div className='col-12 '>
                                    <div className="row">
                                        <div className=" col-lg-4 col-md-5 col-sm-7  ">
                                            <TextField id="outlined-basic" name='timeZone' value={Filter?.timeZone} onChange={handleChangeFilter} placeholder='Search Time Zone.. ' variant="outlined" className='w-100' size='small' />
                                        </div>
                                        <div className=" mt-sm-0 mt-3 col-lg-8 col-md-7 col-sm-4  d-flex justify-content-sm-start justify-content-end">
                                            <Button variant="contained" disabled={Filter?.disable_button} className='me-2 mui_button_color' onClick={HandleSearchList}>search</Button>
                                            <Button variant="contained" disabled={Filter?.disable_button} className='mui_button_color' onClick={reset}>reset</Button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="row">
                                {
                                    timeZone?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div>
                                }
                            </div>
                            <TableContainer className=" mt-4"  >
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={timeZone?.length} />
                                    }
                                    <TableBody>
                                        {stableSort(timeZone, getComparator(order, orderBy))?.map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={row?.timezoneId||index}>
                                                    <TableRow id="hover_tr">
                                                        <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                            {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                        </TableCell>
                                                        <TableCell id={labelId} align="left" >{row.abbr}</TableCell>
                                                        <TableCell id={labelId} align="left" >{row?.text}</TableCell>
                                                        <TableCell id={labelId} align="right" >{row?.timezone}</TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <section className='w-100 d-flex justify-content-end pt-3'>
                                {
                                    paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </section>
                        </>
                        : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>   
                }
            </div>
        </>
    )
}

export default List
