import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { StateValidation } from '../../../Validation/Validation'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { COUNTRY_LIST } from '../../../Repository/CountryRepo';
import { INSERT_STATE } from '../../../Repository/StateRepo';

const useAdd = () => {
    const navigate = useNavigate()
    const [listDatatacountry, setListDataCountry] = useState([])
    const [loading, setLoading] = useState(false)
    //////////////////////// country list ///////////////////// 
    async function listcountrys() {
        setLoading(true)
        let payload = {
            activeStatus: true,
            keyword: ""
        }
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response?.data)
        } catch (error) {
            toast.error(error.message)
            setListDataCountry([])
        }
        finally {
            setLoading(false)
        }
    }
    //////////////////////////// Add State /////////////////////
    const formik = useFormik({
        initialValues: {
            abbr: "",
            stateName: "",
            countryId:"",
        },
        validationSchema: StateValidation,
        onSubmit: async(values) => {
            let payload = {
                ...values
            }
            setLoading(true)
            try {
                let response = await INSERT_STATE(payload)
                toast.success(response.message)
                formik.resetForm()
                navigate("/layout/state")
                setLoading(false)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        listcountrys()
    }, [])

    return {
        Loading:loading,
        formik,
        listcountry: listDatatacountry,
        navigate,
    }
}

export default useAdd
