
import React from 'react'
import useTableSorting from '../../Hooks/useTableSorting';
import Loading from '../../Component/Loading';
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import PaginationCom from '../../Component/pagination';
import { Errors } from '../../Component/ErrorShow';
import { Modal } from 'react-bootstrap';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddIcon from '@mui/icons-material/Add';
import Dialogs from '../../Component/Dialog';
import { dailogAllMessage, permissionMessage } from '../../Constants/Constants';
import { BiArchiveIn } from 'react-icons/bi';
import NoRecordFound from '../../Component/NoRecordFound';
import useSetup from './Hooks/useSetup';
import { getlocalStorage, intialRole } from '../../utils';
import { FaArrowLeft } from 'react-icons/fa6';
const TestRequired = () => {
    const {
        reset,
        setController,
        handleChangeFilter,
        handleOpenModal,
        handleClosedModal,
        HandleSearchList,
        loading,
        paginationTotalCount,
        headCells,
        setUpList,
        controller,
        Filter,
        formik,
        openModal,
        handleClose,
        naviget
    } = useSetup("TEST", "Test", "insertTestRequired")
    const userData = getlocalStorage("userInfodata")
    let ActionPermission = !intialRole({ permission: "Test Required", userData: userData, section: 'AbletoEditTestRequired' }) && !intialRole({ permission: "Test Required", userData: userData, section: "AbletoActive/In-ActiveTestRequired" })
    if (ActionPermission) {
        headCells.pop()
    }
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loading && <Loading />}
            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <FaArrowLeft className='BACK_ICON' onClick={() => naviget(-1)} />
                    <h3 className='PAGE-NAME'>Test Required</h3>
                </div>
            </div>
            <div className="container-fluid">
                <section className='d-flex justify-content-between row'>
                    <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
                        {
                            intialRole({ permission: "Test Required", userData: userData, section: 'AbletoView/ListTestRequired' }) ? <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <Select
                                        name='archive'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className='w-100'
                                        size='small'
                                        value={Filter?.archive}
                                        onChange={handleChangeFilter}>
                                        <MenuItem value={true}>Unarchive</MenuItem>
                                        <MenuItem value={false}>Archive</MenuItem>
                                    </Select>
                                </div>
                                <div className="mt-3 mt-sm-0 col-lg-4 col-sm-6">
                                    <TextField id="outlined-basic" value={Filter?.searchName} name='searchName' onChange={handleChangeFilter} placeholder='Search .. ' variant="outlined" className='w-100' size='small' />
                                </div>
                                <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                                    <Button variant="contained" className='me-2 mui_button_color' disabled={Filter.disable_button} onClick={() => HandleSearchList()}>search</Button>
                                    <Button variant="contained" className='mui_button_color' disabled={Filter.disable_button} onClick={reset}>reset</Button>
                                </div>
                            </div> : null
                        }

                    </div>
                    {
                        intialRole({ permission: "Test Required", userData: userData, section: 'AbletoaddTestRequired' }) ?
                            <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => handleOpenModal("insertTestRequired")}>
                                    Add Test Required
                                </Button>
                            </div> : null
                    }
                </section>
                {
                    intialRole({ permission: "Test Required", userData: userData, section: 'AbletoView/ListTestRequired' }) ? <>
                        <div className="row">
                            {
                                setUpList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                    <NoRecordFound />
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TableContainer className=" mt-4" style={{ minHeight: "300px" }} >
                                    <Table
                                        id="table_mui"
                                        aria-labelledby="tableTitle"
                                        size={"medium"}
                                        aria-label="enhanced table">
                                        {
                                            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={setUpList.length} />
                                        }
                                        <TableBody>
                                            {stableSort(setUpList, getComparator(order, orderBy)).map(
                                                (row, index) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return <React.Fragment key={row?.id}>
                                                        <TableRow id="hover_tr"
                                                            //  }
                                                            style={{ cursor: 'pointer' }}>
                                                            <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                                {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                            </TableCell>
                                                            <TableCell id={labelId} align="left" >{row?.name}</TableCell>
                                                            {
                                                                !ActionPermission ? <TableCell id={labelId} align="right"  >
                                                                    <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                    <ul className="dropdown-menu">
                                                                        {
                                                                            intialRole({ permission: "Test Required", userData: userData, section: 'AbletoEditTestRequired' }) ?
                                                                                <li><button className={`dropdown-item`} onClick={() => handleOpenModal("insertTestRequired", row)}><i className="fa-solid fa-pen-to-square all_icons_color me-2" />Edit Test Required</button></li> : null
                                                                        }
                                                                        {
                                                                            intialRole({ permission: "Test Required", userData: userData, section: "AbletoActive/In-ActiveTestRequired" }) ? <li><button className="dropdown-item"
                                                                                onClick={() => handleOpenModal("archive", row)}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus ? "Archive" : "Unarchive"} Test Required`}</button>
                                                                            </li> : null
                                                                        }

                                                                    </ul>
                                                                </TableCell> : null
                                                            }
                                                        </TableRow>
                                                    </React.Fragment>
                                                }
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <section className='w-100 d-flex justify-content-end'>
                            {
                                paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                            }
                        </section>
                    </> : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                }

            </div>
            <Dialogs handleClose={handleClose} title={openModal?.data?.activeStatus ? dailogAllMessage?.TestRequired?.Archive : dailogAllMessage?.TestRequired?.UnArchive} open={openModal?.archive} data={openModal?.data} />

            <Modal show={openModal?.insertTestRequired} onHide={() => { handleClosedModal("insertTestRequired") }} centered>
                <Modal.Body>
                    <div className="text-center">
                        <p className="modal-title mb-0"> {openModal.data ? "Edit Test Required" : "Add Test Required"} </p>
                    </div>
                    <div className="modal-body  cus-i">
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label mb-0">Test<span className='Star_Mark'>*</span></label>
                            <input maxLength={50} type="text" name='name' className="form-control" id="exampleInputEmail1"
                                value={formik?.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} aria-describedby="emailHelp"
                                onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                            />
                            <Errors formikfun={formik} name={"name"} />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <Button variant="contained" className='mui_button_color' onClick={formik.handleSubmit}>{openModal.data ? "update" : "save"}</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TestRequired




