import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';

import { NOTIFICATION_LIST, READ_NOTIFICATION } from '../../Repository/NotificationRepo';
import { getlocalStorage } from '../../utils';
import { RoleTitle } from '../../Constants/Constants';
import { UserInfo } from '../../App'

const useNotification = () => {
    const [notificationList, setNotificationList] = useState([])
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [loading, setLoading] = useState(false)
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [filter, setFilter] = useState("ALL")

    const headCells = [
        { id: "#", numeric: false, disablePadding: false, label: "#", showLeft: false },
        { id: "title", numeric: false, disablePadding: false, label: "Title", showLeft: false, showIcon: true },
        { id: "Description", numeric: false, disablePadding: false, label: "Description", showLeft: false },
        { id: "date", numeric: false, disablePadding: false, label: "Date", showLeft: true, padding: true, showIcon: true },
    ];
    const userData = getlocalStorage("userInfodata")
    const {notificationcount, setNotificationcount} = useContext(UserInfo)

    useEffect(() => {
        getListNotification()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, filter])
    useEffect(() => {
        document.title = "Notification"
    }, [])

    async function getListNotification() {
        setLoading(true)
        const { page, rowsPerPage, } = controller;
        const payload = {
            page: page,
            count: rowsPerPage,
            filter: filter,
            [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
            roleTitle: userData?.rolesAndPermission?.roleName
        };
        try {
            let response = await NOTIFICATION_LIST(payload)
            setNotificationList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setNotificationcount(response?.unreadCount)
            setLoading(false)
        } catch (error) {
            setNotificationList([])
            setPaginationTotalCount(0)
            setLoading(false)
        }
    }

    async function HandleReadNotification(notificationId) {
        setLoading(true)
        let payload = {
            notificationId: notificationId !== "ALL" ? notificationId : undefined,
            [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
            roleTitle: userData?.rolesAndPermission?.roleName,
            filter: notificationId === "ALL" ? "ALL" : undefined
        }
        try {
            let response = await READ_NOTIFICATION(payload)
            setLoading(false)
            getListNotification()
            toast.success(response?.message)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }
    return {
        notificationList,
        controller,
        loading,
        paginationTotalCount,
        headCells,
        filter,
        notificationcount,
        setFilter,
        setController,
        HandleReadNotification,
    }
}

export default useNotification