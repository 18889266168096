import { axiosInstance } from "../Context/CreateAxios"
import { UpdateVaccinationForm, VaccinationFormList, insertVaccinationCategory, insertVaccinationForm, updateVaccinationCategory, vaccinationCategoryList } from "../services/Api"
import handleError from "../utils/handleError"

export const VACCINATION_CATEGORY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(vaccinationCategoryList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_VACCINATION_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(insertVaccinationCategory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_VACCINATION_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(updateVaccinationCategory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_VACCINATION_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(insertVaccinationForm(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const VACCINATION_FORM_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(VaccinationFormList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_VACCINATION_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(UpdateVaccinationForm(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

