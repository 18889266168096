import React from 'react'
import { useNavigate } from 'react-router-dom'

const Expertise = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12">
                        <ul className="list-style">
                            <li className='tab_hover_Effect' onClick={()=>navigate("specialization")}> <div className="d-flex justify-content-between align-items-center ">
                                <div>Specialization </div>
                                <i className="fa-solid fa-angle-right"></i>
                            </div></li>
                            <li className='tab_hover_Effect' onClick={()=>navigate("services")} >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>Services</div>
                                    <i className="fa-solid fa-angle-right"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Expertise


