import React from 'react'
import { Button, Fab, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Tooltip } from '@mui/material'
import Loading from '../../Component/Loading'
import { FormikProvider } from 'formik';
import { FieldArray, ErrorMessage } from 'formik';
import moment from 'moment-timezone';
import { Errors } from '../../Component/ErrorShow'
import { TimeFrameOptions } from '../../Constants/Constants'
import { IoMdAdd } from 'react-icons/io';
import { RiSubtractFill } from 'react-icons/ri';
import useVaccinationFormUpdate from './Hooks/useVaccinationFormUpdate';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { VaccinationImageFilePath } from '../../services/Api';
import { RxCross2 } from 'react-icons/rx';
const VaccinationFormUpdate = () => {
    const { formik, loader, UploadImage, deleteFileFun, FileRef } = useVaccinationFormUpdate()
    return (
        <>
            {loader && <Loading />}
            <section >
                <h3 className='PAGE-NAME' > Edit Vaccination Form</h3>
            </section>
            <div className="container-fluid shadow py-3  CONTAINER">
                <div className="row  mb-4 ">
                    <div className="col-12 mb-2">
                        <div className="form-group">
                            <label className='mb-0' htmlFor="exampleFormControlInput1">Form Name<span className='Star_Mark'>*</span></label>
                            <input type="text" value={formik?.values?.vaccinationFormName} onChange={(e) => formik?.setFieldValue("vaccinationFormName", e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="" />
                            <Errors formikfun={formik} name={"vaccinationFormName"} />
                        </div>
                    </div>
                    <div className="col-12">
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="calendarArray"
                                render={arrayHelpers => (
                                    <div>
                                        {
                                            formik?.values?.calendarArray?.map((cur, index, array) => (
                                                <div key={index}>
                                                    <div className="row ">
                                                        <div className="col-12 text-end">
                                                            {
                                                                index !== 0 &&
                                                                <Tooltip title="Remove Form" placement="left">
                                                                    <Fab onClick={() => arrayHelpers.remove(index)} size="small" style={{ background: "#000", color: "#fff" }} aria-label="add" className='me-2'>
                                                                        <RiSubtractFill style={{ fontSize: "20px" }} />
                                                                    </Fab>
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                                                            <label className="form-label mb-0">Vaccination Name<span className='Star_Mark'>*</span></label>
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => formik.setFieldValue(`calendarArray.${index}.vaccineName`, e.target.value)}
                                                                value={formik.values?.calendarArray?.[index]?.vaccineName}
                                                                maxLength={50}
                                                                name={`calendarArray.${index}.vaccineName`} />
                                                            <div style={{ height: "10px" }}>
                                                                <ErrorMessage name={`calendarArray.${index}.vaccineName`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                                                            <label className="form-label mb-0">Brand Name<span className='Star_Mark'>*</span></label>
                                                            <input type="text" className="form-control"
                                                                name={`calendarArray.${index}.brandName`}
                                                                onChange={(e) => formik.setFieldValue(`calendarArray.${index}.brandName`, e.target.value)}
                                                                value={formik.values?.calendarArray[index].brandName}
                                                                maxLength={100}
                                                            />
                                                            <div style={{ height: "10px" }}>
                                                                <ErrorMessage name={`calendarArray.${index}.brandName`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-4 col-sm-6 col-12 mb-2">
                                                            <label className="form-label mb-0">Given On</label>
                                                            <input type="date" className="form-control position-relative" name={`calendarArray.${index}.givenOn`} id="datefield"
                                                                disabled
                                                                value={moment(formik.values?.calendarArray[index].givenOn).format("YYYY-MM-DD")}
                                                                onChange={(e) => formik.setFieldValue(`calendarArray.${index}.givenOn`, e.target.value)}
                                                                max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]} />
                                                            <div style={{ height: "10px" }}>
                                                                <ErrorMessage name={`calendarArray.${index}.givenOn`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-4 col-sm-6 col-12 mb-2  ">
                                                            <label className="form-label mb-0">Select One<span className='Star_Mark'>*</span></label>
                                                            <div className='d-flex w-100 align-items-center ps-2' style={{
                                                                backgroundColor: "#fff ",
                                                                border: "2px solid #f0f0f0 ",
                                                                height: " 55%",
                                                                borderRadius: "5px"
                                                            }}>

                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="row-radio-buttons-group"
                                                                    value={formik.values?.calendarArray[index].dueType}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`calendarArray.${index}.dueType`, e.target.value);
                                                                        formik.setFieldValue(`calendarArray.${index}.dueAge`, "")
                                                                        formik.setFieldValue(`calendarArray.${index}.dueAgeDuration`, "")
                                                                        formik.setFieldValue(`calendarArray.${index}.dueDate`, "")
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="dueDate" control={<Radio size='small' />} label="Due Date" />
                                                                    <FormControlLabel value="dueAge" control={<Radio size='small' />} label="Age" />
                                                                </RadioGroup>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                                                            <label className="form-label mb-0">Due Date<span className='Star_Mark'>*</span></label>
                                                            <input type="date" className="form-control position-relative" name={`calendarArray.${index}.dueDate`} id="datefield"
                                                                value={formik.values?.calendarArray[index].dueDate ? moment(formik.values?.calendarArray[index].dueDate).format("YYYY-MM-DD") : ""}
                                                                onChange={(e) => formik.setFieldValue(`calendarArray.${index}.dueDate`, e.target.value)}
                                                                disabled={formik.values?.calendarArray[index].dueType === "dueDate" ? false : true}
                                                            />
                                                            <div style={{ height: "10px" }}>
                                                                <ErrorMessage name={`calendarArray.${index}.dueDate`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                                                            <label className="form-label mb-0">Age <span className='Star_Mark'>*</span></label>
                                                            <input type="number" className="form-control" name={`calendarArray.${index}.dueAge`}
                                                                maxLength={3}
                                                                onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                                onChange={(e) => formik.setFieldValue(`calendarArray.${index}.dueAge`, e.target.value)}
                                                                value={formik.values?.calendarArray[index].dueAge || ""}
                                                                disabled={formik.values?.calendarArray[index].dueType === "dueAge" ? false : true}
                                                            />
                                                            <div style={{ height: "10px" }}>
                                                                <ErrorMessage name={`calendarArray.${index}.dueAge`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                                                            <label className="form-label mb-0">Time Frame {formik.values?.calendarArray[index].dueType === "dueAge" ? <span className='Star_Mark'>*</span> : null}</label>
                                                            <Select
                                                                size='small'
                                                                className='w-100'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name={`calendarArray.${index}.dueAgeDuration`}
                                                                value={formik.values?.calendarArray[index].dueAgeDuration}
                                                                onChange={(e) => formik.setFieldValue(`calendarArray.${index}.dueAgeDuration`, e.target.value)}
                                                                disabled={formik.values?.calendarArray[index].dueType === "dueAge" ? false : true}
                                                            >
                                                                {TimeFrameOptions?.map((cur, index) => {
                                                                    return <MenuItem key={cur?.value} value={cur?.value}>{cur?.label}</MenuItem>
                                                                })}
                                                            </Select>
                                                            <div style={{ height: "10px" }}>
                                                                <ErrorMessage name={`calendarArray.${index}.dueAgeDuration`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8    col-sm-6 mb-2">
                                                            <label className="form-label mb-0">Comment <span className='Star_Mark'>*</span></label>
                                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                                rows="1"
                                                                name={`calendarArray.${index}.comment`}
                                                                onChange={(e) => formik.setFieldValue(`calendarArray.${index}.comment`, e.target.value)}
                                                                value={formik.values?.calendarArray[index].comment}
                                                            ></textarea>
                                                            <div style={{ height: "10px" }}>
                                                                <ErrorMessage name={`calendarArray.${index}.comment`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                        <div className="col-12  mb-2 ">
                                                            <label className="form-label mb-1"> Vaccination Serial Number <span className='Star_Mark'>*</span></label>
                                                            <div className="row d-flex justify-content-start">
                                                                <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-8">
                                                                    <div className="bodrerer  d-flex justify-content-center align-items-center" style={{ border: `${!formik.values?.calendarArray[index].vaccinationSerialNumber ? "2px dashed #aaaaaa" : "none"}` }}>
                                                                        <label htmlFor={`inputTag${index}`}>
                                                                            {
                                                                                !formik.values?.calendarArray[index].vaccinationSerialNumber && <AiOutlineCloudUpload className="fs-4 " />
                                                                            }
                                                                            {formik.values?.calendarArray[index].vaccinationSerialNumber && <img src={VaccinationImageFilePath + formik.values?.calendarArray[index].vaccinationSerialNumber} alt="" />}
                                                                            <input ref={FileRef} id={`inputTag${index}`} type="file" name="file" onChange={(e) => { UploadImage(e.target.files[0], index) }} />
                                                                            {
                                                                                !formik.values?.calendarArray[index].vaccinationSerialNumber && <>
                                                                                    <br /> Upload an Image<br />
                                                                                    <span id="imageName">Vaccination Serial Number</span>
                                                                                </>
                                                                            }
                                                                        </label>
                                                                        {formik.values?.calendarArray[index].vaccinationSerialNumber && <Tooltip title="Delete" placement="right" style={{ cursor: "pointer" }}>
                                                                            <span className="delete_icon_for_image" onClick={() => { deleteFileFun(formik.values?.calendarArray[index].vaccinationSerialNumber, index, cur?.vaccinationFormFieldId) }}><RxCross2 /></span>
                                                                        </Tooltip>}
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <ErrorMessage name={`calendarArray.${index}.vaccinationSerialNumber`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end ">
                                                        {
                                                            array?.length === index + 1 &&

                                                            <Tooltip title="Add Form" placement="left">
                                                                <Fab onClick={() => arrayHelpers.push({ vaccineName: "", brandName: "", givenOn: "", dueDate: "", dueAge: "", comment: "", dueType: "dueDate", dueAgeDuration: "", vaccinationSerialNumber: "" })} size="small" style={{ background: "#000", color: "#fff" }} aria-label="add" className='me-2'>
                                                                    <IoMdAdd style={{ fontSize: "20px" }} />
                                                                </Fab>
                                                            </Tooltip>

                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        <div className=" row  mt-4  ">
                                            <div className="col-12 d-flex justify-content-end pb-4">
                                                <Button className='mui_button_color ' variant="contained" onClick={formik.handleSubmit} >Update</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </FormikProvider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VaccinationFormUpdate



