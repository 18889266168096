import { useFormik } from 'formik'
import { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CreateCalendarFormValidation } from '../../../Validation/Validation'
import { INSERT_VACCINATION_FORM } from '../../../Repository/VaccinationRepo'
import { toast } from 'react-toastify'
import { getlocalStorage } from '../../../utils'
import { DELETE_FILE, UPLOAD_FILE } from '../../../Repository/CommonRepo'
import { fileTypes, RoleTitle } from '../../../Constants/Constants'

const useVaccinationForm = () => {
    const [loader, setLoader] = useState(false)
    const [initilvalue] = useState([{ vaccineName: "", brandName: "", givenOn: "", dueDate: "", dueAge: "", comment: "", dueType: "dueDate", dueAgeDuration: "", vaccinationSerialNumber: "" }])
    const navigate = useNavigate()
    const userData = getlocalStorage("userInfodata")
    const FileRef = useRef()
    const { id } = useParams()

    const formik = useFormik({
        validationSchema: CreateCalendarFormValidation,
        initialValues: {
            calendarArray: initilvalue,
            vaccinationFormName: ""
        },
        onSubmit: async (values) => {
            let payload = {
                categoryId: id,
                providerId: userData?.userId,
                form: values?.calendarArray,
                vaccinationFormName: values?.vaccinationFormName,
                [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
                roleTitle: userData?.rolesAndPermission?.roleName
            }
            setLoader(true)
            try {
                let response = await INSERT_VACCINATION_FORM(payload)
                toast.success(response.message)
                setLoader(false)
                navigate(`/layout/vaccination/${id}`)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }

        }
    })
    const UploadImage = async (e, index) => {
        if (!e) return
        const fd = new FormData()
        if (e.size / 1024 / 1024 > 10) return toast.warn('Please upload file less than 10 MB')
        if (!fileTypes.Img_Type.includes(e?.type)) return toast.warn('Only image format allowed')
        if (e?.size / 1024 / 1024 < 10 && fileTypes.Img_Type.includes(e?.type)) {
            fd.append('type', "VACCINATIONFORM")
            fd.append('file', e)
            fd.append('previousFileName', formik.values?.calendarArray[index].vaccinationSerialNumber)
            fd.append('id', null)
            setLoader(true)
            try {
                let response = await UPLOAD_FILE(fd)
                formik.setFieldValue(`calendarArray.${index}.vaccinationSerialNumber`, response?.data?.db_name)
            } catch (error) {
                toast.error(error.message)
            } finally {
                setLoader(false)
                FileRef.current.value = null;
            }
        }
    };
    const deleteFileFun = async (fileName, index) => {
        let payload = { fileName: fileName, type: "VACCINATIONFORM", id: null }
        setLoader(true)
        try {
            let response = await DELETE_FILE(payload)
            formik.setFieldValue(`calendarArray.${index}.vaccinationSerialNumber`, "")
            toast.success(response?.data?.message)
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoader(false)
        }
    }
    return {
        formik,
        loader,
        FileRef,
        UploadImage,
        deleteFileFun
    }
}

export default useVaccinationForm
