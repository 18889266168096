import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EDIT_STATE, STATE_LIST } from '../../../Repository/StateRepo';

const useList = () => {
    const [open, setOpen] = useState(false);
    const [ListState, setListState] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [Filter, setFilter] = useState({
        archive: true,
        country: "",
        state: "",
        disable_button: true
    })
    const [DeletedData, setDeletedData] = useState({});
    const [Action] = useState(false)
    const naviget = useNavigate()
    const { state } = useLocation();
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "countryName", numeric: true, disablePadding: false, label: "Country", isShorted: true },
        { id: "stateName", numeric: true, disablePadding: false, label: "State", isShorted: true },
        { id: "abbr", numeric: true, disablePadding: false, label: " Abbreviation", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];

    // /////////////////////////// list state //////////////////////////
    async function StateListRefetch() {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, country, state } = Filter;
        const keyword = country.trim() || state.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
        };
        try {
            let response = await STATE_LIST(payload)
            setListState(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setListState([])
            setPaginationTotalCount(0)
            setLoading(false)
        }
    }

    ////////////////////////////// delete state //////////////////////////

    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                stateId: data?.stateId,
                activeStatus: !data?.activeStatus
            }
            try {
                let response = await EDIT_STATE(payload)
                toast.success(response.message)
                setLoading(false)
                if (ListState?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else StateListRefetch()
                setOpen(false)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
        else setOpen(false)
    }

    useEffect(() => {
        StateListRefetch()
        naviget({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, Action])

    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }

    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, country: "", state: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    return {
        loading,
        paginationTotalCount,
        headCells,
        ListState,
        controller,
        DeletedData,
        open,
        setDeletedData,
        reset,
        naviget,
        setOpen,
        handleClose,
        setController,
        StateListRefetch,
        Filter,
        handleChangeFilter,
        HandleSearchList
    }
}

export default useList
