const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
export const getlocalStorage = (key) => {
  const userInfo = localStorage.getItem(key);
  const parsedData = JSON.parse(userInfo);
  return parsedData
}

export const TIMEZONE = () => {
  if (timeZone === "Asia/Calcutta" || timeZone === "Asia/Kolkata") {
    let indiaTimezone = "Asia/Kolkata"
    return indiaTimezone
  } else {
    return timeZone
  }
}

export const formatNumber = (number) => {
  if (number !== null && number !== undefined) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(2) + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2) + 'K';
    } else {
      return number.toLocaleString();
    }
  } else {
    return 0; // or any other default value if number is undefined or null
  }
};


export const intialRole = ({ permission, userData, section }) => {
  let active = false
  let newdata = userData?.rolesAndPermission?.permissionArray?.find((cur, index) => cur?.title?.toLowerCase() === permission?.toLowerCase())
  if (newdata) {
    let conditionData = newdata?.permissionData?.find((cur, index) => cur?.title?.toLowerCase() === section?.toLowerCase())
    active = conditionData?.data?.value
  }
  else {
    active = false
  }
  return active
}