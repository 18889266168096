import React from 'react';
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress } from '@mui/material';
export default function Loading() {
    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 50000 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>

    );
}