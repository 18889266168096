import { commonMessages } from "../Constants/Constants";

const handleError = (error = {}) => {
    if (!error) return;
    if (error && error.code === "ERR_NETWORK") {
        throw new Error(commonMessages?.NetworkError || "A network error occurred.");
    } else if (error && error?.response) {
        if (error?.response?.status === 401) {
            throw new Error("Token Expire");
        }
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    } else throw new Error("An unspecified error occurred.");
};

export default handleError;
