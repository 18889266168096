import invironment, { ImageBaseURL } from "../environment/environment"
const Url = "/winkdoc-api/rest/"
//login
export const loginApi = () => invironment.baseUrl + Url + "api/v2/superadmin/login";
export const logoutApi = () => invironment.baseUrl + Url + "api/v2/superadmin/logout";
export const ForgotPinApi = () => invironment.baseUrl + Url + "api/v3/forgotpin";
export const ResendOtpApi = () => invironment.baseUrl + Url + "api/v3/resendOTP";
export const VerifyOtpApi = () => invironment.baseUrl + Url + "api/v3/verifyOTP";
export const CreatePinApi = () => invironment.baseUrl + Url + "api/v3/createPin";
export const changePinApi = () => invironment.baseUrl + Url + "api/v3/resetPin";
export const UserImageFilePath = invironment.baseUrl + `${ImageBaseURL}ProviderPictures/`;

export const validateAuthToken = () => invironment.baseUrl + Url + "api/v1/validateAuth";
export const decline = () => invironment.baseUrl + Url + "api/v1/decline";
// common
export const uploadfile = () => invironment.baseUrl + Url + "api/v1/file/upload";
export const deletefile = () => invironment.baseUrl + Url + "api/v1/file/delete";
// State
export const insertstate = () => invironment.baseUrl + Url + "api/v2/state/insert";
export const liststate = () => invironment.baseUrl + Url + "api/v2/state/list";
export const deletestate = () => invironment.baseUrl + Url + "deletestate";
export const updatestate = () => invironment.baseUrl + Url + "api/v2/state/update";
export const liststatebyid = () => invironment.baseUrl + Url + "liststatebyid";
// Country
export const insertcountry = () => invironment.baseUrl + Url + "api/v2/country/insert";
export const getlistcountry = () => invironment.baseUrl + Url + "api/v2/listcountry";
export const updatecountry = () => invironment.baseUrl + Url + "api/v2/country/update";
// specialization
export const insertspecialization = () => invironment.baseUrl + Url + "api/v2/specialization/insert";
export const listspecialization = () => invironment.baseUrl + Url + "api/v2/specialization/list";
export const updatespecialization = () => invironment.baseUrl + Url + "api/v2/specialization/update";
export const SpecializationImageFilePath = invironment.baseUrl + `${ImageBaseURL}SpecializationPictures/`;
// service
export const insertservice = () => invironment.baseUrl + Url + "api/v2/service/insert";
export const listlistservice = () => invironment.baseUrl + Url + "api/v2/service/list";
export const updateservice = () => invironment.baseUrl + Url + "api/v2/service/update";
export const ServiceImageFilePath = invironment.baseUrl + `${ImageBaseURL}ServicePictures/`;

// FAQ
export const listfaqs = () => invironment.baseUrl + Url + "listfaqs";
export const insertfaqs = () => invironment.baseUrl + Url + "insertfaqs";
export const deletefaqs = () => invironment.baseUrl + Url + "deletefaqs";
export const updatefaqs = () => invironment.baseUrl + Url + "updatefaqs";
export const viewfaqs = () => invironment.baseUrl + Url + "viewfaqs";
// Time Zone
export const timeZoneList = () => invironment.baseUrl + Url + "api/v2/timezone/list";
// category
export const insertbusinesscategories = () => invironment.baseUrl + Url + "api/v2/businesscategory/insert";
export const listBusinessCategories = () => invironment.baseUrl + Url + "api/v2/businesscategory/list";
export const updateBusinessCategories = () => invironment.baseUrl + Url + "api/v2/businesscategory/update";

// Life  Styele Habit
export const insertLifeStyleHabit = () => invironment.baseUrl + Url + "api/v2/lifestyle/insert";
export const listLifeStyleHabit = () => invironment.baseUrl + Url + "api/v2/lifestyle/list";
export const updateLifeStyleHabit = () => invironment.baseUrl + Url + "api/v2/lifestyle/update";

// Subcription list
export const subcriptionPlanList = () => invironment.baseUrl + Url + "api/v1/subscriptionplan/list";
export const inserSubcriptionPlan = () => invironment.baseUrl + Url + "api/v1/subscriptionplan/insert";
export const updateSubcriptionPlan = () => invironment.baseUrl + Url + "api/v1/subscriptionplan/update";
export const assignSubcriptionPlan = () => invironment.baseUrl + Url + "api/v1/subscriptionplan/assign";
export const planHistory = () => invironment.baseUrl + Url + "api/v1/subscriptionplan/assigned/history/list";
export const paymentHistory = () => invironment.baseUrl + Url + "api/v1/subscriptionplan/payment/list";
export const currentPlanView = () => invironment.baseUrl + Url + "api/v1/subscriptionplan/assigned/list";

// roles permission 
export const permissionsList = () => invironment.baseUrl + Url + "api/v1/permission/list";
export const insertRolepermissions = () => invironment.baseUrl + Url + "api/v1/roles/insert";
export const roleList = () => invironment.baseUrl + Url + "api/v1/roles/list";
export const getpermissionDetails = () => invironment.baseUrl + Url + "api/v1/roles/permission/list";
export const updateRolepermissions = () => invironment.baseUrl + Url + "api/v1/roles/permission/update";
export const updateRole = () => invironment.baseUrl + Url + "api/v1/roles/update";

//Provider list
export const providreList = () => invironment.baseUrl + Url + "api/v2/provider/list";
export const locationList = () => invironment.baseUrl + Url + "api/v1/Clinic/list";
export const updateStaffStatus = () => invironment.baseUrl + Url + "api/v1/staff/updatestatus";
export const providerbasicDetails = () => invironment.baseUrl + Url + "api/v2/provider/list";
export const providerbusinessDetails = () => invironment.baseUrl + Url + "api/v1/businessprofile/list";
export const updatebasicdetails = () => invironment.baseUrl + Url + "api/v3/staff/update";
export const updatebusinessdetails = () => invironment.baseUrl + Url + "api/v1/BusinessProfile/update";


// Team management
export const insertTeam = () => invironment.baseUrl + Url + "api/v3/staff/insert";
export const updateTeam = () => invironment.baseUrl + Url + "api/v3/staff/update";
export const resendInvitationEmailTeam = () => invironment.baseUrl + Url + "api/v2/staff/resendInvitationEmail";
export const updatePhoneNumberApi = () => invironment.baseUrl + Url + "api/v1/validateAuth/staff/update";
export const TeamImageFilePath = invironment.baseUrl + `${ImageBaseURL}TeamPictures/`;

// cancellation policy 
export const cancellationPolicyList = () => invironment.baseUrl + Url + "api/v2/policy/subscription/cancellation/list";
export const addCancellationPolicy = () => invironment.baseUrl + Url + "api/v2/policy/subscription/cancellation/insert";
export const updateCancellationPolicy = () => invironment.baseUrl + Url + "api/v2/policy/subscription/cancellation/update";

// leads
export const insertLead = () => invironment.baseUrl + Url + "api/v1/lead/insert";
export const leadList = () => invironment.baseUrl + Url + "api/v2/lead/list";
export const updateLead = () => invironment.baseUrl + Url + "api/v1/lead/update";
export const convertToProvider = () => invironment.baseUrl + Url + "api/v2/provider/signup";

// vaccination

export const vaccinationCategoryList = () => invironment.baseUrl + Url + "api/v2/vaccination/category/list";
export const insertVaccinationCategory = () => invironment.baseUrl + Url + "api/v2/vaccination/category/insert";
export const updateVaccinationCategory = () => invironment.baseUrl + Url + "api/v2/vaccination/category/update";
export const insertVaccinationForm = () => invironment.baseUrl + Url + "api/v2/vaccination/form/insert";
export const VaccinationFormList = () => invironment.baseUrl + Url + "api/v2/vaccination/form/list";
export const UpdateVaccinationForm = () => invironment.baseUrl + Url + "api/v2/vaccination/form/update";
export const VaccinationImageFilePath = invironment.baseUrl + `${ImageBaseURL}VaccinationFormPictures/`;


// setup

export const setupList = () => invironment.baseUrl + Url + "api/v2/settings/listall";
export const insertSetup = () => invironment.baseUrl + Url + "api/v2/settings/insert";
export const updateSetup = () => invironment.baseUrl + Url + "api/v2/settings/update";


// Dashboard

export const dashboardDetailsAPI = () => invironment.baseUrl + Url + "api/v1/superadmin/dashboard";
export const reportGraphAPI = () => invironment.baseUrl + Url + "api/v1/superadmin/dashboard/report/graph";

//  notification
export const ListNotificationApi = () => invironment.baseUrl + Url + "api/v1/notification/list";
export const ReadNotificationApi = () => invironment.baseUrl + Url + "api/v1/notification/changestatus";