import React, { useEffect, useState } from 'react'
import { SelectBox, TextInput } from '../../Component/Inputs'
import { useFormik } from 'formik'
import { Errors } from '../../Component/ErrorShow'
import { Button, IconButton } from '@mui/material'
import { CreateSubcriptionPlanValidation } from '../../Validation/Validation'
import { EDIT_PLAN, PLAN_LIST } from '../../Repository/SubcripstionPlanRepo'
import { toast } from 'react-toastify'
import Loader from '../../Component/Loading';
import { useNavigate, useParams } from 'react-router-dom'
import { DescountType, PlanTypeOption } from '../../Constants/Constants'
import { COUNTRY_LIST } from '../../Repository/CountryRepo'
import { FaArrowLeft, FaMinus, FaPlus } from 'react-icons/fa6'
const EditPlan = () => {
    const [Loading, setLoading] = useState(false)
    const [listDatatacountry, setListDataCountry] = useState([])
    const navigate = useNavigate()
    const { id } = useParams()
    async function listcountrys() {
        setLoading(true)
        const payload = { activeStatus: true }
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response?.data)
        } catch (error) {
            toast.error(error.message)
            setListDataCountry([])
        }
        finally {
            setLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            planType: "",
            region: "",
            title: "",
            Features: [
                { isActive: false, type: "Add Multiple Clinic", price: "", number: 0, key: "/ Clinic" },
                { isActive: false, type: "No. Of Team", price: "", number: 0, key: "/ Team" },
                { isActive: false, type: "No. Of Service", price: "", number: 0, key: "/ Service" },
            ],
            AddOns: [
                { isActive: false, type: "Analytics & Insights", price: "" },
                { isActive: false, type: "Patient Mobile App", price: "" },
                { isActive: false, type: "Separate Team Login", price: "" },
                { isActive: false, type: "Telemedicine", price: "" },
                { isActive: false, type: "Chat", price: "" },
                { isActive: false, type: "Email reminders", price: "" },
                { isActive: false, type: "Payment Gateway", price: "" },
                { isActive: false, type: "Vaccination Calendar", price: "" },
                { isActive: false, type: "SupportTicket", price: "" },
            ],
            discountType: '',
            discount: "",
            price: "",
            AddOnsAmount: "",
            finalAmount: "",
        },
        validationSchema: CreateSubcriptionPlanValidation,
        validateOnChange: true,
        validate: (values) => {
            const errors = {};
            if (values.discount < 0) {
                errors.discount = "Discount value must be greater than zero and equal to zero"
            }
            if (values.discountType === "PERCENTAGE" && values.discount > 100) {
                errors.discount = "Discount value must be less than  100"
            }
            return errors;
        },
        onSubmit: async (values) => {
            const { deletedStatus, createdBy, activeStatus, pricingPlanId, ...restValues } = values;
            const payload = {
                ...restValues,
            };
            setLoading(true)
            try {
                let response = await EDIT_PLAN(payload)
                toast.success(response.message)
                formik.resetForm()
                navigate("/layout/subscription-plans")
                setLoading(false)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })

    const getsubcriptionPlanDetails = async () => {
        setLoading(true)
        let payload = { subscriptionPlanId: +id, activeStatus: true, }
        try {
            let response = await PLAN_LIST(payload)
            formik.setValues(response.data)

        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const Discount = () => {
        if (formik.values.price) {
            let BasePlanPrice = parseFloat(formik.values.price)
            let DiscountValue = parseFloat(formik.values.discount)
            let AddOnsAmount = formik?.values?.AddOnsAmount
            let discountType = formik.values.discountType
            let totalAmount = (BasePlanPrice || 0) + (AddOnsAmount || 0)
            let discountAmount = 0;
            if (discountType === "FLAT") {
                let finalAmount = totalAmount - DiscountValue;
                formik.setFieldValue("finalAmount", finalAmount > 0 ? finalAmount : 0)
            } else {
                discountAmount = (totalAmount * (DiscountValue / 100))?.toFixed(3);
                let finalAmount = totalAmount - parseFloat(discountAmount);
                formik.setFieldValue("finalAmount", finalAmount > 0 ? finalAmount : 0)
            }
        }
    }
    const calculateAmount = (items) => {
        return items.reduce((accumulator, current) => {
            if (current.isActive) {
                const price = parseFloat(current.price);
                const number = parseInt(current.number);
                if (!isNaN(price)) {
                    const itemAmount = isNaN(number) ? price : price * number;
                    return accumulator + itemAmount;
                }
            }
            return accumulator;
        }, 0);
    };
    const AddOnAmount = () => {
        const FeaturesAmount = calculateAmount(formik.values.Features);
        const AddOnsAmount = calculateAmount(formik.values.AddOns);
        formik.setFieldValue("AddOnsAmount", FeaturesAmount + AddOnsAmount);
    };
    const handleChangeInput = (event) => {
        const { name, value } = event.target
        formik.setFieldValue(name, value)
    }
    const handleChangeCheckBox = (event, type, index) => {
        const { name, checked } = event.target
        formik.setFieldValue(name, checked)
        if (!checked) {
            if (type === "Features") {
                formik.setFieldValue(`${type}.${index}.price`, "")
                formik.setFieldValue(`${type}.${index}.number`, 0)
            } else {
                formik.setFieldValue(`${type}.${index}.price`, '')
            }
        } else {
            if (type === "Features") {
                formik.setFieldValue(`${type}.${index}.price`, 0)
                formik.setFieldValue(`${type}.${index}.number`, 1)
            } else {
                formik.setFieldValue(`${type}.${index}.price`, 0)
            }
        }
    }
    const handleCount = (isActive, type, index, number) => {
        if (!isActive) return
        if (type === "Increment") {
            formik.setFieldValue(`Features.${index}.number`, number + 1)
        }
        if (type === "Decrement" && number > 1) {
            formik.setFieldValue(`Features.${index}.number`, number - 1)
        }
    }

    useEffect(() => {
        listcountrys()
        getsubcriptionPlanDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        AddOnAmount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.Features, formik.values.AddOns])

    useEffect(() => {
        Discount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.Features, formik.values.AddOns, formik.values.discountType, formik.values.discount, formik.values.price, formik.values.AddOnsAmount])
    console.log(formik.values, "formik")
    return (
        <>
            {Loading && <Loader />}
            <section className='d-flex align-items-center' >
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME' >Edit Plan</h3>
            </section>
            <div className="container-fluid shadow py-3  CONTAINER">
                <form className="row g-3 mt-4" >
                    <div className="col-sm-4 mb-2 mt-0">
                        <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="title"
                            value={formik.values.title}
                            type="text"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"title"} />
                    </div>
                    <div className="col-sm-4 mb-2 mt-0">
                        <label className="form-label mb-0">Plan Type<span className='Star_Mark'>*</span></label>
                        <SelectBox
                            placeholder="Select Discount Type"
                            value={formik.values.planType}
                            name="planType"
                            keys="value"
                            label="label"
                            menuValeu="value"
                            handleChange={handleChangeInput}
                            option={PlanTypeOption}
                        />
                        <Errors formikfun={formik} name={"planType"} />
                    </div>
                    <div className="col-sm-4 col-md-4 mb-2 mt-0">
                        <label className="form-label mb-0">Region<span className='Star_Mark'>*</span></label>
                        <SelectBox
                            placeholder="Select Discount Type"
                            value={formik.values.region}
                            name="region"
                            keys="id"
                            label="countryName"
                            menuValeu="countryName"
                            handleChange={handleChangeInput}
                            option={listDatatacountry}
                        />
                        <Errors formikfun={formik} name={"region"} />
                    </div>
                    <div className="col-12  d-flex justify-content-between  mt-0">
                        <h5 className='mb-0'>Features</h5>
                    </div>
                    <div className="col-12">
                        <table className="table ">
                            <tbody className='border'>
                                {
                                    formik?.values?.Features?.map((cur, index) => {
                                        return <tr key={index}>
                                            <td className='w-25'>

                                                <input
                                                    onChange={(e) => handleChangeCheckBox(e, 'Features', index)}
                                                    className="form-check-input cursor me-2"
                                                    type="checkbox"
                                                    id={`Features.${index}.isActive`}
                                                    name={`Features.${index}.isActive`}
                                                    checked={formik.values?.Features?.[index]?.isActive}
                                                />
                                                <label htmlFor={`Features.${index}.isActive`} className='cursor'>{cur.type}</label>
                                            </td>
                                            <td className='w-25'>
                                                {
                                                    formik?.values?.region?.trim() ? (formik?.values?.region?.toLocaleLowerCase()?.trim() === "india" ? <i style={{ fontSize: "12px" }} className="fa-solid me-2  fa-indian-rupee-sign "></i> : <i style={{ fontSize: "12px" }} className="fa-solid me-2  fa-dollar-sign "></i>) : null
                                                }
                                                <TextInput
                                                    handleChange={handleChangeInput}
                                                    className="w-25"
                                                    value={formik.values?.Features?.[index]?.price}
                                                    name={`Features.${index}.price`}
                                                    type="number"
                                                    size='small'
                                                    readOnly={!formik.values?.Features?.[index]?.isActive}
                                                    maxLength={7}
                                                /></td>
                                            <td className='w-50'>
                                                <span style={{ color: 'gray', fontSize: "14px", fontWeight: "400", minWidth: "80px", display: "inline-block" }} className='me-5'>{cur?.key}</span>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    aria-label="delete"
                                                    onClick={() => handleCount(formik.values?.Features?.[index]?.isActive, "Decrement", index, formik.values?.Features?.[index]?.number)} ><FaMinus />
                                                </IconButton>
                                                {/* <input type="text" name={`Features.${index}.number`}
                          style={{ width: "40px", border: "none", outline: "none", textAlign: "center", color: "gray" }}
                          id=""
                          value={formik.values?.Features?.[index]?.number}
                          onChange={handleChangeCountInput}
                        /> */}
                                                <div className='d-inline-block' style={{ width: "25px" }}>
                                                    <input type="number"
                                                        disabled={!formik.values?.Features?.[index]?.isActive}
                                                        maxLength={2}
                                                        onChange={(e) => formik.setFieldValue(`Features.${index}.number`, parseInt(e.target.value))}
                                                        className='w-100 border-0 text-center'
                                                        value={formik.values?.Features?.[index]?.number}
                                                        onInput={(e) => {
                                                            let maxValue = 2;
                                                            e.target.maxLength = maxValue;
                                                            if (e.target.value.length > maxValue) {
                                                                e.target.value = e.target.value.slice(0, maxValue);
                                                            }
                                                            if (parseFloat(e.target.value) <= 0) {
                                                                e.target.value = 1;
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                {/* <span className='mx-2' style={{ color: 'gray', fontSize: "14px", fontWeight: "500" }} >{formik.values?.Features?.[index]?.number}</span> */}
                                                <IconButton
                                                    onClick={() => handleCount(formik.values?.Features?.[index]?.isActive, "Increment", index, formik.values?.Features?.[index]?.number)}
                                                    color="primary"
                                                    size='small'
                                                    aria-label="delete"><FaPlus />
                                                </IconButton></td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12  d-flex justify-content-between  mt-0">
                        <h5 className='mb-0'>Add-Ons </h5>
                    </div>
                    <div className="col-12">
                        <table className="table ">
                            <tbody className='border'>
                                {
                                    formik?.values?.AddOns?.map((cur, index) => {
                                        return <tr key={index}>
                                            <td className='w-25'>
                                                <input
                                                    onChange={(e) => handleChangeCheckBox(e, "AddOns", index)}
                                                    className="form-check-input cursor me-2"
                                                    type="checkbox"
                                                    id={`AddOns.${index}.isActive`}
                                                    name={`AddOns.${index}.isActive`}
                                                    checked={formik.values?.AddOns?.[index]?.isActive}
                                                />
                                                <label htmlFor={`AddOns.${index}.isActive`} className='cursor'>{cur.type}</label></td>
                                            <td className='w-25'>
                                                {
                                                    formik?.values?.region?.trim() ? (formik?.values?.region?.toLocaleLowerCase()?.trim() === "india" ? <i style={{ fontSize: "12px" }} className="fa-solid me-2  fa-indian-rupee-sign "></i> : <i style={{ fontSize: "12px" }} className="fa-solid me-2  fa-dollar-sign "></i>) : null
                                                }
                                                <TextInput
                                                    className="w-25"
                                                    type="number"
                                                    size='small'
                                                    handleChange={handleChangeInput}
                                                    value={formik.values?.AddOns?.[index]?.price}
                                                    name={`AddOns.${index}.price`}
                                                    readOnly={!formik.values?.AddOns?.[index]?.isActive}
                                                    maxLength={7}
                                                />
                                            </td>
                                            <td className='w-50'> </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-4 mb-2 mt-0">
                        <label className="form-label mb-0">Discount Type<span className='Star_Mark'>*</span></label>
                        <SelectBox
                            placeholder="Select Discount Type"
                            value={formik.values.discountType}
                            name="discountType"
                            keys="value"
                            label="label"
                            menuValeu="value"
                            handleChange={handleChangeInput}
                            option={DescountType}
                        />
                        <Errors formikfun={formik} name={"discountType"} />
                    </div>
                    <div className="col-sm-4 col-md-4 mb-2 mt-0">
                        <label className="form-label mb-0">Discount Value<span className='Star_Mark'>*</span></label>
                        <TextInput
                            PointMaxValue={formik.values.discountType === "PERCENTAGE" ? 4 : 7}
                            maxLength={formik.values.discountType === "PERCENTAGE" ? 3 : 7}
                            handleChange={handleChangeInput}
                            name="discount"
                            value={formik.values.discount}
                            type="number"
                            size='small'
                            readOnly={formik.values.discountType ? false : true}
                        />
                        <Errors formikfun={formik} name={"discount"} />
                    </div>
                    <div className="col-sm-4 col-md-4 mb-2 mt-0">
                        <label className="form-label mb-0">Base Plan Price<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={30}
                            name="price"
                            value={formik.values.price}
                            type="number"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"price"} />
                    </div>

                    <div className="col-12  px-2">
                        <div className="row border p-0 m-0 py-3 ">
                            <div className="col-12 d-flex justify-content-between align-items-center" >
                                <label className="form-label mb-0 w-50">Base Plan Price</label>
                                <div className=' ps-5 pe-3 py-1 bg-light' >
                                    <b className='text-secondary'>        {
                                        formik?.values?.region?.trim() ? (formik?.values?.region?.toLocaleLowerCase()?.trim() === "india" ? <i style={{ fontSize: "12px" }} className="fa-solid   fa-indian-rupee-sign "></i> : <i style={{ fontSize: "12px" }} className="fa-solid   fa-dollar-sign "></i>) : null
                                    } {formik.values.price || 0}</b>
                                </div>
                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                <label className="form-label mb-0 w-50">Add On Amount</label>
                                <div className=' ps-5 pe-3 py-1 bg-light' >
                                    <b className='text-secondary'>        {
                                        formik?.values?.region?.trim() ? (formik?.values?.region?.toLocaleLowerCase()?.trim() === "india" ? <i style={{ fontSize: "12px" }} className="fa-solid   fa-indian-rupee-sign "></i> : <i style={{ fontSize: "12px" }} className="fa-solid   fa-dollar-sign "></i>) : null
                                    } {formik?.values?.AddOnsAmount || 0}</b>
                                </div>
                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                <label className="form-label mb-0 w-50">Discount {`(${formik.values.discountType === "PERCENTAGE" ? " Percentage" : "Flat"})`}</label>
                                <div className=' ps-5 pe-3 py-1 bg-light' >
                                    <b className='text-danger'> {
                                        formik.values.discountType !== "PERCENTAGE" && formik?.values?.region?.trim() ? (formik?.values?.region?.toLocaleLowerCase()?.trim() === "india" ? <i style={{ fontSize: "12px" }} className="fa-solid   fa-indian-rupee-sign "></i> : <i style={{ fontSize: "12px" }} className="fa-solid   fa-dollar-sign "></i>) : null
                                    }   {formik.values.discount || 0}</b>
                                </div>
                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                <label className="form-label mb-0 w-50">Final Price (After Discount)</label>
                                <div className=' ps-5 pe-3 py-1 bg-light' >
                                    <b className='text-secondary'>        {
                                        formik?.values?.region?.trim() ? (formik?.values?.region?.toLocaleLowerCase()?.trim() === "india" ? <i style={{ fontSize: "12px" }} className="fa-solid   fa-indian-rupee-sign "></i> : <i style={{ fontSize: "12px" }} className="fa-solid   fa-dollar-sign "></i>) : null
                                    } {formik?.values?.finalAmount || 0}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-end">
                        <Button variant="contained" type="button" onClick={formik.handleSubmit} className="my-3 mui_button_color">save</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditPlan
