import { axiosInstance } from "../Context/CreateAxios"
import {  insertLifeStyleHabit, listLifeStyleHabit, updateLifeStyleHabit } from "../services/Api"
import handleError from "../utils/handleError"

export const LIFE_STYLE_HABIT_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listLifeStyleHabit(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const EDIT_LIFE_STYLE_HABIT = async (body) => {
    try {
        let response = await axiosInstance.post(updateLifeStyleHabit(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const INSERT_LIFE_STYLE_HABIT = async (body) => {
    try {
        let response = await axiosInstance.post(insertLifeStyleHabit(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
