import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { dailogAllMessage } from '../../../../Constants/Constants'
import { EDIT_SERICES, SERICES_LIST } from '../../../../Repository/ServicesRepo';
import { getlocalStorage } from '../../../../utils';
const useServices = () => {
    const [open, setOpen] = useState(false);
    const [servicesList, setservicesList] = useState([])
    const [loading, setLoading] = useState(true)
    const [searchText, setSearchText] = useState("")
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [DeletedData, setDeletedData] = useState({});
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)

    const userData = getlocalStorage("userInfodata")
    const [Filter, setFilter] = useState({
        archive: true,
        services: "",
        disable_button: true
    })
    const naviget = useNavigate()
    const PER_PAGE = 10
    useEffect(() => {
        getlistservice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])

    const handleClose = async (event, data) => {
        if (event === "YES") {
            let payload = {
                serviceId: data?.serviceId,
                activeStatus: !data?.activeStatus
            }
            try {
                let response = await EDIT_SERICES(payload)
                toast.success(response.message)
                setOpen(false)
                if (servicesList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getlistservice()
            } catch (error) {
                toast.error(error.message)
            } finally {
                setLoading(false)
            }

        } else {
            setOpen(!open)
        }
    }
    const getlistservice = async () => {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, services } = Filter;
        const keyword = services.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
            providerId: userData?.userId
        };

        try {
            let response = await SERICES_LIST(payload)
            setservicesList(response?.data.map((cur, index) => {
                return { ...cur, specializationName: cur.specialization?.specializationName }
            }))
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            if (error.message !== "No Service found") toast.error(error.message)
            setservicesList([])
            setPaginationTotalCount(0)
        }
        finally {
            setLoading(false)
        }
    }

    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, services: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    const headCells = [
        { id: "image", numeric: false, disablePadding: true, label: "Images" },
        { id: "serviceName", numeric: true, disablePadding: false, label: "Services", showIcon: true, isShorted: true },
        { id: "fixedPrice", numeric: true, disablePadding: false, label: "Price", showIcon: true, isShorted: true },
        { id: "specializationName", numeric: true, disablePadding: false, label: "Specialization", isShorted: true },
        { id: "description", numeric: true, disablePadding: false, label: "Description", showIcon: true, isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action" },
    ];
    return {
        open,
        servicesList,
        loading,
        PER_PAGE,
        searchText,
        controller,
        paginationTotalCount,
        DeletedData,
        dailogAllMessage,
        setController,
        setSearchText,
        setservicesList,
        naviget,
        setOpen,
        setLoading,
        handleClose,
        getlistservice,
        setDeletedData,
        reset, headCells, handleChangeFilter, Filter, HandleSearchList
    }
}

export default useServices
