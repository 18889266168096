import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import NoRecordFound from '../../Component/NoRecordFound';
import PaginationCom from '../../Component/pagination';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useTableSorting from '../../Hooks/useTableSorting';
import { CANCELLATION_POLICY_LIST } from '../../Repository/CancellationPolicyRepo';
import { toast } from 'react-toastify';
import Loading from '../../Component/Loading';
import { getlocalStorage, intialRole } from '../../utils';
import { permissionMessage } from '../../Constants/Constants';
const List = () => {
    const naviget = useNavigate()
    const [CancellationList, setCancellationList] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const { state } = useLocation();
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10 } : state?.controller);
    const [Filter] = useState({
        archive: true,
        role: "",
        disable_button: true
    })
    const userData = getlocalStorage("userInfodata")
    const headCells = [
        { id: "#", numeric: true, disablePadding: false, label: "S.No", isShorted: false },
        { id: "refundType", numeric: true, disablePadding: false, label: "Refund Type", isShorted: true },
        { id: "validity", numeric: true, disablePadding: false, label: "Validity", isShorted: true },
        { id: "days", numeric: true, disablePadding: false, label: "Days", isShorted: true },
        { id: "cancellationCharger", numeric: true, disablePadding: false, label: "Cancellation Charges", isShorted: true },
        { id: "Action", numeric: true, disablePadding: false, label: "Action", isShorted: false, showLeft: true, },
    ];

    let ActionPermission = !intialRole({ permission: "Cancelation Policy", userData: userData, section: 'AbletoEditCancelationPolicy' })
    if (ActionPermission) {
        headCells.pop()
    }
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)


    async function getCancellationList() {
        setLoading(true)
        const { page, rowsPerPage } = controller;
        const { archive, role } = Filter;
        const keyword = role.trim();
        const payload = {
            keyword: keyword || undefined,
            page: keyword ? undefined : page,
            count: keyword ? undefined : rowsPerPage,
            activeStatus: archive,
        };
        try {
            let response = await CANCELLATION_POLICY_LIST(payload)
            setCancellationList(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            setCancellationList([])
            if (error.message !== "No Cancellation Policy found") toast.error(error.message)
            setPaginationTotalCount(0)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getCancellationList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 px-0">
                        <h3 className='PAGE-NAME'>Cancellation Policy</h3>
                    </div>
                </div>
                {
                    intialRole({ permission: "Cancelation Policy", userData: userData, section: 'AbletoAddCancelationPolicy' }) ? <section className='d-flex justify-content-end row'>
                        <div className=' col-12 d-flex justify-content-end'>
                            <Button variant="contained" className='mui_button_color' onClick={() => naviget("add")} startIcon={<AddIcon />}>
                                add Policy
                            </Button>
                        </div>
                    </section> : null
                }

                {
                    intialRole({ permission: "Cancelation Policy", userData: userData, section: 'AbletoviewCancelationPolicy' }) ? <>
                        <div className="row">
                            {
                                CancellationList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                    <NoRecordFound />
                                </div>
                            }
                        </div>
                        <TableContainer className=" mt-4"   >
                            <Table
                                id="table_mui"
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table">
                                {
                                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={CancellationList.length} />
                                }
                                <TableBody>
                                    {stableSort(CancellationList, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={row?.cancellationPolicyId || ""}>
                                                <TableRow id="hover_tr" >
                                                    <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                        {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                    </TableCell>
                                                    <TableCell id={labelId} align="left" >{row?.refundType ? row?.refundType : "-"}</TableCell>
                                                    <TableCell id={labelId} align="left" >{row?.validity}</TableCell>
                                                    <TableCell id={labelId} align="left" > {row?.days} </TableCell>
                                                    <TableCell id={labelId} align="left" > {row?.cancellationCharger} </TableCell>
                                                    {
                                                        intialRole({ permission: "Cancelation Policy", userData: userData, section: 'AbletoEditCancelationPolicy' }) ? <TableCell id={labelId} align="right" >
                                                                <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                <ul className="dropdown-menu">
                                                                    <li>
                                                                        <button className={`dropdown-item `} onClick={() => naviget(`edit/${row?.cancellationPolicyId}`)} >Edit  Policy</button>
                                                                    </li>
                                                                </ul>
                                                        </TableCell> : null
                                                    }

                                                </TableRow>
                                            </React.Fragment>
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <section className='w-100 d-flex justify-content-end'>
                            {
                                paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                            }
                        </section>
                    </> : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                }

            </div>
        </>
    )
}

export default List