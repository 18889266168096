
import React, { useState, } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Button, TextField } from '@mui/material';
import { Errors } from '../../Component/ErrorShow';
import { addLifeStyleValidation, } from '../../Validation/Validation';
import Loading from '../../Component/Loading'
import { INSERT_LIFE_STYLE_HABIT } from '../../Repository/LifeStyleHabitRepo';
import { useNavigate } from 'react-router-dom';
import { getlocalStorage } from '../../utils';
import { FaArrowLeft } from 'react-icons/fa6';

const Add = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const userData = getlocalStorage("userInfodata")
    const formik = useFormik({
        initialValues: {
            lifeStyleName: ""
        },
        validationSchema: addLifeStyleValidation,
        onSubmit: async (value) => {
            setLoading(true)
            let payload = {
                lifeStyleName: value.lifeStyleName,
                // userId: userData?.userId
                providerId: userData?.userId
            }
            try {
                let response = await INSERT_LIFE_STYLE_HABIT(payload)
                toast.success(response.message)
                formik.resetForm()
                setTimeout(() => navigate("/layout/lifestyles-habit"), 1000)
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoading(false)
            }
        }
    })

    return (
        <>
            {loading && <Loading />}
            <section className='d-flex align-items-center' >
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'> Add Lifestyle Habit </h3>
            </section>
            <div className="container-fluid shadow">
                <form className="row g-3 mt-4" >
                    <div className="col-sm-6">
                        <label htmlFor="exampleInputEmail1" className='my-2'>Life Style Habit<span className='Star_Mark'>*</span></label>
                        <TextField id="outlined-basic" variant="outlined" className='w-100' size='small'
                            value={formik?.values?.lifeStyleName}
                            onChange={(e) => formik.setFieldValue("lifeStyleName", e.target.value)}
                            onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                        />
                        <Errors formikfun={formik} name={"lifeStyleName"} />
                    </div>
                    <div className="col-12 text-end">
                        <Button type="button" onClick={formik.handleSubmit} variant="contained" className='me-2 mui_button_color my-2' >save</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Add