
import { useFormik } from 'formik';
import React, {  useState } from 'react'
import { ChangePinValidation } from '../../Validation/Validation';
import { toast } from 'react-toastify';
import Loader from '../../Component/Loading';
import { Errors } from '../../Component/ErrorShow';
import { CHANGE_PIN } from '../../Repository/AuthRepo';
import { getlocalStorage } from '../../utils';

const ChangePin = () => {
    const [loading, setLoading] = useState(false)
    const { userId, roleTitle } = getlocalStorage("userInfodata")
    const [showpassword, setShowPassword] = useState({
        oldpin: false,
        newpin: false,
        confirmpin: false,
        pin: false
    })

    const showhide = (active) => {
        setShowPassword((pre) => ({ ...pre, [active]: !pre[active] }))
    }

    const formik = useFormik({
        initialValues: {
            oldPin: "",
            newPin: "",
            confirmPin: ""
        },
        validationSchema: ChangePinValidation,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            let payload = {
                ...values,
                roleTitle,
                userId,
            }
            try {
                let response = await CHANGE_PIN(payload)
                toast.success(response.message)
                resetForm()
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoading(false)
            }

        }
    })
    return (
        <>{loading && <Loader />}   
            <div className="row">
                <div className="col-12 ">
                    <h3 className='PAGE-NAME'>Change PIN</h3>
                </div>
            </div>
            <div className="container-fluid" style={{ height: "87vh" }}>
                <div className="row d-flex align-item-center">
                    <div className="col-md-6 d-md-block d-none ">
                        <img style={{ width: "100%", height: "100%" }} src="https://media.istockphoto.com/photos/businessman-typing-on-a-computer-keyboard-customer-service-survey-picture-id1399550888?b=1&k=20&m=1399550888&s=170667a&w=0&h=o0ZSPlXtQyQdiaWWn_Kli_bpPAf30yAt0bcNXUVYHcM=" alt="" />
                    </div>
                    <div className="col-md-6  ">
                        <form onSubmit={formik.handleSubmit} className='w-100'>
                            <div className="form-group mb-2 ">
                                <label htmlFor="exampleInputEmail1 " className='my-0'>Old Pin</label>
                                <div className="input_fild_main w-100 row px-0 mx-0">
                                    <div className="col-10 px-0">
                                        <input type={`${!showpassword.oldpin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                            placeholder="Enter Pin " name='oldPin' onChange={(e) => formik.setFieldValue("oldPin", e.target.value)} value={formik.values.oldPin}
                                            onInput={(e) => {
                                                if (e.target.value.length > 4) {
                                                    e.target.value = e.target.value.slice(0, 4);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-2 text-center d-flex justify-content-end align-items-center">
                                        <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("oldpin")}>
                                            {showpassword.oldpin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}

                                        </button>

                                    </div>
                                </div>
                                <Errors formikfun={formik} name={"oldPin"} />
                            </div>
                            <div className="form-group mb-2 ">
                                <label htmlFor="exampleInputEmail1" className='my-0'>New Pin</label>
                                <div className="input_fild_main w-100 row px-0 mx-0">
                                    <div className="col-10 px-0">
                                        <input type={`${!showpassword.newpin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                            placeholder="Enter Pin" name='newPin' onChange={(e) => formik.setFieldValue("newPin", e.target.value)} value={formik.values.newPin}
                                            onInput={(e) => {
                                                if (e.target.value.length > 4) {
                                                    e.target.value = e.target.value.slice(0, 4);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-2 text-center d-flex justify-content-end align-items-center">
                                        <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("newpin")}>
                                            {showpassword.newpin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}
                                        </button>
                                    </div>
                                </div>
                                <Errors formikfun={formik} name={"newPin"} />
                            </div>
                            <div className="form-group mb-2 ">
                                <label htmlFor="exampleInputEmail1" className='my-0'>Confirm New Pin</label>
                                <div className="input_fild_main w-100 row px-0 mx-0">
                                    <div className="col-10 px-0">
                                        <input type={`${!showpassword.confirmpin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                            placeholder="Enter Pin" name='confirmPin' onChange={(e) => formik.setFieldValue("confirmPin", e.target.value)} value={formik.values.confirmPin}
                                            onInput={(e) => {
                                                if (e.target.value.length > 4) {
                                                    e.target.value = e.target.value.slice(0, 4);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-2 text-center d-flex justify-content-end align-items-center">
                                        <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("confirmpin")}>
                                            {showpassword.confirmpin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}
                                        </button>

                                    </div>
                                </div>
                                <Errors formikfun={formik} name={"confirmPin"} />
                            </div>
                            <div className="form-group mb-0 w-100 text-center    ">
                                <button type="button" className=" btn-login shadow mx-auto mt-4 px-5" onClick={formik.handleSubmit} >save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePin
