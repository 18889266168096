import React, { useEffect, useRef, useState } from 'react'
import PhoneNumber from '../../Component/PhoneInput'
import { useFormik } from 'formik'
import { Errors } from '../../Component/ErrorShow'
import { TextInput } from '../../Component/Inputs'
import { UserImageFilePath } from '../../services/Api'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import DeleteIcons from '../../Component/DeleteIcons'
import useUploadImage from '../../Hooks/useUploadImage'
import Loader from '../../Component/Loading'
import { Button, MenuItem, Select } from '@mui/material'
import { toast } from 'react-toastify'
import { TIMEZONE, getlocalStorage } from '../../utils'
import { AddTeamValidation, pattern } from '../../Validation/Validation'
import { INSERT_TEAM } from '../../Repository/TeamManagementRepo'
import { useNavigate } from 'react-router-dom'
import { ROLE_LIST } from '../../Repository/Roles&PermissionRepo'
import { FaArrowLeft } from 'react-icons/fa6'
const AddTeam = () => {
    const [Loading, setLoading] = useState(false)
    const [imgUrl, setImgUrl] = useState(null)
    const [roleList, setRoleList] = useState([])
    const { UploadImage } = useUploadImage("PROVIDER", setLoading, setImgUrl)
    const userData = getlocalStorage("userInfodata")
    const FileRef = useRef()
    const navigate = useNavigate()
    useEffect(() => {
        getRoleList()
    }, [])
    const formik = useFormik({
        initialValues: {
            mobile: "",
            firstName: "",
            lastName: "",
            email: "",
            roleId: "",
        },
        validationSchema: AddTeamValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (values?.mobile?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
                delete errors.mobile
            } else {
                errors.mobile = "Please enter a valid mobile number";
            }
            return errors;
        },
        onSubmit: async (values) => {
            let payload = {
                ...values,
                providerId: userData?.userId,
                image: imgUrl,
                timezoneName: TIMEZONE()
            }
            setLoading(true)
            try {
                let response = await INSERT_TEAM(payload)
                toast.success(response.message)
                formik.resetForm()
                setTimeout(() => navigate("/layout/team-list"), 1000)
                setLoading(false)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })
    const handleChangeInput = (event) => {
        const { name, value } = event.target
        formik.setFieldValue(name, value)
    }
    async function getRoleList() {
        const payload = {
            page: 1,
            count: 1000,
            activeStatus: true,
        };
        try {
            let response = await ROLE_LIST(payload)
            setRoleList(response?.data)
        } catch (error) {
            setRoleList([])
        }
    }

    return (
        <>
            {Loading && <Loader />}
            <section className='d-flex align-items-center' >
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'> Add Team</h3>
            </section>
            <div className="container-fluid shadow py-3">
                <form className="row g-3 mt-4" onSubmit={formik.handleSubmit} >
                    <div className="col-sm-12 m-auto">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-8">
                                <div className="bodrerer mb-4 d-flex justify-content-center align-items-center" style={{ border: `${!imgUrl ? "2px dashed #aaaaaa" : "none"}` }}>
                                    <label htmlFor="inputTag">
                                        <AiOutlineCloudUpload className="fs-4 " />
                                        {imgUrl && <img src={UserImageFilePath + imgUrl} alt="" />}
                                        <input ref={FileRef} id="inputTag" type="file" name="file" onChange={(e) => UploadImage(e, FileRef,imgUrl)} />
                                        <br /> Upload an Image<br />
                                        <span id="imageName"></span>
                                    </label>
                                    {imgUrl && <DeleteIcons setLoading={setLoading} setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "TEAM", id: null }} isDeleted={true} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="firstName"
                            value={formik.values.firstName}
                            type="text"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"firstName"} />
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="lastName"
                            value={formik.values.lastName}
                            type="text"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"lastName"} />
                    </div>
                    <div className="col-sm-6 mb-2 ms-0 mt-0 mobile_number_input_box">
                        <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                        <div className="input_group_mobile mb-0">
                            <PhoneNumber
                                width={false}
                                Require={false}
                                Name="mobile"
                                HandleChange={handleChangeInput}
                                Value={formik.values.mobile}
                                disabledInput={false}
                            />
                        </div>
                        <Errors formikfun={formik} name={"mobile"} />
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="email"
                            value={formik.values.email}
                            type="email"
                            size='small'
                            disable={false}
                        />
                        <Errors formikfun={formik} name={"email"} />
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Role<span className='Star_Mark'>*</span></label>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className='w-100'
                            size='small'
                            value={formik.values.roleId}
                            onChange={(e) => formik.setFieldValue("roleId", e.target.value)}
                        >
                            {
                                roleList?.map((cur, index) => {
                                    return <MenuItem key={cur.roleId} value={cur.roleId} >{cur.roleName}</MenuItem>
                                })
                            }
                        </Select>
                        <Errors formikfun={formik} name={"roleId"} />
                    </div>
                    <div className="col-12 text-end">
                        <Button variant="contained" type="submit" className="my-3 mui_button_color">Save</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddTeam