import axios from 'axios';
import envoirment from "../environment/environment";
import { getlocalStorage } from '../utils';
// Create an Axios instance
const baseUrl = `${envoirment.baseUrl}`
export const axiosInstance = axios.create({ baseURL: baseUrl })
// Add a request interceptor
axiosInstance.interceptors.request.use(
    config => {
        const { authToken } = getlocalStorage("userInfodata")
        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error)
    }
);
// Add a response interceptor
axiosInstance.interceptors.response.use(
    response => {
        localStorage.removeItem("network")
        return response;
    }, (error) => {
        localStorage.setItem("network", error.message && JSON.stringify(error.message))
        if (error.response && error?.response?.status === 401) {
            localStorage?.clear()
            window.location = '/';
        }
        return Promise.reject(error);
    }
);