import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EDIT_VACCINATION_FORM, VACCINATION_FORM_LIST } from '../../../Repository/VaccinationRepo';
import { getlocalStorage } from '../../../utils';
import { RoleTitle } from '../../../Constants/Constants';

const useVaccinationCalendar = () => {
    const [open, setOpen] = useState(false);
    const [vaccinationFormList, setVaccinationFormList] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [Filter, setFilter] = useState({
        archive: true,
        publishStatus: false,
        formName: "",
        disable_button: true
    })
    const [openModal, setOpenModal] = useState({
        public: false,
        archive: false,
        data: null
    })
    const [DeletedData, setDeletedData] = useState({});
    const userData = getlocalStorage("userInfodata")
    const naviget = useNavigate()
    const { state } = useLocation();
    const { id } = useParams()
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "formName", numeric: true, disablePadding: false, label: "Vaccination Form", isShorted: true },
        { id: "publishStatus", numeric: true, disablePadding: false, label: "Status", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];

    // /////////////////////////// list vaccination form list //////////////////////////
    async function getVaccinationFormList() {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, formName, publishStatus } = Filter;
        const keyword = formName.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
            // providerId: userData?.userId,
            vaccinationCategoryId: id,
            publishStatus: publishStatus === "ALL" ? undefined : publishStatus,
            [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
            roleTitle: userData?.rolesAndPermission?.roleName
        };
        try {
            let response = await VACCINATION_FORM_LIST(payload)
            setVaccinationFormList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoading(false)
        } catch (error) {
            if (error.message !== "No vaccination form found") toast.error(error.message)
            setVaccinationFormList([])
            setPaginationTotalCount(0)
            setLoading(false)
        }
    }


    ////////////////////////////// archive/unarchive //////////////////////////

    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                activeStatus: !data.activeStatus,
                vaccinationFormId: data?.vaccinationFormId,
                categoryId: id,
                [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
                roleTitle: userData?.rolesAndPermission?.roleName
            }
            try {
                let response = await EDIT_VACCINATION_FORM(payload)
                toast.success(response.message)
                setLoading(false)
                if (vaccinationFormList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getVaccinationFormList()
                handleClosedModal("archive")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
        else {
            handleClosedModal("archive")
        }
    }

    const handlePublicForm = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                publishStatus: !data.publishStatus,
                vaccinationFormId: data?.vaccinationFormId,
                categoryId: id
            }
            try {
                let response = await EDIT_VACCINATION_FORM(payload)
                toast.success(response.message)
                setLoading(false)
                if (vaccinationFormList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getVaccinationFormList()
                handleClosedModal("public")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
        else {
            handleClosedModal("public")
        }
    }

    useEffect(() => {
        getVaccinationFormList()
        naviget({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])


    const handleOpenModal = (type, data = null) => {
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
    };
    const handleClosedModal = (type) => {
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }

    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, formName: "", disable_button: true, publishStatus: "ALL" })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }

    return {
        loading,
        paginationTotalCount,
        headCells,
        vaccinationFormList,
        controller,
        DeletedData,
        open,
        setDeletedData,
        reset,
        naviget,
        setOpen,
        handleClose,
        setController,
        getVaccinationFormList,
        Filter,
        handleChangeFilter,
        openModal,
        handleOpenModal,
        handleClosedModal,
        handlePublicForm,
        HandleSearchList
    }
}

export default useVaccinationCalendar