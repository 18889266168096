import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { StateValidation } from '../../../Validation/Validation'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom'
import { COUNTRY_LIST } from '../../../Repository/CountryRepo';
import { EDIT_STATE, STATE_LIST } from '../../../Repository/StateRepo';

const useEdit = () => {
    const [listDatatacountry, setListDataCountry] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams()

    //////////////////////// country list ///////////////////// 
    async function listcountrys() {
        setLoading(true)
        let payload = {
            activeStatus: true,
            keyword: ""
        }
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response?.data)
        } catch (error) {
            toast.error(error.message)
            setListDataCountry([])
        }
        finally {
            setLoading(false)
        }
    }

    ////////////////////// get State by Id ////////////////////
    async function StateListRefetch() {
        setLoading(true)
        const payload = {
            stateId:id,
            activeStatus: true,
        }
        try {
            let response = await STATE_LIST(payload)
            formik.setValues(response?.data)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }

    //////////////////////////// Edit State /////////////////////

    const formik = useFormik({
        initialValues: {
            abbr: "",
            stateName: "",
            countryId:"",
        },
        validationSchema: StateValidation,
        onSubmit:async(values) => {
            let payload = {
                abbr: values?.abbr,
                stateName: values?.stateName,
                countryId:values.countryId,
                stateId:id,
            }
            try {
                let response = await EDIT_STATE(payload)
                toast.success(response.message)
                formik.resetForm()
                setTimeout(() => navigate("/layout/state"), 1000)
                setLoading(false)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })
    useEffect(() => {
        listcountrys()
        StateListRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        Loading: loading,
        formik,
        listcountry:listDatatacountry ,
        navigate,
    }
}

export default useEdit
