import { axiosInstance } from "../Context/CreateAxios"
import {insertstate, liststate, updatestate } from "../services/Api"
import handleError from "../utils/handleError"
export const STATE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(liststate(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_STATE = async (body) => {
    try {
        let response = await axiosInstance.post(insertstate(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_STATE = async (body) => {
    try {
        let response = await axiosInstance.post(updatestate(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
