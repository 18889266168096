import { axiosInstance } from "../Context/CreateAxios"
import { getlistcountry, insertcountry, updatecountry } from "../services/Api"
import handleError from "../utils/handleError"

export const COUNTRY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(getlistcountry(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_COUNTRY = async (body) => {
    try {
        let response = await axiosInstance.post(insertcountry(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_COUNTRY = async (body) => {
    try {
        let response = await axiosInstance.post(updatecountry(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
