import { toast } from 'react-toastify';
import { DELETE_FILE } from '../Repository/CommonRepo';
const useDeleteFile = (setImgUrl, deletedData, isDeleted,setLoading) => {
    const deleteFileFun = async () => {
        if (isDeleted) {
            setLoading(true)
            try {
                let response = await DELETE_FILE(deletedData)
                setImgUrl(null)
                toast.success(response?.data?.message)
            } catch (error) {
                toast.error(error.message)
            } finally {
                setLoading(false)
            }
        } else {
            setImgUrl(null)
        }
    }
    return {
        deleteFileFun
    }
}

export default useDeleteFile