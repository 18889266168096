import React from 'react'
import { Errors } from '../../Component/ErrorShow'
import Loader from '../../Component/Loading'
import { Button, MenuItem, Select, TextField } from '@mui/material'
import useAdd from './Hooks/useAdd';
import { FaArrowLeft } from 'react-icons/fa';

const Add = () => {
    const {
        Loading,
        formik,
        listcountry,
        navigate,
    } = useAdd()
    return (
        <>
            {Loading && <Loader />}
            <section className='d-flex align-items-center' >
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'> Add State</h3>
            </section>
            <div className="container-fluid shadow">
                <form className="row g-3 mt-3" onSubmit={formik.handleSubmit}>
                    <div className="col-sm-6">
                        <label htmlFor="inputEmail4" className="form-label">Country<span className='Star_Mark'>*</span></label>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className='w-100'
                            size='small'
                            value={formik.values.countryId}
                            onChange={(e) => formik.setFieldValue("countryId", e.target.value)}
                        >
                            {
                                listcountry?.map((cur, index) => {
                                    return <MenuItem key={cur?.id || index} value={cur.id} >{cur.countryName}</MenuItem>
                                })
                            }
                        </Select>
                        <Errors formikfun={formik} name={"countryId"} />
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="inputPassword4" className="form-label">State<span className='Star_Mark'>*</span></label>
                        <TextField id="outlined-basic" variant="outlined" className='w-100' size='small'
                            value={formik.values.stateName} placeholder="Enter state"
                            onChange={(e) => formik.setFieldValue("stateName", e.target.value)}
                            onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                        />
                        <Errors formikfun={formik} name={"stateName"} />
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="exampleInputEmail1" className='form-label'>Abbreviation<span className='Star_Mark'>*</span></label>
                        <TextField id="outlined-basic" variant="outlined" className='w-100' size='small'
                            value={formik.values.abbr} placeholder="Enter abbreviation"
                            onChange={(e) => formik.setFieldValue("abbr", e.target.value)}
                            onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                        />

                        <Errors formikfun={formik} name={"abbr"} />
                    </div>
                    <div className="col-12 text-end">
                        <Button type="submit" variant="contained" className='me-2 mui_button_color  mt-4 mb-2' >save</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Add
