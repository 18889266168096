import React from 'react'
import { useNavigate } from 'react-router-dom'

const Setting = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="container-fluid ">
            <h3 className='PAGE-NAME'>Settings</h3>
                <div className="row">
                    <div className="col-12">
                        <ul className="list-style">
                            <li className='tab_hover_Effect' onClick={()=>navigate("symptoms")}> <div className="d-flex justify-content-between align-items-center ">
                                <div>Symptoms </div>
                                <i className="fa-solid fa-angle-right"></i>
                            </div></li>
                            <li className='tab_hover_Effect' onClick={()=>navigate("allergies")} >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>Allergies</div>
                                    <i className="fa-solid fa-angle-right"></i>
                                </div>
                            </li>
                            <li className='tab_hover_Effect' onClick={()=>navigate("chronic-disease")} >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>Chronic Disease</div>
                                    <i className="fa-solid fa-angle-right"></i>
                                </div>
                            </li>
                            <li className='tab_hover_Effect' onClick={()=>navigate("test-required")} >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>Test Required</div>
                                    <i className="fa-solid fa-angle-right"></i>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Setting