import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PaginationCom from '../../Component/pagination';
import useTableSorting from '../../Hooks/useTableSorting';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import NoRecordFound from '../../Component/NoRecordFound';
import Loading from '../../Component/Loading';
import { PROVIDER_LIST } from '../../Repository/ProviderRepo';
import { RoleTitle } from '../../Constants/Constants';
import { FaArrowLeft } from 'react-icons/fa';

const StaffList = () => {
    const [staffList, setStaffList] = useState([])
    const [loading, setLoading] = useState(false)
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)

    const [Filter, setFilter] = useState({
        archive: true,
        staff: "",
        disable_button: true
    })
    const { state } = useLocation();
    const { id } = useParams()
    const navigate = useNavigate()
    const [Action, setAction] = useState(false)
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10 } : state?.controller);
    const headCells = [
        { id: "Staff", numeric: true, disablePadding: false, label: "Staff Name", isShorted: true },
        { id: "Email", numeric: true, disablePadding: false, label: "Email Address", isShorted: true },
        { id: "Mobile", numeric: true, disablePadding: false, label: "Mobile Number", isShorted: true },
        { id: "Invitation Status", numeric: true, disablePadding: false, label: " Invitation Status", isShorted: true },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    async function getLocationList() {
        setLoading(true)
        const { page, rowsPerPage } = controller;
        const { archive, staff } = Filter;
        const keyword = staff.trim();
        const payload = {
            page,
            count: rowsPerPage,
            providerId: id,
            activeStatus: archive,
            keyword: keyword || undefined,
            rolesToList: [RoleTitle?.STAFF],
            getStaffList: true
        };
        try {
            let response = await PROVIDER_LIST(payload)
            setStaffList(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            if (error.message !== "No provider found") toast.error(error.message)
            setStaffList([])
            setPaginationTotalCount(0)
        } finally {
            setLoading(false)
        }
    }
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setAction((pre) => !pre)
        setFilter({ archive: true, staff: "", disable_button: true })
    }

    useEffect(() => {
        getLocationList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, Action])
    return (
        <>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 px-0  d-flex align-items-center">
                        <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                        <h3 className='PAGE-NAME'>Staff</h3>
                    </div>
                </div>
                <section className='d-flex justify-content-between row'>
                    <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className='w-100'
                                    size='small'
                                    value={Filter?.archive}
                                    name='archive'
                                    onChange={handleChangeFilter}
                                >
                                    <MenuItem value={true}>Unarchive</MenuItem>
                                    <MenuItem value={false}>Archive</MenuItem>
                                </Select>
                            </div>
                            <div className=" mt-3 mt-sm-0 col-lg-4 col-sm-6">
                                <TextField id="outlined-basic" name='staff' value={Filter?.staff} onChange={handleChangeFilter} placeholder='Search staff ' variant="outlined" className='w-100' size='small' />
                            </div>
                            <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                                <Button variant="contained" className='me-2 mui_button_color' disabled={Filter?.disable_button} onClick={getLocationList}>search</Button>
                                <Button variant="contained" className='mui_button_color' disabled={Filter?.disable_button} onClick={reset}>reset</Button>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="row">
                    {
                        staffList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                            <NoRecordFound />
                        </div>
                    }
                </div>
                <TableContainer className=" mt-4"   >
                    <Table
                        id="table_mui"
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table">
                        {
                            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={staffList.length} />
                        }
                        <TableBody>
                            {stableSort(staffList, getComparator(order, orderBy)).map(
                                (row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <React.Fragment key={row?.userId}>
                                        <TableRow id="hover_tr" >
                                            <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                {`${row?.firstName} ${row?.lastName}`}
                                            </TableCell>
                                            <TableCell id={labelId} align="left" >{row?.email ? row?.email : "-"}</TableCell>
                                            <TableCell id={labelId} align="left" >{row?.mobile}</TableCell>
                                            <TableCell id={labelId} align="left" > {row?.invitationStatus} </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <section className='w-100 d-flex justify-content-end'>
                    {
                        paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                    }
                </section>

            </div>
        </>
    )
}

export default StaffList