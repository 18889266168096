
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PaginationCom from '../../Component/pagination'
import Dialogs from '../../Component/Dialog'
import { toast } from 'react-toastify';
import { dailogAllMessage, permissionMessage } from '../../Constants/Constants'
import { Button, MenuItem, Select, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material'
import { Table } from 'react-bootstrap'
import useTableSorting from '../../Hooks/useTableSorting'
import AddIcon from '@mui/icons-material/Add';
import NoRecordFound from '../../Component/NoRecordFound'
import { BiArchiveIn } from 'react-icons/bi';
import { EDIT_PLAN, PLAN_LIST } from '../../Repository/SubcripstionPlanRepo'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Loading from '../../Component/Loading';
import { getlocalStorage, intialRole } from '../../utils';
const List = () => {
  const [open, setOpen] = useState(false);
  const [subcriptionList, setSubcriptionList] = useState([])
  const [paginationTotalCount, setPaginationTotalCount] = useState(0)
  const [DeletedData, setDeletedData] = useState({});
  const [Filter, setFilter] = useState({
    archive: true,
    plan: "",
    disable_button: true
  })
  const [loading, setLoading] = useState(false)
  const { state } = useLocation();
  const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
  const naviget = useNavigate()

  const headCells = [
    { id: "title", numeric: false, disablePadding: true, label: "Title", isShorted: true },
    { id: "planType", numeric: false, disablePadding: true, label: "Plan Type", isShorted: true },
    { id: "price", numeric: true, disablePadding: false, label: "Base Plan Price", isShorted: true },
    { id: "AddOnsAmount", numeric: true, disablePadding: false, label: "Add On Amount", isShorted: true },
    { id: "discount", numeric: true, disablePadding: false, label: " Discount", isShorted: true },
    { id: "finalAmount", numeric: true, disablePadding: false, label: " Actual Amount", isShorted: true },
    // { id: "serviceNumber", numeric: true, disablePadding: false, label: " Services Number", isShorted: true },
    // { id: "providerNumber", numeric: true, disablePadding: false, label: " Provider Number", isShorted: true },
    { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
  ];
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  useEffect(() => {
    SubcriptionPlanList()
    naviget({ replace: true, state: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller])

  async function SubcriptionPlanList() {
    setLoading(true)
    const { page, rowsPerPage, searchPage } = controller;
    const { archive, plan } = Filter;
    const keyword = plan.trim();
    const payload = {
      keyword: (searchPage && keyword) ? keyword : undefined,
      page: page,
      count: rowsPerPage,
      activeStatus: archive,
    };

    try {
      let response = await PLAN_LIST(payload)
      setSubcriptionList(response?.data)
      setPaginationTotalCount(response?.totalCount)
    } catch (error) {
      if (error.message !== "No subscription plan found") toast.error(error.message)
      setSubcriptionList([])
      setPaginationTotalCount(0)
    }
    finally {
      setLoading(false)
    }
  }
  const handleClose = async (event, data) => {
    if (event === "YES") {
      setLoading(true)
      let payload = {
        subscriptionPlanId: data?.subscriptionPlanId,
        activeStatus: !data?.activeStatus
      }
      try {
        let response = await EDIT_PLAN(payload)
        toast.success(response.message)
        if (subcriptionList?.length === 1) {
          setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
        } else SubcriptionPlanList()
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
        setOpen(false)
      }
    } else {
      setOpen(!open)
    }
  }
  const handleChangeFilter = (event) => {
    const { name, value } = event.target
    setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
  }
  const reset = () => {
    setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
    setFilter({ archive: true, plan: "", disable_button: true })
  }
  const HandleSearchList = () => {
    setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
  }
  const userData = getlocalStorage("userInfodata")
  const editArchivePermission = (!intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoEditSubscriptionPlan' }) && !intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoActive/In-ActiveSubscriptionPlan' }))
  if (editArchivePermission) {
    headCells.pop()
  }
  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid h-100 d-flex flex-column justify-content-between ">
        <div >
          {/* <h3 className='PAGE-NAME'>Subscription Plan</h3> */}
          <div className="row">
            <div className="col-12 px-0">
              <h3 className='PAGE-NAME'>Subscription Plan</h3>
            </div>
          </div>
          <section className='d-flex justify-content-between row'>
            <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
              {
                intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoView/ListSubscriptionPlan' }) ?
                  <div className="row">
                    <div className="col-lg-4 col-sm-6">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className='w-100'
                        size='small'
                        value={Filter?.archive}
                        name='archive'
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={true}>Unarchive</MenuItem>
                        <MenuItem value={false}>Archive</MenuItem>
                      </Select>
                    </div>
                    <div className="mt-3 mt-sm-0 col-lg-4 col-sm-6">
                      <TextField id="outlined-basic" name='plan' value={Filter?.plan} onChange={handleChangeFilter} placeholder='Search plan ' variant="outlined" className='w-100' size='small' />
                    </div>
                    <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                      <Button variant="contained" className='me-2 mui_button_color' disabled={Filter?.disable_button} onClick={HandleSearchList}>search</Button>
                      <Button variant="contained" className='mui_button_color' disabled={Filter?.disable_button} onClick={reset}>reset</Button>
                    </div>
                  </div>
                  : null
              }
            </div>
            {
              intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoaddSubscriptionPlan' }) ?
                <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                  <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("create-plan")}>
                    Create Plan
                  </Button>
                </div>
                : null
            }
          </section>
          {
            intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoView/ListSubscriptionPlan' }) ?
              <>
                <div className="row">
                  {
                    subcriptionList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                      <NoRecordFound />
                    </div>
                  }
                </div>
                <TableContainer className=" mt-4"  >
                  <Table
                    id="table_mui"
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    {
                      <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={subcriptionList?.length} />
                    }
                    <TableBody>

                      {stableSort(subcriptionList, getComparator(order, orderBy))?.map(
                        (row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return <React.Fragment key={row?.subscriptionPlanId || index}>
                            <TableRow id="hover_tr">
                              <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" > {row?.title} </TableCell>
                              <TableCell id={labelId} align="left" >{row?.planType}</TableCell>
                              <TableCell id={labelId} align="left" >{row?.price}</TableCell>
                              <TableCell id={labelId} align="left" >{row?.AddOnsAmount}</TableCell>
                              <TableCell id={labelId} align="left" >{row?.discount} {row.discountType === "PERCENTAGE" ? "%" : "Flat"}</TableCell>
                              <TableCell id={labelId} align="left" >{row.finalAmount}</TableCell>
                              {/* <TableCell id={labelId} align="left" >{row?.serviceNumber}</TableCell>
                              <TableCell id={labelId} align="left" >{row.providerNumber}</TableCell> */}
                              {
                                !editArchivePermission ?
                                  <TableCell id={labelId} align="right"  >
                                    <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                    <ul className="dropdown-menu">
                                      {
                                        intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoEditSubscriptionPlan' }) ?
                                          <li><button className={`dropdown-item ${row?.activeStatus ? "" : "disabled"} `}
                                            onClick={() => {
                                              naviget(`${row.subscriptionPlanId}`)
                                            }} ><i className="fa-solid fa-pen-to-square all_icons_color me-2"></i>Edit Plan</button>
                                          </li>
                                          : null
                                      }
                                      {
                                        intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoActive/In-ActiveSubscriptionPlan' }) ?
                                          <li><button className="dropdown-item"
                                            onClick={() => { setDeletedData(row); setOpen(!open) }}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus ? "Archive" : "Unarchive"} Plan`}</button></li>
                                          : null
                                      }
                                    </ul>
                                  </TableCell>
                                  : null
                              }
                            </TableRow>
                          </React.Fragment>
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

              </>
              : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
          }
        </div>
        <section className='w-100 d-flex justify-content-center'>
          {
            intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoView/ListSubscriptionPlan' }) && <section className='w-100 d-flex justify-content-center'>
              {
                paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
              }
            </section>
          }
        </section>

      </div>
      <Dialogs handleClose={handleClose} title={DeletedData?.activeStatus ? dailogAllMessage?.subcriptionPlan?.Archive : dailogAllMessage?.subcriptionPlan?.UnArchive} open={open} data={DeletedData} />
    </>
  )
}

export default List
