import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PaginationCom from '../../Component/pagination'
import Dialogs from '../../Component/Dialog'
import { toast } from 'react-toastify';
import { dailogAllMessage, permissionMessage } from '../../Constants/Constants'
import { Button, MenuItem, Select, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material'
import Loading from '../../Component/Loading'
import { Modal, Table } from 'react-bootstrap'
import useTableSorting from '../../Hooks/useTableSorting'
import AddIcon from '@mui/icons-material/Add';
import NoRecordFound from '../../Component/NoRecordFound'
import { BiArchiveIn } from 'react-icons/bi';
import { PROVIDER_LIST, UPDATE_PROVIDER } from '../../Repository/ProviderRepo';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useFormik } from 'formik';
import { Errors } from '../../Component/ErrorShow';
import { pattern } from '../../Validation/Validation';
import { getlocalStorage, intialRole } from '../../utils';
import { RESEND_INVITATION_EMAIL_TEAM } from '../../Repository/TeamManagementRepo';
const TeamList = () => {
  const [open, setOpen] = useState(false);
  const [teamList, setTeamList] = useState([])
  const [paginationTotalCount, setPaginationTotalCount] = useState(0)
  const [DeletedData, setDeletedData] = useState({});
  const [resendInvitation, setResendInvitation] = useState(false)
  const [changeEmail, setChangeEmail] = useState(false)
  const [Filter, setFilter] = useState({
    archive: true,
    team: "",
    disable_button: true
  })
  const [loading, setLoading] = useState(false)
  const { state } = useLocation();
  const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
  const naviget = useNavigate()
  const userData = getlocalStorage("userInfodata")

  const headCells = [
    { id: "firstName", numeric: true, disablePadding: false, label: " Name", isShorted: true },
    { id: "email", numeric: true, disablePadding: false, label: " Email", isShorted: true },
    { id: "mobile", numeric: true, disablePadding: false, label: " Mobile", isShorted: true },
    { id: "invitationStatus", numeric: true, disablePadding: false, label: " Confirmation Status", isShorted: true },
    { id: "Action", numeric: true, disablePadding: false, label: " Action", isShorted: false, showLeft: true, },
  ];
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  useEffect(() => {
    getTeamList()
    naviget({ replace: true, state: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller])
  async function getTeamList() {
    setLoading(true)
    const { page, rowsPerPage, searchPage } = controller;
    const { archive, team } = Filter;
    const keyword = team.trim();
    const payload = {
      keyword: (searchPage && keyword) ? keyword : undefined,
      page: page,
      count: rowsPerPage,
      activeStatus: archive,
      getStaffList: true,
      providerId: userData?.userId,
      filter:"ALL"
    };

    try {
      let response = await PROVIDER_LIST(payload)
      setTeamList(response?.data)
      setPaginationTotalCount(response?.totalCount)
    } catch (error) {
      setTeamList([])
      if (error.message !== "No provider found") toast.error(error.message)
      setPaginationTotalCount(0)
    } finally {
      setLoading(false)
    }
  }
  const handleClose = async (event, data) => {
    if (event === "YES") {
      setLoading(true)
      let payload = {
        staffId: data?.userId,
        activeStatus: !data?.activeStatus
      }
      try {
        let response = await UPDATE_PROVIDER(payload)
        toast.success(response.message)
        setLoading(false)
        setOpen(false)
        if (teamList?.length === 1) {
          setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
        } else getTeamList()
      } catch (error) {
        toast.error(error.message)
        setOpen(false)
        setLoading(false)
      }
    } else {
      setOpen(!open)
    }
  }
  const resendInvitationFun = useFormik({
    initialValues: {
      oldEmail: "",
      newEmail: "",
      name: "",
      staffId: "",
      mobile: ""
    },
    validateOnChange: true,
    validate: (values) => {
      const errors = {};
      if (changeEmail) {
        if (!values?.newEmail) {
          errors.newEmail = "Please enter new email address ";
        } else if (!values?.newEmail?.match(pattern?.EMAIL)) {
          errors.newEmail = "Please enter correct email ";
        }
        else {
          delete errors.newEmail
        }
      }
      return errors;
    },
    onSubmit: async (value, { resetForm }) => {
      let payload = {
        staffId: value?.staffId,
        // providerId: userData?.userId,
        newEmail: changeEmail ? value?.newEmail : value?.oldEmail,
        firstName: value?.name,
        // mobile: value?.mobile
      }
      setLoading(true)
      try {
        let response = await RESEND_INVITATION_EMAIL_TEAM(payload)
        toast.success(response.message)
        setLoading(false)
        setResendInvitation(false)
        getTeamList()
        resetForm()
      } catch (error) {
        toast.error(error.message)
        setResendInvitation(false)
        setLoading(false)
      }
    }
  })
  const Invitation = (row) => {
    if (row?.invitationStatus === "Pending") {
      setResendInvitation(true);
      resendInvitationFun.setFieldValue("oldEmail", row?.email);
      resendInvitationFun.setFieldValue("staffId", row?.userId)
      resendInvitationFun.setFieldValue("name", row?.firstName)
      resendInvitationFun.setFieldValue("mobile", row?.mobile)
    }
  }
  const handleChangeFilter = (event) => {
    const { name, value } = event.target
    setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
  }
  const reset = () => {
    setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
    setFilter({ archive: true, team: "", disable_button: true })
  }
  const HandleSearchList = () => {
    setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
  }
  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <h3 className='PAGE-NAME'>Team Management</h3>
          </div>
        </div>
        <section className='d-flex justify-content-between row'>
          <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
            {
              intialRole({ permission: "Team Management", userData: userData, section: 'ViewTeamMembers' }) ?
                <div className="row">
                  <div className="col-lg-4 col-sm-6">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className='w-100'
                      size='small'
                      value={Filter?.archive}
                      name='archive'
                      onChange={handleChangeFilter}
                    >
                      <MenuItem value={true}>Unarchive</MenuItem>
                      <MenuItem value={false}>Archive</MenuItem>
                    </Select>
                  </div>
                  <div className="mt-3 mt-sm-0 col-lg-4 col-sm-6">
                    <TextField id="outlined-basic" name='team' value={Filter?.team} onChange={handleChangeFilter} placeholder='Search team.. ' variant="outlined" className='w-100' size='small' />
                  </div>
                  <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                    <Button variant="contained" className='me-2 mui_button_color' disabled={Filter?.disable_button} onClick={HandleSearchList}>search</Button>
                    <Button variant="contained" className='mui_button_color' disabled={Filter?.disable_button} onClick={reset}>reset</Button>
                  </div>
                </div>
                : null
            }
          </div>
          {
            intialRole({ permission: "Team Management", userData: userData, section: 'AddTeamMember' }) ?
              <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("add")}>
                  Add team
                </Button>
              </div>
              : null
          }
        </section>
        {
          intialRole({ permission: "Team Management", userData: userData, section: 'ViewTeamMembers' }) ?
            <>
              <div className="row">
                {
                  teamList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                    <NoRecordFound />
                  </div>
                }
              </div>
              <TableContainer className=" mt-4" >
                <Table
                  id="table_mui"
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  {
                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={teamList} />
                  }
                  <TableBody>

                    {stableSort(teamList, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return <React.Fragment key={row?.userId || index}>
                          <TableRow id="hover_tr">
                            <TableCell className="" component="th" id={labelId} scope="row" padding="none" style={{ textTransform: "capitalize" }} >
                              {`${row?.firstName} ${row?.lastName}`}
                            </TableCell>
                            <TableCell id={labelId} align="left" >{row.email}</TableCell>
                            <TableCell id={labelId} align="left" >{row.mobile ? row.mobile : "--"}</TableCell>
                            <TableCell id={labelId} align="left" >{row?.invitationStatus}</TableCell>
                            <TableCell id={labelId} align="right"  >
                              <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                              <ul className="dropdown-menu">
                                {
                                  intialRole({ permission: "Team Management", userData: userData, section: 'EditTeamMemberDetails' }) ?
                                    <li><button className={`dropdown-item ${row?.activeStatus ? "" : "disabled"} `}
                                      onClick={() => {
                                        naviget(`${row.userId}`)
                                      }} ><i className="fa-solid fa-pen-to-square all_icons_color me-2"></i>Edit Team</button></li>
                                    : null
                                }
                                {
                                  row?.invitationStatus === "Pending" && <li><button className={`dropdown-item`}
                                    onClick={() => Invitation(row)}><i className="fa-solid fa-calendar-check all_icons_color me-2"></i>Resend Invitation </button></li>
                                }
                                <li><button className="dropdown-item"
                                  onClick={() => { setDeletedData(row); setOpen(!open) }}><BiArchiveIn className="all_react_icons_color " />{`${row?.activeStatus ? " Archive" : "Unarchive"} Team Member`}</button></li>
                              </ul>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <section className='w-100 d-flex justify-content-end'>
                {
                  paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                }
              </section>
            </>
            : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
        }

      </div>
      <Dialogs handleClose={handleClose} title={DeletedData?.activeStatus ? dailogAllMessage?.Team?.Archive : dailogAllMessage?.Team?.UnArchive} open={open} data={DeletedData} />
      <Modal show={resendInvitation} onHide={() => { setResendInvitation(false); resendInvitationFun.resetForm() }} centered>
        <Modal.Body>
          <div className="text-center">
            <h5 className="modal-title mb-0">Resend Invitation</h5>
          </div>
          <div className="modal-body  cus-i">
            <div className="col-sm-12 mb-3">
              <label className="form-label mb-0">{changeEmail ? "Old Email" : "Email"} <span className='Star_Mark'>*</span></label>
              <input disabled type="email" className="form-control" name='oldEmail' value={resendInvitationFun.values.oldEmail}
                onChange={(e) => resendInvitationFun.setFieldValue(`oldEmail`, e.target.value)} />
            </div>
            <Errors formikfun={resendInvitationFun} name={"oldEmail"} />
            <div className="d-flex justify-content-end">
              <Button variant="contained" style={{ textTransform: "capitalize" }} onClick={() => setChangeEmail((pre) => !pre)} > {!changeEmail ? "Change email" : "Dismiss"} </Button>
            </div>
            {
              changeEmail && <div className="col-sm-12 mb-3">
                <label className="form-label mb-0">New Email<span className='Star_Mark'>*</span></label>
                <input maxLength={50} type="email" className="form-control" name='newEmail' value={resendInvitationFun.values.newEmail}
                  onChange={(e) => resendInvitationFun.setFieldValue(`newEmail`, e.target.value)} />
                <Errors formikfun={resendInvitationFun} name={"newEmail"} />
              </div>
            }
          </div>
          <div className="text-center mt-3">
            <Button variant="contained" className='me-2 mui_button_color' onClick={resendInvitationFun.handleSubmit}>submit</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default TeamList
