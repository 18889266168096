import { axiosInstance } from "../Context/CreateAxios"
import { assignSubcriptionPlan, currentPlanView, inserSubcriptionPlan, paymentHistory, planHistory, subcriptionPlanList, updateSubcriptionPlan } from "../services/Api"
import handleError from "../utils/handleError"

export const PLAN_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(subcriptionPlanList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_PLAN = async (body) => {
    try {
        let response = await axiosInstance.post(inserSubcriptionPlan(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_PLAN = async (body) => {
    try {
        let response = await axiosInstance.post(updateSubcriptionPlan(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ASSIGN_PLAN = async (body) => {
    try {
        let response = await axiosInstance.post(assignSubcriptionPlan(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const PLAN_HISTORY = async (body) => {
    try {
        let response = await axiosInstance.post(planHistory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const PAYMENT_HISTORY = async (body) => {
    try {
        let response = await axiosInstance.post(paymentHistory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CURRENT_PLAN_VIEW = async (body) => {
    try {
        let response = await axiosInstance.post(currentPlanView(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}