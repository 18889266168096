import { axiosInstance } from "../Context/CreateAxios"
import { setupList, insertSetup, updateSetup, } from "../services/Api"
import handleError from "../utils/handleError"

export const SETUP_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(setupList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_SETUP = async (body) => {
    try {
        let response = await axiosInstance.post(insertSetup(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_SETUP = async (body) => {
    try {
        let response = await axiosInstance.post(updateSetup(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}