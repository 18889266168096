import React, { useEffect, useState } from 'react'
import PhoneNumber from '../../Component/PhoneInput'
import { useFormik } from 'formik'
import { Errors } from '../../Component/ErrorShow'
import { SelectBox, TextInput } from '../../Component/Inputs'
import Loader from '../../Component/Loading'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import { TIMEZONE } from '../../utils'
import { convertProviderValidation, pattern } from '../../Validation/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import { CONVERT_TO_PROVIDER, EDIT_LEAD, LEAD_LIST } from '../../Repository/LeadRepo'
import { RoleTitle, TitleOptions } from '../../Constants/Constants'
import { BUSINESS_CATEGORY_LIST } from '../../Repository/BusinessCategoryRepo'
import { FaArrowLeft } from 'react-icons/fa6'
const ConvertProvider = () => {
    const [Loading, setLoading] = useState(false)
    const [categoryList, setCategoryList] = useState([])
    const navigate = useNavigate()
    const { id } = useParams()

    const getlistBusinessCategories = async () => {
        setLoading(true)
        let payload = {
            activeStatus: true,
        }
        try {
            let response = await BUSINESS_CATEGORY_LIST(payload)
            setCategoryList(response?.data)
        } catch (error) {
            setCategoryList([])
        }
        finally {
            setLoading(false)
        }
    }

    async function getLeadDetails() {
        setLoading(true)
        const payload = {
            leadId: id,
        };
        try {
            let response = await LEAD_LIST(payload)
            const { mobile, email, firstName, lastName, title, providerName, businessCategoryId } = response.data
            formik.setValues({
                title,
                firstName,
                lastName,
                mobile,
                email,
                providerName,
                businessCategoryId,
            })
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            title: "",
            firstName: "",
            lastName: "",
            mobile: "",
            email: "",
            providerName: "",
            businessCategoryId: ''
        },
        validationSchema: convertProviderValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (values?.mobile?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
                delete errors.mobile
            } else {
                errors.mobile = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: async (values) => {
            let payload = {
                ...values,
                timeZone: TIMEZONE(),
                image: null,
                roleTitle: RoleTitle?.SUPER_ADMIN
            }
            setLoading(true)
            try {
                await CONVERT_TO_PROVIDER(payload)
                // toast.success(response.message)
                setLoading(false)
                convertProviderFun()
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })

    const convertProviderFun = async () => {
        let payload = {
            leadId: id,
            leadStatus: "CONVERTED"
        }
        setLoading(true)
        try {
            let response = await EDIT_LEAD(payload)
            toast.success(response.message)
            formik.resetForm()
            setTimeout(() => navigate("/layout/lead-list"), 1000)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }

    const handleChangeInput = (event) => {
        const { name, value } = event.target
        formik.setFieldValue(name, value)
    }
    useEffect(() => {
        getLeadDetails()
        getlistBusinessCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {Loading && <Loader />}
            <section className='d-flex align-items-center' >
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'> Convert Provider</h3>
            </section>
            <div className="container-fluid shadow py-3">
                <form className="row g-3 mt-4" onSubmit={formik.handleSubmit} >
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                        <SelectBox
                            placeholder="Select Title"
                            value={formik?.values?.title?.trim()}
                            name="title"
                            keys="value"
                            label="label"
                            menuValeu="value"
                            handleChange={(e) => formik.setFieldValue("title", e.target.value)}
                            option={TitleOptions}
                        />
                        <Errors formikfun={formik} name={"title"} />
                    </div>

                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="firstName"
                            value={formik.values.firstName}
                            type="text"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"firstName"} />
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="lastName"
                            value={formik.values.lastName || ""}
                            type="text"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"lastName"} />
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Provider Name<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="providerName"
                            value={formik.values.providerName || ""}
                            type="text"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"providerName"} />
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Business Category<span className='Star_Mark'>*</span></label>
                        <SelectBox
                            placeholder="Select Title"
                            value={formik?.values?.businessCategoryId}
                            name="title"
                            keys="businessCategoryId"
                            label="categoryName"
                            menuValeu="businessCategoryId"
                            handleChange={(e) => formik.setFieldValue("businessCategoryId", e.target.value)}
                            option={categoryList}
                        />
                        <Errors formikfun={formik} name={"businessCategoryId"} />
                    </div>
                    <div className="col-sm-6 mb-2 ms-0 mt-0 mobile_number_input_box">
                        <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                        <div className="input_group_mobile mb-0">
                            <PhoneNumber
                                width={false}
                                Require={false}
                                Name="mobile"
                                HandleChange={handleChangeInput}
                                Value={formik.values.mobile}
                                disabledInput={false}
                            />
                        </div>
                        <Errors formikfun={formik} name={"mobile"} />
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="email"
                            value={formik.values.email}
                            type="email"
                            size='small'
                            disable={false}
                        />
                        <Errors formikfun={formik} name={"email"} />
                    </div>
                    <div className="col-12 text-end">
                        <Button variant="contained" type="submit" className="my-3 mui_button_color">Save</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ConvertProvider