import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PaginationCom from '../../Component/pagination'
import Dialogs from '../../Component/Dialog'
import { toast } from 'react-toastify';
import { dailogAllMessage, permissionMessage } from '../../Constants/Constants'
import { Button, MenuItem, Select, TableBody, TableCell, TableContainer, TableRow, TextField, } from '@mui/material'
import Loading from '../../Component/Loading'
import { Table } from 'react-bootstrap'
import useTableSorting from '../../Hooks/useTableSorting'
import AddIcon from '@mui/icons-material/Add';
import NoRecordFound from '../../Component/NoRecordFound'
import { COUNTRY_LIST, EDIT_COUNTRY } from '../../Repository/CountryRepo'
import { BiArchiveIn } from 'react-icons/bi';
import { getlocalStorage, intialRole } from '../../utils';
const List = () => {
    const [open, setOpen] = useState(false);
    const [listDatatacountry, setListDataCountry] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [DeletedData, setDeletedData] = useState({});
    const [Filter, setFilter] = useState({
        archive: true,
        country: "",
        disable_button: true
    })
    const [loading, setLoading] = useState(false)
    const { state } = useLocation();
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
    const [Action] = useState(false)
    const naviget = useNavigate()
    useEffect(() => {
        listcountrys()
        naviget({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, Action])
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "countryName", numeric: true, disablePadding: false, label: "Country", isShorted: true },
        { id: "abbr", numeric: true, disablePadding: false, label: " Abbreviation", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

    async function listcountrys() {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, country } = Filter;
        const keyword = country.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
        };
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            if (error.message !== "No country found") toast.error(error.message)
            setListDataCountry([])
            setPaginationTotalCount(0)
        }
        finally {
            setLoading(false)
        }
    }

    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                countryId: data?.id,
                activeStatus: !data?.activeStatus
            }
            try {
                let response = await EDIT_COUNTRY(payload)
                toast.success(response.message)
                setLoading(false)
                setOpen(false)
                if (listDatatacountry?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else listcountrys()
            } catch (error) {
                toast.error(error.message)
                setOpen(false)
                setLoading(false)
            }

        } else {
            setOpen(!open)
        }
    }

    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }

    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, country: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    const userData = getlocalStorage("userInfodata")
    if (!intialRole({ permission: "Country", userData: userData, section: 'AbletoEditCountry' }) && !intialRole({ permission: "Country", userData: userData, section: 'AbletoActive/In-ActiveCountry' })) {
        headCells.pop()
    }
    return (
        <>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 px-0">
                        <h3 className='PAGE-NAME'>Country</h3>
                    </div>
                </div>
                <section className='d-flex justify-content-between row'>
                    <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
                        {
                            intialRole({ permission: "Country", userData: userData, section: 'AbletoView/ListCountry' }) ?
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='w-100'
                                            size='small'
                                            value={Filter?.archive}
                                            name='archive'
                                            onChange={handleChangeFilter}
                                        >
                                            <MenuItem value={true}>Unarchive</MenuItem>
                                            <MenuItem value={false}>Archive</MenuItem>
                                        </Select>
                                    </div>
                                    <div className=" mt-3 mt-sm-0 col-lg-4 col-sm-6">
                                        <TextField id="outlined-basic" name='country' value={Filter?.country} onChange={handleChangeFilter} placeholder='Search Country ' variant="outlined" className='w-100' size='small' />
                                    </div>
                                    <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                                        <Button variant="contained" className='me-2 mui_button_color' disabled={Filter?.disable_button} onClick={HandleSearchList}>search</Button>
                                        <Button variant="contained" className='mui_button_color' disabled={Filter?.disable_button} onClick={reset}>reset</Button>
                                    </div>
                                </div>
                                : null
                        }

                    </div>

                    {
                        intialRole({ permission: "Country", userData: userData, section: 'AbletoaddCountry' }) ?
                            <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("add")}>
                                    Add Country
                                </Button>
                            </div>
                            : null
                    }
                </section>
                {
                    intialRole({ permission: "Country", userData: userData, section: 'AbletoView/ListCountry' }) ?
                        <>
                            <div className="row">
                                {
                                    listDatatacountry?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div>
                                }
                            </div>
                            <TableContainer className=" mt-4"  >
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={listDatatacountry.length} />
                                    }
                                    <TableBody>

                                        {stableSort(listDatatacountry, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={row?.countryId || index}>
                                                    <TableRow id="hover_tr">
                                                        <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                            {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                        </TableCell>
                                                        <TableCell id={labelId} align="left" >{row.countryName}</TableCell>
                                                        <TableCell id={labelId} align="left" >{row?.abbr}</TableCell>
                                                        <TableCell id={labelId} align="right"  >
                                                            {
                                                                intialRole({ permission: "Country", userData: userData, section: 'AbletoEditCountry' }) ?
                                                                    row?.activeStatus === true && <svg onClick={() => naviget(`edit/${row.id}`, { state: { controller } })} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                    </svg>
                                                                    : null
                                                            }
                                                            {
                                                                intialRole({ permission: "Country", userData: userData, section: 'AbletoActive/In-ActiveCountry' }) ?
                                                                    <BiArchiveIn title={`${row?.archive === false ? "Archive" : "Unarchive"}`} className="all_react_icons_color ms-2 " onClick={() => { setDeletedData(row); setOpen(!open) }} />
                                                                    : null}
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <section className='w-100 d-flex justify-content-end'>
                                {
                                    paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </section>
                        </>
                        : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                }

            </div>
            <Dialogs handleClose={handleClose} title={DeletedData?.activeStatus ? dailogAllMessage?.Country?.Archive : dailogAllMessage?.Country?.UnArchive} open={open} data={DeletedData} />
        </>
    )
}

export default List
