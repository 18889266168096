import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PaginationCom from '../../Component/pagination'
import Dialogs from '../../Component/Dialog'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { commonMessages } from '../../Constants/Constants'
import { deletefaqs, listfaqs } from '../../services/Api';
import { Button, Switch } from '@mui/material';

const List = () => {
  const [open, setOpen] = useState(false);
  const [Delete, setDelete] = useState([]);
  const [FaqList, setFaqList] = useState([])
  const [page, setpage] = useState(1)

  const naviget = useNavigate()
  const PER_PAGE = 10
  const ofset = (page - 1) * PER_PAGE
  const title = "Are you sure you want to delete this Faq?";


  useEffect(() => {
    getlistservice()
  }, [])

  const handleClose = (event, id, index) => {
    if (event === "YES") {
      axios.post(deletefaqs(), { faqsId: id.toString() }).then((response) => {
        if (response.data.status === "200") {
          FaqList.data.splice(index, 1)
          setFaqList({ ...FaqList })
          toast.success(response.data.message)
          setOpen(!open)
        } else {
          toast.error(response.data.message)
        }

      }).catch(() => {
        toast.error(commonMessages.NetworkError)
      })
    } else {
      setOpen(!open)
    }
  }
  const getlistservice = () => {
    axios.post(listfaqs(), {}).then((res) => {
      if (res.data.status === "200") {
        setFaqList(res.data)
       
      } else {
        toast.error(res.data.message)
       

      }
    }).catch(() => {
      toast.error(commonMessages.NetworkError)
     

    })
  }
  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <section className='top_section'>
          <Button className='add_btn' onClick={() => naviget("/faq/add")} >+ Add FAQ</Button>
        </section>
        <table className="table  shadow mt-3">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th className='text-start' scope="col">Question</th>
              <th className='text-start' scope="col">Answer</th>
              <th className='text-start' scope='col'>Status</th>
              <th className='text-end pe-5' scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {

              FaqList.data?.slice(ofset, ofset + PER_PAGE).map((cur, index) => {
                return <>
                  <tr>
                    <th scope="row">{PER_PAGE * (page - 1) + index + 1}</th>
                    <td className='text-start'>{`${cur.question.slice(0, 20)}`}</td>
                    <td className='text-start'>{`${cur.answer.slice(0, 50)}`}</td>
                    <td className='text-start'><Switch size='small' /></td>
                    <td >
                      <tr className='d-flex justify-content-end'>
                        <td><svg onClick={() => naviget(`/faq/view/${cur.faqId}`)} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#315e99" className="bi bi-eye-fill" viewBox="0 0 16 16">
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                        </svg>
                        </td>
                        <td>
                          <svg onClick={() => naviget(`/faq/edit/${cur.faqId}`)} style={{ cursor: "pointer", }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>
                        </td>
                        <td><svg onClick={() => { setDelete({ id: cur.faqId, index }); setOpen(!open) }} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#e62222" className="bi bi-trash-fill" viewBox="0 0 16 16">
                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg></td>
                      </tr>
                    </td>
                  </tr>
                  {cur?.faqId === Delete?.id && <Dialogs handleClose={handleClose} title={title} open={open} data={Delete} />}

                </>
              })
            }


          </tbody>
        </table>
        <section className='w-100 d-flex justify-content-center'>
          {
            FaqList?.data?.length > 10 && <PaginationCom page={page} setpage={setpage} count={Math.ceil(FaqList?.data?.length / PER_PAGE)} />
          }
        </section>

      </div>

    </>
  )
}

export default List
