
import React, { useEffect, useState } from 'react'
import logo from '../../Assets/logo.png';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { DECLINE_INTITATION } from '../../Repository/AuthRepo';

const RejectInvitation = ({ type }) => {
    const { token } = useParams()
    const [message, setMessage] = useState('')
    useEffect(() => {
        declineInvitation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const declineInvitation = async () => {
        let payload = {
            token: token
        }
        try {
            await DECLINE_INTITATION(payload)
        } catch (error) {
            toast.error(error?.message)
            setMessage(error?.message)
        }
    }
    return (
        <>
            <ToastContainer />
            <section className="bgImg ">
                <nav className="navbar navbar-expand-lg pb-2 pb-mg-0 pb-lg-0">
                    <div className="container-fluid">
                        <img src={logo} height="50px" className="img-fluid my-3"
                            style={{ margin: '0px !important', maxWidth: '100%', padding: '0px' }} width="60px" alt="" />
                    </div>
                </nav>

                <div className=" padd Token_expired ">
                    <div className="my-5">
                        {message === 'Token expired' ? <h2> Your token has expired</h2> : <h2>You have declined Invitation</h2>}
                    </div>
                </div>

            </section>
        </>
    )
}

export default RejectInvitation