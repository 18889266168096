import React from 'react'
import { Routes, Route, } from 'react-router-dom'

//
import Profile from '../Pages/Profile/Index'
// State
import AddState from '../Pages/State/Add'
import EditState from '../Pages/State/Edit'
import ListState from '../Pages/State/List'
// country
import ListCountry from '../Pages/Country/List'
import AddCountry from '../Pages/Country/Add'
import EditCountry from '../Pages/Country/Edit'
// provider
import ProviderList from '../Pages/Provider/List'
// Change pin
import ChangePin from '../Pages/ChangePin/changePin'
// Expertise
import Expertise from '../Pages/Expertise'
// service
import ServiceList from '../Pages/Expertise/Services/List'
import ServiceAdd from '../Pages/Expertise/Services/Add'
import ServiceEdit from '../Pages/Expertise/Services/Edit'

// Specialization
import SpecializationList from '../Pages/Expertise/Specialization/List'
import SpecializationAdd from '../Pages/Expertise/Specialization/Add'
import SpecializationEdit from '../Pages/Expertise/Specialization/Edit'

//  Business Categories
import BusinessCategoriesAdd from '../Pages/BusinessCategories/Add'
import BusinessCategoriesList from '../Pages/BusinessCategories/List'
import BusinessCategoriesEdit from '../Pages/BusinessCategories/Edit'

// FAQ
import FAQAdd from '../Pages/FAQ/Add'
import FAQEdit from '../Pages/FAQ/Edit'
import FAQList from '../Pages/FAQ/List'
import FAQView from '../Pages/FAQ/View'


// time zone
import TimeZoneList from '../Pages/TimeZone/List'
import Dashboard from '../Pages/deshboard/Dashboard'

// Vaccination Library
import VaccinationCategory from '../Pages/VaccinationLibrary/VaccinationCategory'
import VaccinationCalendar from '../Pages/VaccinationLibrary/VaccinationCalendar'
import VaccinationForm from '../Pages/VaccinationLibrary/VaccinationForm'
import VaccinationList from '../Pages/VaccinationLibrary/VaccinationList'
// Pricing Plan

import Planlist from '../Pages/SubscriptionPlans/List'
import CreatePlan from '../Pages/SubscriptionPlans/CreatePlan'
import EditPlan from '../Pages/SubscriptionPlans/EditPlan'

// RevenueReport
import RevenueReport from '../Pages/RevenueReport/Index'

// Lifestyles Habit
import ListLifestylesHabit from '../Pages/LifestylesHabit/List'
import AddLifestylesHabit from '../Pages/LifestylesHabit/Add'
import EditLifestylesHabit from '../Pages/LifestylesHabit/Edit'

// Team Member

import TeamList from '../Pages/TeamManagement/List'
import AddTeam from '../Pages/TeamManagement/AddTeam'
import EditTeam from '../Pages/TeamManagement/EditTeam'
// lead

import LeadList from '../Pages/Leads/LeadList'
import AddLead from '../Pages/Leads/AddLead'
import EditLead from '../Pages/Leads/EditLead'
import ConvertProvider from '../Pages/Leads/ConvertProvider'
//Provider

import PlanHistory from '../Pages/Provider/PlanHistory'
import ViewPlan from '../Pages/Provider/ViewPlan'

// setting
import Setting from '../Pages/Setting'
import Symptoms from '../Pages/Setting/Symptoms'
import ChronicDisease from '../Pages/Setting/ChronicDisease'
import TestRequired from '../Pages/Setting/TestRequired'
import Allergies from '../Pages/Setting/Allergies'
import VaccinationFormUpdate from '../Pages/VaccinationLibrary/VaccinationFormUpdate'
import AddProvider from '../Pages/Provider/AddProvider'
import EditProvider from '../Pages/Provider/EditProvider'
import LocationList from '../Pages/Provider/LocationList'
import PaymentHistory from '../Pages/Provider/PaymentHistory'
import StaffList from '../Pages/Provider/StaffList'

// roles & Permissions
import RolesList from '../Pages/Roles/List'

// cancellation policy

import CancellationPolicyList from '../Pages/CancellationPolicy/List'
import AddPolicy from '../Pages/CancellationPolicy/Add'
import EditPolicy from '../Pages/CancellationPolicy/Edit'
import AddRoles from '../Pages/Roles/Add'
import EditRoles from '../Pages/Roles/Edit'
import { ProtectedRoute } from './ProtectedRoute '
import { getlocalStorage, intialRole } from '../utils'
import Page404 from '../Pages/404page'
import Notification from '../Pages/Notification/Notification'



const AllRoutes = () => {
    const userData = getlocalStorage("userInfodata")
    intialRole({ permission: "State", userData: userData, section: 'AbletoaddState' })
    return (
        <>
            <Routes>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='profile' element={<Profile />} />
                <Route path='state' element={<ListState />} />
                <Route path='State/add' element={<ProtectedRoute user={intialRole({ permission: "State", userData: userData, section: 'AbletoaddState' })}>
                    <AddState />
                </ProtectedRoute>} />
                <Route path='State/edit/:id' element={<ProtectedRoute user={intialRole({ permission: "State", userData: userData, section: 'AbletoEditState' })}>
                    <EditState />
                </ProtectedRoute>} />
                {/*country  */}
                <Route path='country' element={<ListCountry />} />
                <Route path='country/add' element={<ProtectedRoute user={intialRole({ permission: "Country", userData: userData, section: 'AbletoaddCountry' })}>
                    <AddCountry />
                </ProtectedRoute>} />
                <Route path='country/edit/:id' element={<ProtectedRoute user={intialRole({ permission: "Country", userData: userData, section: 'AbletoEditCountry' })}>
                    <EditCountry />
                </ProtectedRoute>} />
                {/*provider  */}
                <Route path='provider' element={<ProviderList />} />
                <Route path='provider/create-provider' element={<ProtectedRoute user={intialRole({ permission: "Provider LIST", userData: userData, section: 'AbletoaddProvider' })}>
                    <AddProvider />
                </ProtectedRoute>} />
                <Route path='provider/edit-provider/:id' element={<ProtectedRoute user={intialRole({ permission: "Provider LIST", userData: userData, section: 'AbletoEditProviderInformation' })}>
                    <EditProvider />
                </ProtectedRoute>} />
                <Route path='provider/view-plan/:id' element={<ViewPlan />} />
                <Route path='provider/plan-history/:id' element={<PlanHistory />} />
                <Route path='provider/payment-history/:id' element={<PaymentHistory />} />
                <Route path='provider/staff-list/:id' element={<StaffList />} />
                <Route path='provider/location-list/:id' element={<LocationList />} />
                {/* Expertise */}
                <Route path='expertise' element={<Expertise />} />
                {/*ServicesS*/}
                <Route path='expertise/services' element={<ServiceList />} />
                <Route path='expertise/services/add' element={<ProtectedRoute user={intialRole({ permission: "Services", userData: userData, section: 'AddServices' })}>
                    <ServiceAdd />
                </ProtectedRoute>} />
                <Route path='expertise/services/edit/:id' element={<ProtectedRoute user={intialRole({ permission: "Services", userData: userData, section: 'EditServices' })}>
                    <ServiceEdit />
                </ProtectedRoute>} />
                {/*Specialization*/}
                <Route path='expertise/specialization' element={<SpecializationList />} />
                <Route path='expertise/specialization/add' element={<ProtectedRoute user={intialRole({ permission: "Expertise", userData: userData, section: 'AddSpecializationCategory' })}>
                    <SpecializationAdd />
                </ProtectedRoute>} />
                <Route path='expertise/specialization/edit/:id' element={<ProtectedRoute user={intialRole({ permission: "Expertise", userData: userData, section: 'EditSpecializationCategory' })}>
                    <SpecializationEdit />
                </ProtectedRoute>} />
                {/* Business Categories */}
                <Route path='business-category' element={<BusinessCategoriesList />} />
                <Route path='business-category/add' element={<ProtectedRoute user={intialRole({ permission: "Business Category", userData: userData, section: 'AbletoaddBusinessCategory' })}>
                    <BusinessCategoriesAdd />
                </ProtectedRoute>} />
                <Route path='business-category/edit/:id' element={<ProtectedRoute user={intialRole({ permission: "Business Category", userData: userData, section: 'AbletoEditBusinessCategory' })}>
                    <BusinessCategoriesEdit />
                </ProtectedRoute>}
                />
                {/* FAQ */}
                <Route path='faq' element={<FAQList />} />
                <Route path='faq/add' element={<FAQAdd />} />
                <Route path='faq/edit/:id' element={<FAQEdit />} />
                <Route path='faq/view/:id' element={<FAQView />} />
                {/* time zone */}
                <Route path='time-zone' element={<TimeZoneList />} />
                {/* Vaccination Library */}
                <Route path='vaccination' element={<VaccinationCategory />} />
                <Route path='vaccination/:id' element={<VaccinationCalendar />} />
                <Route path='vaccination/:id/add-vaccinaion-form' element={<ProtectedRoute user={intialRole({ permission: "Vaccination calendar", userData: userData, section: 'AddVaccinationCalendar' })}>
                    <VaccinationForm />
                </ProtectedRoute>} />
                <Route path='vaccination/:id/vaccinaion-list/:from_id' element={<VaccinationList />} />
                <Route path='vaccination/:id/update/:from_id' element={<ProtectedRoute user={intialRole({ permission: "Vaccination calendar", userData: userData, section: 'EditVaccinationCalendar' })}>
                    <VaccinationFormUpdate />
                </ProtectedRoute>} />
                {/* Pricing Plan */}
                <Route path='subscription-plans' element={<Planlist />} />
                <Route path='subscription-plans/create-plan' element={<ProtectedRoute user={intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoaddSubscriptionPlan' })}>
                    <CreatePlan />
                </ProtectedRoute>} />
                <Route path='subscription-plans/:id' element={<ProtectedRoute user={intialRole({ permission: "Subscription Plan", userData: userData, section: 'AbletoEditSubscriptionPlan' })}>
                    <EditPlan />
                </ProtectedRoute>} />
                <Route path='team-list' element={<TeamList />} />
                <Route path='team-list/add' element={<ProtectedRoute user={intialRole({ permission: "Team Management", userData: userData, section: 'AddTeamMember' })}>
                    <AddTeam />
                </ProtectedRoute>} />
                <Route path='team-list/:id' element={<ProtectedRoute user={intialRole({ permission: "Team Management", userData: userData, section: 'EditTeamMemberDetails' })}>
                    <EditTeam />
                </ProtectedRoute>} />
                {/* lead */}
                <Route path='lead-list' element={<LeadList />} />
                <Route path='lead-list/add-lead' element={<ProtectedRoute user={intialRole({ permission: "Lead Management", userData: userData, section: 'AbletoaddLeads' })}>
                    <AddLead />
                </ProtectedRoute>} />
                <Route path='lead-list/:id' element={<ProtectedRoute user={intialRole({ permission: "Lead Management", userData: userData, section: 'AbletoEditLeads' })}>
                    <EditLead />
                </ProtectedRoute>} />
                <Route path='lead-list/convert-provider/:id' element={<ConvertProvider />} />
                {/* RevenueReport */}
                <Route path='revenue-report' element={<RevenueReport />} />
                {/* Lifestyles Habit */}
                <Route path='lifestyles-habit' element={<ListLifestylesHabit />} />
                <Route path='lifestyles-habit/add' element={<ProtectedRoute user={intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoaddLifestyleHabits' })}>
                    <AddLifestylesHabit />
                </ProtectedRoute>} />
                <Route path='lifestyles-habit/edit/:id' element={<ProtectedRoute user={intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoEditLifestyleHabits' })}>
                    <EditLifestylesHabit />
                </ProtectedRoute>} />
                {/* Notification */}
                <Route path='notification' element={<Notification />} />
                {/* setting */}
                <Route path='setting' element={<Setting />} />
                <Route path='setting/symptoms' element={<Symptoms />} />
                <Route path='setting/chronic-disease' element={<ChronicDisease />} />
                <Route path='setting/test-required' element={<TestRequired />} />
                <Route path='setting/allergies' element={<Allergies />} />
                {/* Roles Permissions */}
                <Route path='roles-permission' element={<RolesList />} />
                <Route path='roles-permission/add-roles' element={<ProtectedRoute user={intialRole({ permission: "Roles & permissions", userData: userData, section: 'AbletoviewRoles&PermissionsofSuperAdmin' })}>
                    <AddRoles />
                </ProtectedRoute>} />
                <Route path='roles-permission/edit-roles/:id' element={<ProtectedRoute user={intialRole({ permission: "Roles & permissions", userData: userData, section: 'EditRoles&Permissions' })}>
                    <EditRoles />
                </ProtectedRoute>} />
                {/* cancellation-policy */}
                <Route path='cancellation-policy' element={<CancellationPolicyList />} />
                <Route path='cancellation-policy/add' element={<ProtectedRoute user={intialRole({ permission: "Cancelation Policy", userData: userData, section: 'AbletoAddCancelationPolicy' })} >
                    <AddPolicy />
                </ProtectedRoute>} />
                <Route path='cancellation-policy/edit/:id' element={<ProtectedRoute user={intialRole({ permission: "Cancelation Policy", userData: userData, section: 'AbletoEditCancelationPolicy' })} >
                    <EditPolicy />
                </ProtectedRoute>} />
                {/*change pin  */}
                <Route path='change-pin' element={<ChangePin />} />
                <Route path='*' element={<Page404 />} />
            </Routes>

        </>
    )
}

export default AllRoutes
