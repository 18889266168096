import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { TIMEZONE_LIST } from '../../../Repository/TimezoneRepo';

const useList = () => {
    const [timeZone, setTimeZone] = useState([])
    const [loading, setLoading] = useState(false)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [Filter, setFilter] = useState({
        timeZone: "",
        disable_button: true,
        isActive: true
    })
    useEffect(() => {
        listTimeZone()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "abbr", numeric: true, disablePadding: false, label: "Abbreviation", isShorted: true },
        { id: "text", numeric: false, disablePadding: false, showLeft: false, label: "Time Zone", isShorted: true },
        { id: "timezone", numeric: true, disablePadding: false, label: "Location", isShorted: true, showLeft: true },
    ];
    const listTimeZone = async () => {
        setLoading(true)
        const { page, rowsPerPage } = controller;
        const { isActive, timeZone } = Filter;
        const keyword = timeZone.trim();
        const payload = {
            keyword: keyword || undefined,
            page: keyword ? undefined : page,
            count: keyword ? undefined : rowsPerPage,
            activeStatus: isActive,
        };
        try {
            let response = await TIMEZONE_LIST(payload)
            setTimeZone(response.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            if (error.message !== "No timezone found") toast.error(error.message)
            setTimeZone([])
            setPaginationTotalCount(0)
        }
        finally {
            setLoading(false)
        }


    }
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ timeZone: "", disable_button: true, isActive: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    return {
        reset,
        setController,
        handleChangeFilter,
        HandleSearchList,
        timeZone,
        controller,
        paginationTotalCount,
        headCells,
        loading,
        Filter,
    }
}

export default useList
