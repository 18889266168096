import React from 'react'
import { Table } from 'react-bootstrap'
import { Button, TableBody, TableCell, TableContainer, TableRow, ButtonGroup } from '@mui/material'

import Loading from '../../Component/Loading'
import NoRecordFound from '../../Component/NoRecordFound'
import PaginationCom from '../../Component/pagination'

import useTableSorting from '../../Hooks/useTableSorting'
import useNotification from './useNotification'
import moment from 'moment-timezone'

const Notification = () => {
  const {
    notificationList,
    controller,
    loading,
    paginationTotalCount,
    headCells,
    filter,
    notificationcount,
    HandleReadNotification,
    setFilter,
    setController,
  } = useNotification()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <h3 className='PAGE-NAME'>Notification</h3>
          </div>
        </div>
        <section className='d-flex justify-content-between row'>
          <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons">
              <Button variant="outlined" onClick={() => { setFilter("UNREAD"); setController((pre) => ({ ...pre, page: 1 })) }} style={{ background: ` ${filter === "UNREAD" ? "#000" : "#fff"} `, color: ` ${filter === "UNREAD" ? "#fff" : "#000"} `, border: filter === "UNREAD" ? "done" : "1px solid #000", padding: "5px 8px", fontSize: "13px" }}>unread</Button>
              <Button variant="contained" onClick={() => setFilter("ALL")} style={{ background: ` ${filter === "ALL" ? "#000" : "#fff"} `, color: ` ${filter === "ALL" ? "#fff" : "#000"} `, padding: "5px 8px", fontSize: "13px" ,border: filter === "ALL" ? "done" : "1px solid #000"}}> all</Button>
            </ButtonGroup>
            <Button style={{ background: "#000", padding: "6px 8px", fontSize: "13px" }} onClick={() => { notificationcount > 0 && HandleReadNotification("ALL"); setController((pre) => ({ ...pre, page: 1 })) }} variant="contained" className="mark_all_btn ms-3">Mark all read</Button>
          </div>
        </section>
        <div className="row">
          {
            notificationList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
              <NoRecordFound />
            </div>
          }
        </div>
        <TableContainer className=" mt-4"  >
          <Table
            id="table_mui"
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            {
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={notificationList.length} />
            }
            <TableBody>
              {stableSort(notificationList, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return <React.Fragment key={index}>
                    <TableRow onClick={() => row?.readStatus !== true && HandleReadNotification(row?.notificationId)} id="hover_tr" style={{ backgroundColor: `${row?.readStatus !== true ? "#f5f5f5" : "transparent"}`, cursor: "pointer" }} >
                      <TableCell className="ps-3" id={labelId} scope="row" padding="none"  >
                        {controller.rowsPerPage * (controller.page - 1) + index + 1}
                      </TableCell>
                      <TableCell id={labelId} align="left" style={{ whiteSpace: "nowrap" }}  >{row?.title}</TableCell>
                      <TableCell id={labelId} align="left" style={{ whiteSpace: "normal" }} >{`${row?.message}`}</TableCell>
                      <TableCell id={labelId} align="right" style={{ whiteSpace: "nowrap" }} >{moment(row?.date).format('ll')} </TableCell>
                    </TableRow>
                  </React.Fragment>
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <section className='w-100 d-flex justify-content-end'>
          {
            paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
          }
        </section>
      </div>
    </>
  )
}

export default Notification