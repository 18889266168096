import {useState} from 'react'
const useVaccinationList = () => {
    const [loader, ] = useState(false)
    const [vaccinationList, ] = useState([
        {
            vaccineId: 11,
            vaccineName: "dummy name",
            givenOn: null,
            brandName: "hgjhgj",
            dueDate: "2024-01-12",
            comment: "ewrewrre",
            age: 0,
            timeFrame: null
        }
    ])
    const headCells = [
        { id: "Vaccine", numeric: true, disablePadding: false, label: "Vaccine" },
        { id: "GivenOn", numeric: true, disablePadding: false, label: "Given On" },
        { id: "DueDate", numeric: true, disablePadding: false, label: "Due Date" },
        { id: "age", numeric: true, disablePadding: false, label: "Age" },
        { id: "Comment", numeric: true, disablePadding: false, label: "Comment" },
    ];
  return {
    headCells,
    loader ,
    vaccinationList
  }
}

export default useVaccinationList




