import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import logo from "../Assets/dummyimg.png"
import Loading from '../Component/Loading'
import { AiOutlineHome } from 'react-icons/ai';
import { RiEarthFill } from 'react-icons/ri';
import { PiUserList } from 'react-icons/pi';
import { SlCalender } from 'react-icons/sl';
import { BiSolidBusiness } from 'react-icons/bi';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { TbReportAnalytics } from 'react-icons/tb';
import { FiSettings } from 'react-icons/fi';
import { MdOutlineRealEstateAgent } from 'react-icons/md';
import { FaRegUserCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom/dist';
import { Modal } from 'react-bootstrap';
import { LOGOUT } from '../Repository/AuthRepo';
import { ToastContainer, toast } from 'react-toastify';
import { UserImageFilePath } from '../services/Api'
import { getlocalStorage } from '../utils'
import { FaUsers } from "react-icons/fa";
import { AiOutlineFileAdd } from "react-icons/ai";
import { Button, IconButton, Menu, MenuItem, alpha, styled, Badge } from "@mui/material";
import { MdSecurity } from "react-icons/md";
import { IoMenuSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import Offcanvas from 'react-bootstrap/Offcanvas';
import AllRoutes from '../AllRoutes/AllRoutes'
import { MdOutlinePolicy } from "react-icons/md";
import { IoIosNotificationsOutline, IoMdNotifications } from "react-icons/io";
import { UserInfo } from '../App';
import { RoleTitle } from '../Constants/Constants';
import { NOTIFICATION_LIST } from '../Repository/NotificationRepo';
import config from '../environment/environment';
import useBroserNotification from '../Hooks/useBroserNotification';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
const Layout = () => {

    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [toggle] = useState(false)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    const [openMenu, setOpenMenu] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [notificationPermission, setNotificationPermission] = useState(false)
    const OPEN = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const userData = getlocalStorage("userInfodata")
    const { notificationcount, setNotificationcount, setSocket } = useContext(UserInfo)
    const [onmessage, setOnmessage] = useState(false)
    const { showNotification } = useBroserNotification()
    useEffect(() => {
        if (!userData?.authToken) navigate("/")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let notificationPermission = false
        if (notificationPermission === false) {
            Notification.requestPermission().then((res) =>
                notificationPermission = res === 'granted' ? true : false
            )
            setNotificationPermission(notificationPermission)
        }
    }, [notificationPermission, onmessage])
    useEffect(() => {
        let ws;
        let reconnectInterval;
        const connectWebSocket = () => {
            ws = new WebSocket(`${config.socketUrl}/winkdoc-api/websocket`);
            ws.onopen = () => {
                console.log("WebSocket onopen")
                setSocket(ws);
                const data = {
                    eventType: "register",
                    userId: userData?.userId,
                    roleTitle: userData?.roleTitle,
                };
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify(data));
                } else {
                    ws.onopen = () => {
                        ws.send(JSON.stringify(data));
                    };
                }
            };
            ws.onmessage = (event) => {
                console.log("WebSocket onmessage")
                const wsdata = JSON.parse(event.data);
                setOnmessage((pre) => !pre)
                console.log(wsdata, "socket data")
                if (wsdata?.data?.eventType === "newNotification") {
                    getListNotification();
                    let notificationData = { message: wsdata?.data?.message,type: wsdata?.data?.type };
                    showNotification(notificationData);
                }
            };
            ws.onerror = () => {
                console.error('WebSocket error');
                if (userData?.authToken) {
                    attemptReconnect()
                }
            };

            ws.onclose = () => {
                console.log('WebSocket onclose')
                if (userData?.authToken) {
                    attemptReconnect()
                }
            };
        };
        const attemptReconnect = () => {
            clearInterval(reconnectInterval);
            reconnectInterval = setInterval(() => {
                if (ws?.readyState === WebSocket?.CLOSED) {
                    connectWebSocket();
                } else {
                    clearInterval(reconnectInterval);
                }
            }, 5000);
        };
        connectWebSocket();
        getListNotification();
        return () => {
            if (ws) {
                ws?.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //


    async function getListNotification() {
        setLoading(true)
        const payload = {
            filter: "ALL",
            [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
            roleTitle: userData?.rolesAndPermission?.roleName
        };
        try {
            let response = await NOTIFICATION_LIST(payload)
            setNotificationcount(response?.unreadCount)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const Logout = async (type) => {
        if (type === "YES") {
            setLoading(true)
            let payload = {
                userId: userData?.userId,
                authToken: userData?.authToken,
            }
            try {
                let response = await LOGOUT(payload)
                toast.success(response.message)
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(() => {
                    navigate("/");
                    setLoading(false)
                }, 800)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        } else {
            setShow(false)
        }
    }
    const closeSubMenu = (type) => {
        setOpenMenu(false)
    }
    // const ToggleSideBar = (type) => {
    //     let data = { ...submenu }
    //     for (const key in data) {
    //         if (data.hasOwnProperty(key)) {
    //             if (data[key] == true) {
    //                 setActiveMenu({
    //                     action: data[key],
    //                     name: key
    //                 })
    //             }
    //         }
    //     }
    //     closeSubMenu("Toggle",toggle)
    //     setToggle(pre => !pre)
    // }


    const fullName = `${userData?.firstName} ${userData?.lastName}`;
    const capitalizedFullName = fullName.replace(/\b\w/g, (char) => char.toUpperCase());


    const SideBarMenu = () => {
        return (
            <>
                <ul className='px-0'>
                    <li>
                        <NavLink to="#">
                            <span className="icon">
                                <FaRegUserCircle className='icons' />
                            </span>
                            <span className="title">Admin Panel</span>
                        </NavLink>
                    </li>

                    <li className={`${splitLocation[2] === "dashboard" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="dashboard">
                            <span className="icon">
                                <AiOutlineHome className='icons' />
                            </span>
                            <span className="title">Dashboard</span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "state" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="state">
                            <span className="icon">
                                <MdOutlineRealEstateAgent className='icons' />
                            </span>
                            <span className="title"> State</span>
                        </NavLink>
                    </li>

                    <li className={`${splitLocation[2] === "country" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="country">
                            <span className="icon">
                                <ion-icon name="globe-outline"></ion-icon>
                            </span>
                            <span className="title"> Country</span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "time-zone" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="time-zone">
                            <span className="icon">
                                <RiEarthFill className='icons' />
                            </span>
                            <span className="title"> Time Zone</span>
                        </NavLink>
                    </li>

                    <li className={`${splitLocation[2] === "provider" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="provider">
                            <span className="icon">
                                <PiUserList className='icons' />
                            </span>
                            <span className="title">Provider List</span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "vaccination" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="vaccination">
                            <span className="icon">
                                <SlCalender className='icons' style={{ fontSize: "22px" }} />
                            </span>
                            <span className="title"> Vaccination Library </span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "business-category" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="business-category">
                            <span className="icon">
                                <BiSolidBusiness className='icons' />
                            </span>
                            <span className="title"> Business Category</span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "subscription-plans" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="subscription-plans">
                            <span className="icon">
                                <BiSolidBusiness className='icons' />
                            </span>
                            <span className="title">Subscription Plan</span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "cancellation-policy" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="cancellation-policy">
                            <span className="icon">
                                <MdOutlinePolicy className='icons' />
                            </span>
                            <span className="title">Cancellation Policy</span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "team-list" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="team-list">
                            <span className="icon">
                                <FaUsers className='icons' />
                            </span>
                            <span className="title">Team Management</span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "lead-list" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="lead-list">
                            <span className="icon">
                                <AiOutlineFileAdd className='icons' />
                            </span>
                            <span className="title"> Leads </span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "expertise" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="expertise">
                            <span className="icon">
                                <FaHandHoldingHeart className='icons' />
                            </span>
                            <span className="title"> Expertise</span>
                        </NavLink>
                    </li>
                    <li className={`${splitLocation[2] === "notification" ? "hovered" : ""} `} onClick={closeSubMenu}>
                        <NavLink to="notification">
                            <span className="icon ">
                                <Badge color="error" badgeContent={notificationcount}>
                                    <IoMdNotifications className='icons' />
                                </Badge>
                            </span>
                            <span className="title"> Notification</span>
                        </NavLink>
                    </li>
                    <li onClick={closeSubMenu}>
                        <NavLink to="revenue-report" >
                            <span className="icon">
                                <TbReportAnalytics className='icons' />
                            </span>
                            <span className="title"> Revenue Report </span>
                        </NavLink>
                    </li>
                    <li onClick={closeSubMenu} className={`${splitLocation[2] === "roles-permission" ? "hovered" : ""} `}>
                        <NavLink to="roles-permission" >
                            <span className="icon">
                                <MdSecurity className='icons' />
                            </span>
                            <span className="title">Roles & Permissions</span>
                        </NavLink>
                    </li>

                    <li onClick={closeSubMenu} className={`${splitLocation[2] === "lifestyles-habit" ? "hovered" : ""} `}>
                        <NavLink to="lifestyles-habit">
                            <span className="icon">
                                <i className={` fas fa-smoking mainhover icons`} style={{ fontSize: "22px" }}></i>
                            </span>
                            <span className="title"> Lifestyles habits  </span>
                        </NavLink>
                    </li>
                    <li onClick={closeSubMenu} className={`${splitLocation[2] === "setting" ? "hovered" : ""} `}>
                        <NavLink to="setting" >
                            <span className="icon">
                                <FiSettings className='icons' />
                            </span>
                            <span className="title"> Settings  </span>
                        </NavLink>
                    </li>
                </ul>
            </>
        )
    }

    return (
        <>
            {loading && <Loading />}
            <ToastContainer />
            <div className=" row px-0 mx-0">
                <div className={`navigation ${toggle ? "active" : ""} col-xxl-2 col-xl-3 col-lg-4  d-lg-block d-none`}>
                    <SideBarMenu />
                </div>
                <div className={`main ${toggle ? "active" : ""} col-xxl-10  col-xl-9 px-0 col-lg-8 `}>
                    <div className="topbar shadow d-flex justify-content-lg-end justify-content-between align-items-center">
                        <IoMenuSharp style={{ color: "gray", fontSize: "30px" }} className='d-lg-none d-block' onClick={() => setOpenMenu(true)} />
                        <div className='w-75 d-flex align-items-center justify-content-end'>
                          
                            <div className='me-2 USER_PROFILE_NAME'>
                                <h5 className='mb-0' >{capitalizedFullName}</h5>
                            </div>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={OPEN ? 'long-menu' : undefined}
                                aria-expanded={OPEN ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <div>
                                    <img style={{ borderRadius: "50%", width: "48px", height: "48px", padding: "4px" }} src={userData?.image ? UserImageFilePath + userData?.image : logo} alt="" />
                                </div>
                                {/* <Avatar alt={capitalizedFullName} style={{ cursor: "pointer", padding:'8px', height:'55px', width:'55px' }} src={userData?.image ? UserImageFilePath + userData?.image : logo} /> */}
                            </IconButton>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={OPEN}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => { handleClose(); navigate("profile") }} disableRipple>
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={() => { handleClose(); navigate('change-pin') }} disableRipple>
                                    Change PIN
                                </MenuItem>
                                <MenuItem onClick={() => { handleClose(); setShow(true) }} disableRipple>
                                    Logout
                                </MenuItem>
                            </StyledMenu>
                            <Badge anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }} badgeContent={notificationcount} color="error" style={{ cursor: "pointer" }} onClick={() => navigate("/layout/notification")} >
                                <IoIosNotificationsOutline style={{ fontSize: "30px" }} />
                            </Badge>
                        </div>


                    </div>
                    <div className="p-3 CONTENT-BOX">
                        <AllRoutes />
                    </div>
                </div>
            </div>
            <Offcanvas show={openMenu} placement={'start'} onHide={() => setOpenMenu(false)}>
                <Offcanvas.Header closeButton style={{ background: "#000" }} className='text-end w-100 d-flex justify-content-end'>
                    <RxCross2 style={{ color: "#fff", fontSize: "30px" }} onClick={() => setOpenMenu(false)} />
                </Offcanvas.Header>
                <div className={`navigation ${toggle ? "active" : ""} `}>
                    <SideBarMenu />
                </div>
            </Offcanvas>
            <Modal size="md" show={show} onHide={() => setShow(false)} centered>
                <Modal.Body className="px-0">
                    <div className="modal-body text-center px-0">
                        <i className='bx bx-log-out' style={{ fontSize: "30px" }}></i>
                        <p className="mt-0 mb-2">Are you sure? </p>
                        <p className="mt-0">You want to logout.</p>
                        <Button variant="contained" className="me-3 " onClick={() => Logout("YES")} style={{ background: "black", padding: "6px 15px 6px 15px" }}> Yes</Button>
                        <Button variant="outlined" onClick={() => Logout("NO")} style={{ color: "black", border: "1px solid black", padding: "6px 15px 6px 15px" }}> No</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Layout
