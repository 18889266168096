import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Errors } from '../../Component/ErrorShow'
import Loader from '../../Component/Loading'
import { Button, Checkbox, FormControlLabel, FormGroup, } from '@mui/material'
import { TextInput } from '../../Component/Inputs';
import { useFormik } from 'formik';
import { rolesValidation } from '../../Validation/Validation';
import { INSERT_ROLE_PERMISSIONS, PERMISSIONS_LIST } from '../../Repository/Roles&PermissionRepo';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';

const AddRoles = () => {
    const [Loading, setLoading] = useState(false)
    const [permissionList, setPermisstionList] = useState([])
    const navigate = useNavigate()
    const getPermissionsList = async () => {
        setLoading(true)
        const payload = {
            activeStatus: true
        };
        try {
            let response = await PERMISSIONS_LIST(payload)
            setPermisstionList(response?.data)
        } catch (error) {
            toast.error(error.message)
            setPermisstionList([])
        } finally {
            setLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            roleName: ""
        },
        validationSchema: rolesValidation,
        onSubmit: async (values) => {
            let payload = {
                roleName: values?.roleName,
                permissionArray: permissionList
            }
            setLoading(true)
            try {
                let response = await INSERT_ROLE_PERMISSIONS(payload)
                toast.success(response.message)
                setLoading(false)
                navigate("/layout/roles-permission")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
    })

    const handleChangeInput = (event) => {
        const { name, value } = event.target
        formik.setFieldValue(name, value)
    }
    const handleChangePermissions = (event, index, permissionIndex) => {
        const { checked } = event.target;
        setPermisstionList(prev => {
            return prev.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        permissionData: item.permissionData.map((perm, permIdx) => {
                            if (permIdx === permissionIndex) {
                                return {
                                    ...perm,
                                    data: {
                                        ...perm.data,
                                        value: checked
                                    }
                                };
                            }
                            return perm;
                        })
                    };
                }
                return item;
            });
        });
    };
    const handleChangeSectionPermissions = (event, index) => {
        const { checked } = event.target;
        setPermisstionList(pre => {
            return pre.map((item, idx) => {
                if (index === idx) {
                    return {
                        ...item,
                        permissionData: item.permissionData.map((perm, permIdx) => {
                            return {
                                ...perm,
                                data: {
                                    ...perm.data,
                                    value: checked
                                }
                            }
                        })
                    }
                }
                return item
            })
        })
    }


    useEffect(() => {
        getPermissionsList()
    }, [])

    return (
        <>
            {Loading && <Loader />}
            <section className='d-flex align-items-center' >
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'> Add Roles</h3>
            </section>
            <div className="container-fluid shadow">
                <div className="row">
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Role Name<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={50}
                            name="roleName"
                            value={formik.values.roleName}
                            type="text"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"roleName"} />
                    </div>
                </div>
                <hr />
                <div className="row">
                    {permissionList?.map((cur, index) => {
                        return <React.Fragment key={index}>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12 ROLE_PAGE">
                                <FormControlLabel className='ms-0' labelPlacement="start" control={<Checkbox onChange={(e) => handleChangeSectionPermissions(e, index)} checked={permissionList[index]?.permissionData?.every((cur) => cur?.data?.value === true)} size='small' />} label={cur?.title} />
                                <FormGroup >
                                    {cur?.permissionData?.map((permission, permissionIndex) => {
                                        return <FormControlLabel key={permissionIndex} style={{ color: "gray" }} control={<Checkbox onChange={(e) => handleChangePermissions(e, index, permissionIndex, permission?.data?.value)} size='small' checked={permission?.data?.value} />}
                                            label={permission?.data?.title} />
                                    })}
                                </FormGroup>
                            </div>
                        </React.Fragment>
                    })}
                </div>
                <div className="row">
                    <div className="col-12 text-end">
                        <Button variant="contained" type="button" onClick={formik.handleSubmit} className="my-3 mui_button_color">Save</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddRoles

