export const commonMessages = {
  NetworkError: "Network Error",
};
export const RoleTitle = {
  SUPER_ADMIN: "SUPER_ADMIN",
  SERVICE_PROVIDER: "SERVICE_PROVIDER",
  STAFF: "STAFF"

}
export const statusCode = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  RequestTimeout: 408,
  Conflict: 409,
  ContentTooLarge: 413,
  UnsupportedMediaType: 415,
  MaximumOTPRetryAttemptsReached: 429,
  UnableToSendOTP: 430,
  IncorrectOTP: 431,
  InternalServerError: 500,
  BadGateway: 502,
  ServiceUnavailable: 503
};
export const fileTypes = {
  pdf_File_Type: ["application/pdf"],
  Img_Type: [
    "image/jpg",
    "image/jpeg",
    "image/jfif",
    "image/pjpeg",
    "image/pjp",
    "image/png",
    "image/svg",
    "image/webp",
  ],
};
export const dailogAllMessage = {
  Country: {
    Archive: " You want to archive this country",
    UnArchive: "You want to un-archive this country"
  },
  State: {
    Archive: " You want to archive this state",
    UnArchive: "You want to un-archive this state"
  },
  Service: {
    Archive: " You want to archive this service",
    UnArchive: "You want to un-archive this service"
  },
  Specialization: {
    Archive: " You want to archive this specialization",
    UnArchive: "You want to un-archive this specialization"
  },
  business_category: {
    Archive: " You want to archive this business category",
    UnArchive: "You want to un-archive this business category"
  },
  habit: {
    Archive: " You want to archive this lifestyle habit ",
    UnArchive: "You want to un-archive this lifestyle habit "
  },
  subcriptionPlan: {
    Archive: " You want to archive this subcription plan  ",
    UnArchive: "You want to un-archive this subcription plan "
  },
  Team: {
    Archive: " You want to archive this team member  ",
    UnArchive: "You want to un-archive this team member "
  },
  vaccinationCategory: {
    Archive: " You want to archive this vaccination category  ",
    UnArchive: "You want to un-archive this vaccination category "
  },
  Allergies: {
    Archive: " You want to archive this allergies  ",
    UnArchive: "You want to un-archive this allergies "
  },
  ChronicDisease: {
    Archive: " You want to archive this chronic disease  ",
    UnArchive: "You want to un-archive this chronic disease "
  },
  Symptoms: {
    Archive: " You want to archive this symptoms  ",
    UnArchive: "You want to un-archive this symptoms "
  },
  TestRequired: {
    Archive: " You want to archive this test required  ",
    UnArchive: "You want to un-archive this test required "
  },
  vaccinationForm: {
    Archive: " You want to archive this vaccination form  ",
    UnArchive: "You want to un-archive this vaccination form "
  },
  PublicVaccinationForm: {
    public: " You want to publish this vaccination form   ",
    draft: "You want to draft this vaccination form "
  },
  role: {
    Archive: " You want to archive this role  ",
    UnArchive: "You want to un-archive this role "
  },

}
export const TimeFrameOptions = [
  { value: "Days", label: "Days" },
  { value: "Weeks", label: "Weeks" },
  { value: "Months", label: "Months" },
];


export const durationOption = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
  { value: 60, label: 60 },
  { value: 70, label: 70 },
  { value: 80, label: 80 },
  { value: 90, label: 90 },
  { value: 100, label: 100 },
  { value: 110, label: 110 },
  { value: 120, label: 120 },
];

export const PlanTypeOption = [
  { value: "Monthly", label: "Monthly" },
  { value: "Yearly", label: "Yearly" },
];
export const RefundTypeOption = [
  { value: "Full", label: "Full" },
  { value: "Partial", label: "Partial" },
];
export const DescountType = [
  { value: "FLAT", label: "Flat" },
  { value: "PERCENTAGE", label: "Percentage" },
];
export const TitleOptions = [
  { value: "Dr.", label: "Dr." },
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
]
export const permissionMessage = "You don't have permission to access"