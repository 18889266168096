import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PaginationCom from '../../Component/pagination'
import { toast } from 'react-toastify';
import { permissionMessage, RoleTitle } from '../../Constants/Constants'
import { Button, Dialog, DialogContent, Slide, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material'
import Loading from '../../Component/Loading'
import { Table } from 'react-bootstrap'
import useTableSorting from '../../Hooks/useTableSorting'
import AddIcon from '@mui/icons-material/Add';
import NoRecordFound from '../../Component/NoRecordFound'
import { PROVIDER_LIST } from '../../Repository/ProviderRepo';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useFormik } from 'formik';
import { Errors } from '../../Component/ErrorShow';
import { assignPlanValidation, leadAssignValidation } from '../../Validation/Validation';
import { getlocalStorage, intialRole } from '../../utils';
import { EDIT_LEAD, LEAD_LIST } from '../../Repository/LeadRepo';
import { SelectBox } from '../../Component/Inputs';
import { ASSIGN_PLAN, PLAN_LIST } from '../../Repository/SubcripstionPlanRepo';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const LeadList = () => {
  const [leadList, setLeadList] = useState([])
  const [paginationTotalCount, setPaginationTotalCount] = useState(0)
  const [teamList, setTeamList] = useState([])
  const [subscriptionPlanList, setSubscriptionPlanList] = useState([])
  const [Filter, setFilter] = useState({
    archive: false,
    team: "",
    disable_button: true
  })
  const [openModal, setOpenModal] = useState({
    assignlead: false,
    completed: false,
    closed: false,
    reOpen: false,
    resendInvitaion: false,
    assignPlan: false,
    leadView: false,
    data: null
  })
  const [loading, setLoading] = useState(false)
  const { state } = useLocation();
  const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
  const naviget = useNavigate()
  const userData = getlocalStorage("userInfodata")
  useEffect(() => {
    getLeadList()
    naviget({ replace: true, state: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller])
  const headCells = [
    { id: "contactPerson", numeric: true, disablePadding: false, label: " Contact Person", isShorted: true },
    { id: "providerName", numeric: true, disablePadding: false, label: " Provider Name", isShorted: true },
    { id: "dealingPerson", numeric: true, disablePadding: false, label: " Dealing person", isShorted: true },
    { id: "leadType", numeric: true, disablePadding: false, label: " Lead Type", isShorted: true },
    { id: "leadStatus", numeric: true, disablePadding: false, label: " Status", isShorted: true },
    { id: "leadOrigin", numeric: true, disablePadding: false, label: " Origin", isShorted: true },
    { id: "Action", numeric: true, disablePadding: false, label: " Action", isShorted: false, showLeft: true, },
  ];
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

  async function getTeamList() {
    setLoading(true)
    const payload = {
      activeStatus: true,
      getStaffList: true,
      providerId: userData?.userId,
      allowLogin: true,
      invitationStatus: "ACCEPTED",
      filter:"ALL"
    };
    try {
      let response = await PROVIDER_LIST(payload)
      setTeamList(response?.data)
    } catch (error) {
      setTeamList([])
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getSubscriptionList() {
    setLoading(true)
    const payload = {
      activeStatus: true,
    };
    try {
      let response = await PLAN_LIST(payload)
      setSubscriptionPlanList(response?.data)
    } catch (error) {
      setSubscriptionPlanList([])
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getLeadList() {
    setLoading(true)
    const { page, rowsPerPage, searchPage } = controller;
    const { archive, team } = Filter;
    const keyword = team.trim();
    const payload = {
      keyword: (searchPage && keyword) ? keyword : undefined,
      page: page,
      count: rowsPerPage,
      deletedStatus: archive,
    };
    try {
      let response = await LEAD_LIST(payload)
      setLeadList(response?.data?.map((cur) => ({
        ...cur,
        contactPerson: `${cur?.firstName ? cur?.firstName : ""} ${cur?.lastName ? cur?.lastName : ""}`,
        dealingPerson: `${cur?.dealingPersonFirstName ? cur?.dealingPersonFirstName : ""} ${cur?.dealingPersonLastName ? cur?.dealingPersonLastName : ""}`

      })))
      setPaginationTotalCount(response?.totalCount)
    } catch (error) {
      setLeadList([])
      if (error.message !== "No lead found") toast.error(error.message)
      setPaginationTotalCount(0)
    } finally {
      setLoading(false)
    }
  }

  const assignLeadFun = useFormik({
    initialValues: {
      teamMember: ""
    },
    validationSchema: leadAssignValidation,
    onSubmit: async (values, { resetForm }) => {
      let payload = {
        leadId: openModal?.data?.leadId,
        leadStatus: "ASSIGNED",
        staffId: values?.teamMember,
        [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
        roleTitle: userData?.rolesAndPermission?.roleName,
      }
      setLoading(true)
      try {
        let response = await EDIT_LEAD(payload)
        toast.success(response.message)
        setLoading(false)
        getLeadList()
        handleClosedModal("assignlead")
        resetForm()
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
      }
    }
  })

  const assignSubscriptionPlan = useFormik({
    initialValues: {
      subscriptionPlan: ""
    },
    validationSchema: assignPlanValidation,
    onSubmit: async (values, { resetForm }) => {
      let payload = {
        providerId: userData?.userId, //loging user ID
        userId: userData?.userId,
        subscriptionPlanId: values?.subscriptionPlan,
      }
      setLoading(true)
      try {
        let response = await ASSIGN_PLAN(payload)
        toast.success(response.message)
        setLoading(false)
        getLeadList()
        handleClosedModal("assignPlan")
        resetForm()
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
      }
    }
  })

  const CompleteLead = async (type) => {
    let payload = {
      leadId: openModal?.data?.leadId,
      leadStatus: "COMPLETED",
      [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
      roleTitle: userData?.rolesAndPermission?.roleName,
      staffId:openModal?.data?.dealingPersonId
    }
    setLoading(true)
    try {
      let response = await EDIT_LEAD(payload)
      toast.success(response.message)
      setLoading(false)
      getLeadList()
      handleClosedModal(type)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }
  const ClosedLead = async (type) => {
    let payload = {
      leadId: openModal?.data?.leadId,
      leadStatus: "CLOSED",
      [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
      roleTitle: userData?.rolesAndPermission?.roleName,
      staffId:openModal?.data?.dealingPersonId
    }
    setLoading(true)
    try {
      let response = await EDIT_LEAD(payload)
      toast.success(response.message)
      setLoading(false)
      getLeadList()
      handleClosedModal(type)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }
  const ReOpenLead = async (type) => {
    let payload = {
      leadId: openModal?.data?.leadId,
      leadStatus: "RE-OPEN",
      [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
      roleTitle: userData?.rolesAndPermission?.roleName,
      staffId:openModal?.data?.dealingPersonId
    }
    setLoading(true)
    try {
      let response = await EDIT_LEAD(payload)
      toast.success(response.message)
      setLoading(false)
      getLeadList()
      handleClosedModal(type)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }
  const resendInvitationFun = useFormik({
    initialValues: {
      oldEmail: "",
      newEmail: "",
      name: "",
      staffId: "",
      mobile: ""
    },
    // validationSchema: resendEmailValidation,
    onSubmit: (value) => {

    }
  })

  const handleSubmit = (event, type) => {
    if (event === "YES") {
      if (type === 'assignlead') assignLeadFun.handleSubmit()
      if (type === "assignPlan") assignSubscriptionPlan.handleSubmit()
      if (type === "completed") CompleteLead(type)
      if (type === "closed") ClosedLead(type)
      if (type === "reOpen") ReOpenLead(type)
      if (type === "resendInvitaion") resendInvitationFun.handleSubmit()
    } else {
      handleClosedModal(type)
    }
  }

  const handleClosedModal = (type) => {
    if (type === "assignlead") {
      assignLeadFun.setFieldValue("teamMember", "")
    }
    setOpenModal((pre) => ({
      ...pre,
      data: null,
      [type]: false
    }))
  }

  const handleOpenModal = (type, data) => {
    if (type === "assignlead") {
      if (data?.dealingPersonId) {
        assignLeadFun.setFieldValue("teamMember", data?.dealingPersonId)
      }
      getTeamList()
    }
    if (type === "assignPlan") {
      getSubscriptionList()
    }
    if (type === "resendInvitaion") {
      resendInvitationFun.setFieldValue("oldEmail", data?.email);
      resendInvitationFun.setFieldValue("staffId", data?.userId)
      resendInvitationFun.setFieldValue("name", data?.firstName)
      resendInvitationFun.setFieldValue("mobile", data?.mobile)
    }
    setOpenModal((prev) => ({
      ...prev,
      data,
      [type]: true,
    }));
  };

  const disabledOption = (type, status) => {
    let disable = "";
    switch (type) {
      case "Assign team member":
        if (status === "CLOSED" || status === "COMPLETED") {
          disable = "disabled";
        }
        break;
      case "Convert to provider":
        if (status === "OPEN" || status === "CLOSED" || status === "CONVERTED" || status === "COMPLETED") {
          disable = "disabled";
        }
        break;
      case "Mark as completed":
        if (status === "CONVERTED") {
          disable = "";
        } else {
          disable = "disabled";
        }
        break;
      case "Assign Plan":
        if (status === "COMPLETED") {
          disable = "";
        } else {
          disable = "disabled";
        }
        break;
      case "Mark as Closed":
        if (status === "CLOSED" || status === "COMPLETED") {
          disable = "disabled";
        }
        break;
      case "Re-Open":
        if (status === "CLOSED") {
          disable = "";
        } else {
          disable = "disabled";
        }
        break;
      default:
        break;
    }

    return disable;

  }

  const handleChangeFilter = (event) => {
    const { name, value } = event.target
    setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
  }
  const reset = () => {
    setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
    setFilter({ archive: false, team: "", disable_button: true })
  }
  const HandleSearchList = () => {
    setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
  }
  console.log(openModal,"openModal")

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <h3 className='PAGE-NAME'>Leads</h3>
          </div>
        </div>
        <section className='d-flex justify-content-between row'>
          <div className='col-xxl-6 col-xl-9 col-sm-8 px-0 '>
            {
              intialRole({ permission: "Lead Management", userData: userData, section: 'AbletoView/ListLeads' }) ?
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-md-6   ">
                    <TextField id="outlined-basic" name='team' value={Filter?.team} onChange={handleChangeFilter} placeholder='Search team.. ' variant="outlined" className='w-100' size='small' />
                  </div>
                  <div className=" mt-3 mt-sm-0 col-lg-6 col-md-5 col-sm-5 d-flex justify-content-sm-start  justify-content-end">
                    <Button variant="contained" className='me-2 mui_button_color' disabled={Filter?.disable_button} onClick={HandleSearchList}>search</Button>
                    <Button variant="contained" className='mui_button_color' disabled={Filter?.disable_button} onClick={reset}>reset</Button>
                  </div>
                </div>
                : null
            }
          </div>
          {
            intialRole({ permission: "Lead Management", userData: userData, section: 'AbletoaddLeads' }) ?
              <div className='col-xxl-2 col-xl-3 col-sm-4 d-flex justify-content-end pe-0 mt-3 mt-sm-0 '>
                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("add-lead")}>
                  Add Lead
                </Button>
              </div>
              : null
          }
        </section>
        {
          intialRole({ permission: "Lead Management", userData: userData, section: 'AbletoView/ListLeads' }) ?
            <>
              <div className="row">
                {
                  leadList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                    <NoRecordFound />
                  </div>
                }
              </div>
              <TableContainer className=" mt-4">
                <Table
                  id="table_mui"
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  {
                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={leadList} />
                  }
                  <TableBody>

                    {stableSort(leadList, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return <React.Fragment key={row?.leadId || index}>
                          <TableRow id="hover_tr">
                            <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" style={{ textTransform: "capitalize" }} >
                              {row?.contactPerson}
                            </TableCell>
                            <TableCell id={labelId} align="left" >{row.providerName}</TableCell>
                            <TableCell id={labelId} align="left" >{row.dealingPerson}</TableCell>
                            <TableCell id={labelId} align="left" >{row?.leadType}</TableCell>
                            <TableCell id={labelId} align="left" >{row?.leadStatus}</TableCell>
                            <TableCell id={labelId} align="left" >{row?.leadOrigin}</TableCell>
                            <TableCell id={labelId} align="right"  >
                              <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                              <ul className="dropdown-menu">
                                {
                                  (intialRole({ permission: "Lead Management", userData: userData, section: 'AbletoEditLeads' }) && row?.leadStatus !== "COMPLETED") ?
                                    <li><button className={`dropdown-item`} onClick={() => { naviget(`${row?.leadId}`) }} >Edit Lead</button></li> : null
                                }
                                {
                                  row?.leadStatus === "COMPLETED" && <li><button className={`dropdown-item`} onClick={() => handleOpenModal("leadView", row)} >View Lead</button></li>
                                }

                                {
                                  disabledOption("Assign team member", row?.leadStatus) !== "disabled" && <li><button className={`dropdown-item `} onClick={() => handleOpenModal("assignlead", row)} >Assign team member </button></li>
                                }
                                {
                                  disabledOption("Convert to provider", row?.leadStatus) !== "disabled" && <li><button onClick={() => naviget(`convert-provider/${row?.leadId}`)} className={`dropdown-item  `}>Convert to provider </button></li>
                                }
                                {
                                  disabledOption("Mark as completed", row?.leadStatus) !== "disabled" && <li><button className={`dropdown-item  `} onClick={() => handleOpenModal("completed", row)}>Mark as completed </button></li>
                                }
                                {
                                  disabledOption("Mark as Closed", row?.leadStatus) !== "disabled" && <li><button className={`dropdown-item ${disabledOption("Mark as Closed", row?.leadStatus)} `} onClick={() => handleOpenModal("closed", row)}>Mark as Closed </button></li>
                                }
                                {
                                  disabledOption("Re-Open", row?.leadStatus) !== "disabled" && <li> <button className={`dropdown-item`} onClick={() => handleOpenModal("reOpen", row)}>Re-Open </button></li>
                                }
                              </ul>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <section className='w-100 d-flex justify-content-end'>
                {
                  paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                }
              </section>
            </>
            : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
        }
      </div>

      <Dialog
        open={openModal.assignlead}
        keepMounted
        TransitionComponent={Transition}
        onClose={() => handleClosedModal("assignlead")}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent className='px-5'>
          <div className="row">
            <div className="col-12 text-center">
              <p>Assign Lead</p>
            </div>
            <div className="col-sm-12 mb-2 mt-0">
              <label className="form-label mb-0">Assigned team member<span className='Star_Mark'>*</span></label>
              <SelectBox
                placeholder="Select Title"
                value={assignLeadFun?.values?.teamMember}
                name="teamMember"
                keys="userId"
                label="firstName"
                extraLable="lastName"
                menuValeu="userId"
                option={teamList}
                handleChange={(e) => assignLeadFun.setFieldValue("teamMember", e.target.value)}
              />
              <Errors formikfun={assignLeadFun} name={"teamMember"} />
            </div>
            <div className="col-12 text-end">
              <Button onClick={() => handleSubmit("NO", "assignlead")} > Cancel</Button>
              <Button onClick={() => handleSubmit("YES", "assignlead")} autoFocus>Submit</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal.completed}
        keepMounted
        TransitionComponent={Transition}
        onClose={() => handleClosedModal("completed")}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent className='px-5'>
          <div className="row">
            <div className="col-12 text-center">
              <p>Are you sure?</p>
              <p>You want to complete this lead</p>
            </div>
            <div className="col-12 text-end">
              <Button variant="outlined" onClick={() => handleSubmit("NO", "completed")} className=' mui_button_color me-3' size='small' > No</Button>
              <Button variant="contained" onClick={() => handleSubmit("YES", "completed")} autoFocus className=' mui_button_color' size='small'>Yes</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal.closed}
        keepMounted
        TransitionComponent={Transition}
        onClose={() => handleClosedModal("closed")}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent className='px-5'>
          <div className="row">
            <div className="col-12 text-center">
              <p>Are you sure?</p>
              <p>You want to close this lead</p>
            </div>
            <div className="col-12 text-end">
              <Button variant="outlined" onClick={() => handleSubmit("NO", "closed")} className=' mui_button_color me-3' size='small' > No</Button>
              <Button variant="contained" onClick={() => handleSubmit("YES", "closed")} autoFocus className=' mui_button_color' size='small'>Yes</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal.reOpen}
        keepMounted
        TransitionComponent={Transition}
        onClose={() => handleClosedModal("reOpen")}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent className='px-5'>
          <div className="row">
            <div className="col-12 text-center">
              <p>Are you sure?</p>
              <p>You want to re-open this lead</p>
            </div>
            <div className="col-12 text-end">
              <Button variant="outlined" onClick={() => handleSubmit("NO", "reOpen")} className=' mui_button_color me-3' size='small' > No</Button>
              <Button variant="contained" onClick={() => handleSubmit("YES", "reOpen")} autoFocus className=' mui_button_color' size='small'>Yes</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal.resendInvitaion}
        keepMounted
        TransitionComponent={Transition}
        onClose={() => handleClosedModal("resendInvitaion")}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent className='px-5'>
          <div className="row">
            <div className="col-12 text-center">
              <p>Resend Invitation</p>
            </div>
            <div className="col-sm-12 mb-3">
              <label className="form-label mb-0">Old Email<span className='Star_Mark'>*</span></label>
              <input disabled type="email" className="form-control" name='oldEmail' value={resendInvitationFun.values.oldEmail}
                onChange={(e) => resendInvitationFun.setFieldValue(`oldEmail`, e.target.value)} />
            </div>
            <Errors formikfun={resendInvitationFun} name={"oldEmail"} />
            <div className="col-sm-12 mb-3">
              <label className="form-label mb-0">New Email<span className='Star_Mark'>*</span></label>
              <input maxLength={50} type="email" className="form-control" name='newEmail' value={resendInvitationFun.values.newEmail}
                onChange={(e) => resendInvitationFun.setFieldValue(`newEmail`, e.target.value)} />
              <Errors formikfun={resendInvitationFun} name={"newEmail"} />
            </div>
            <div className="col-12 text-end">
              <Button onClick={() => handleSubmit("NO", "resendInvitaion")} > Cancel</Button>
              <Button onClick={() => handleSubmit("YES", "resendInvitaion")} autoFocus>Submit</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModal.assignPlan}
        keepMounted
        TransitionComponent={Transition}
        onClose={() => handleClosedModal("assignPlan")}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent className='px-5'>
          <div className="row">
            <div className="col-12 text-center">
              <p>Assign Subscription Plan</p>
            </div>
            <div className="col-sm-12 mb-2 mt-0">
              <label className="form-label mb-0">Subscription Plan<span className='Star_Mark'>*</span></label>
              <SelectBox
                placeholder="Select Title"
                value={assignSubscriptionPlan?.values?.subscriptionPlan}
                name="subscriptionPlan"
                keys="subscriptionPlanId"
                label="title"
                menuValeu="subscriptionPlanId"
                option={subscriptionPlanList}
                handleChange={(e) => assignSubscriptionPlan.setFieldValue("subscriptionPlan", e.target.value)}
              />
              <Errors formikfun={assignSubscriptionPlan} name={"subscriptionPlan"} />
            </div>
            <div className="col-12 text-end">
              <Button onClick={() => handleSubmit("NO", "assignPlan")} > Cancel</Button>
              <Button onClick={() => handleSubmit("YES", "assignPlan")} autoFocus>Submit</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>


      <Dialog
        open={openModal.leadView}
        keepMounted
        TransitionComponent={Transition}
        onClose={() => handleClosedModal("leadView")}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent className='px-5'>

          <div className="row">
            <div className="col-12 text-center">
              {/* <p>Assign Subscription Plan</p> */}
            </div>
            <div className="col-sm-6 mb-2 mt-0">
              <label className="form-label mb-0">Contact Person</label>
              <div className="col-sm-12 mb-2  ">
                <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box' >
                  <p>
                    {openModal?.data?.contactPerson ? openModal?.data?.contactPerson : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-2 mt-0">
              <label className="form-label mb-0">Provider Name</label>
              <div className="col-sm-12 mb-2  ">
                <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                  <p>
                    {openModal?.data?.providerName ? openModal?.data?.providerName : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-2 mt-0">
              <label className="form-label mb-0">Dealing person</label>
              <div className="col-sm-12 mb-2  ">
                <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                  <p>
                    {openModal?.data?.dealingPerson ? openModal?.data?.dealingPerson : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-2 mt-0">
              <label className="form-label mb-0"> Lead Type</label>
              <div className="col-sm-12 mb-2  ">
                <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                  <p>
                    {openModal?.data?.leadType ? openModal?.data?.leadType : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-2 mt-0">
              <label className="form-label mb-0">Status</label>
              <div className="col-sm-12 mb-2  ">
                <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                  <p>{openModal?.data?.leadStatus ? openModal?.data?.leadStatus : "-"}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-2 mt-0">
              <label className="form-label mb-0">Origin</label>
              <div className="col-sm-12 mb-2  ">
                <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                  <p> {openModal?.data?.leadOrigin ? openModal?.data?.leadOrigin : "-"}</p>
                </div>
              </div>
            </div>
            <div className="col-12 text-end">
              <Button onClick={() => handleClosedModal("leadView")} > Cancel</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default LeadList
