
import React from 'react'
import Dialogs from '../../Component/Dialog'
import PaginationCom from '../../Component/pagination'
import { dailogAllMessage, permissionMessage } from '../../Constants/Constants'
import Loader from '../../Component/Loading'
import useTableSorting from '../../Hooks/useTableSorting'
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import useList from './Hooks/useList'
import { BiArchiveIn } from 'react-icons/bi';
import NoRecordFound from '../../Component/NoRecordFound';
import { getlocalStorage, intialRole } from '../../utils';
const List = () => {
    const {
        loading,
        paginationTotalCount,
        headCells,
        ListState,
        controller,
        DeletedData,
        open,
        setDeletedData,
        reset,
        naviget,
        setOpen,
        handleClose,
        setController,
        Filter,
        handleChangeFilter,
        HandleSearchList
    } = useList()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const userData = getlocalStorage("userInfodata")
    if (!intialRole({ permission: "State", userData: userData, section: 'AbletoEditState' }) && !intialRole({ permission: "State", userData: userData, section: "AbletoActive/In-ActiveState" })) {
        headCells.pop()
    }
    return (
        <>
            {loading && <Loader />}
            <div className="container-fluid" >
                <div className="row">
                    <div className="col-12 px-0 ">                      
                        <h3 className='PAGE-NAME'>State</h3>
                    </div>
                </div>
                <section className='d-flex justify-content-between row'>
                    <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12 '>
                        {
                            intialRole({ permission: "State", userData: userData, section: 'AbletoView/ListState' }) ?
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 ">
                                        <Select
                                            name='archive'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='w-100'
                                            size='small'
                                            value={Filter?.archive}
                                            onChange={handleChangeFilter}>
                                            <MenuItem value={true}>Unarchive</MenuItem>
                                            <MenuItem value={false}>Archive</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="  mt-3 mt-sm-0 col-lg-4 col-sm-6 ">
                                        <TextField id="outlined-basic" value={Filter?.state} name='state' onChange={handleChangeFilter} placeholder='Search .. ' variant="outlined" className='w-100' size='small' />
                                    </div>
                                    <div className=" mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end ">
                                        <Button variant="contained" className='me-2 mui_button_color' disabled={Filter.disable_button} onClick={HandleSearchList}>search</Button>
                                        <Button variant="contained" className='mui_button_color' disabled={Filter.disable_button} onClick={reset}>reset</Button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    {
                        intialRole({ permission: "State", userData: userData, section: 'AbletoaddState' }) ?
                            <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("add")}>
                                    Add State
                                </Button>
                            </div>
                            : null
                    }
                </section>
                {
                    intialRole({ permission: "State", userData: userData, section: 'AbletoView/ListState' }) ?
                        <>
                            <div className="row">
                                {
                                    ListState?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound />   </div>
                                }
                            </div>
                            <TableContainer className=" mt-4"  >
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {<EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={ListState.length} />
                                    }
                                    <TableBody>

                                        {stableSort(ListState, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={row?.stateId}>
                                                    <TableRow id="hover_tr">
                                                        <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none"  >
                                                            {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                        </TableCell>
                                                        <TableCell id={labelId} align="left" >{row.countryName}</TableCell>
                                                        <TableCell id={labelId} align="left" >{row?.stateName}</TableCell>
                                                        <TableCell id={labelId} align="left" >{row?.abbr}</TableCell>
                                                        <TableCell id={labelId} align="right" >
                                                            {
                                                                intialRole({ permission: "State", userData: userData, section: 'AbletoEditState' }) ?
                                                                    row?.activeStatus === true && <svg onClick={() => naviget(`edit/${row.stateId}`, { state: { controller } })} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                    </svg>
                                                                    : null
                                                            }
                                                            {
                                                                intialRole({ permission: "State", userData: userData, section: "AbletoActive/In-ActiveState" }) ?
                                                                    <BiArchiveIn title={`${row?.archive === false ? "Archive" : "Unarchive"}`} className="all_react_icons_color ms-2 " onClick={() => { setDeletedData(row); setOpen(!open) }} />
                                                                    : null
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <section className='w-100 d-flex justify-content-end mt-3'>
                                {
                                    paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </section>
                        </>
                        : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                }
            </div>
            <Dialogs handleClose={handleClose} title={DeletedData?.activeStatus ? dailogAllMessage?.State?.Archive : dailogAllMessage?.State?.UnArchive} open={open} data={DeletedData} />
        </>
    )
}

export default List
