import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../Component/Loading'
import { motion } from 'framer-motion';
import { CreatePinValidation, pattern } from '../../Validation/Validation';
import { CREATE_PIN, VALIDATE_AUTH_TOKEN } from '../../Repository/AuthRepo';
import { Errors } from '../../Component/ErrorShow';
import { RoleTitle } from '../../Constants/Constants';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import "../style/checkedstyle.css"
import PhoneNumber from '../../Component/PhoneInput';
import { UPDATE_STAFF_MOBILE_NUMBER } from "../../Repository/TeamManagementRepo";
const CreatePIN = () => {
    const [loading, setLoading] = useState(false)
    const [userdata, setUserdata] = useState({})
    const [showPage, setShowPage] = useState(1)
    const { token } = useParams()
    const navigate = useNavigate()
    const [showpassword, setShowPassword] = useState({
        oldpin: false,
        newpin: false,
        confirmpin: false,
        pin: false
    })

    const backToLogin = () => {
        localStorage.clear()
        sessionStorage.clear()
        navigate("/")
    }

    const CheckToken = async () => {
        let payload = { token: token }
        setLoading(true)
        try {
            let response = await VALIDATE_AUTH_TOKEN(payload)
            if (response?.data?.conditionStatus === 1002) { //accepted invitation
                setShowPage(3)
            }
            if (response?.data?.conditionStatus === 1003) { //mobile number
                setShowPage(1)
            }
            if (response?.data?.conditionStatus === 1004) {//mobile number and create pin 
                setShowPage(1)
            }
            if (response?.data?.conditionStatus === 1005) { //creatre pin
                setShowPage(2)
            }
            setUserdata(response.data)
            setLoading(false)
        } catch (error) {
            toast.error(error?.message)
            setLoading(false)
            navigate("declineInvitation")
        }
    }


    const resetPIN = useFormik({
        initialValues: {
            newPin: "",
            confirmPin: ""
        },
        validationSchema: CreatePinValidation,
        onSubmit: async (values) => {
            setLoading(true)
            let payload = {
                pin: values.newPin,
                confirmPin: values.confirmPin,
                userId: userdata?.userId,
                authId: userdata?.authId,
                roleTitle: RoleTitle.SERVICE_PROVIDER,
                filter:"INVITATION"
            }
            try {
                let response = await CREATE_PIN(payload)
                toast.success(response.message)
                setTimeout(() => {
                    backToLogin()
                }, 1000)
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoading(false)
            }
        }
    })
    const ragistorNumber = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: Yup.object({ phone: Yup.string().trim().required("Please enter mobile number ") }),
        validateOnChange: true,
        validate: (values) => {
            const errors = {};
            if (values?.phone?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
                delete errors.phone
            } else {
                errors.phone = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            let payload = {
                mobile: values?.phone,
                userId: userdata?.userId,
                authId: userdata?.authId,
            }
            try {
                let response = await UPDATE_STAFF_MOBILE_NUMBER(payload)
                toast.success(response?.message);
                setLoading(false)
                resetForm()
                if (userdata?.conditionStatus === 1003) {
                    backToLogin()
                } else {
                    setShowPage(2)
                }
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
    })
    const handleChangeInput = (event) => {
        const { name, value } = event.target
        ragistorNumber.setFieldValue(name, value)
    }

    const showhide = (active) => {
        setShowPassword((pre) => ({ ...pre, [active]: !pre[active] }))
    }
    useEffect(() => {
        CheckToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <div className="container login_section" style={{ overflowY: "hidden" }} >
                {
                    showPage === 1 && <motion.div className="login shadow py-5 px-4  " style={{ width: "500px" }}
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        exit={{ x: -300 }}
                        transition={{ duration: 0.5 }}>
                        <div className="logo w-100 d-flex justify-content-center">
                            <h1>Mobile Number</h1>
                        </div>
                        <div className="logo w-100 d-flex justify-content-center">
                            <form className='w-100' onSubmit={ragistorNumber.handleSubmit}>
                                <div className="form-group mb-2 ">
                                    <div className="col-sm-12 mb-2 ms-0 mt-5 mobile_number_input_box">
                                        <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                                        <div className="input_group_mobile mb-0">
                                            <PhoneNumber
                                                width={false}
                                                Require={false}
                                                Name="phone"
                                                HandleChange={handleChangeInput}
                                                Value={ragistorNumber.values.phone}
                                                disabledInput={false}
                                            />
                                        </div>
                                        <Errors formikfun={ragistorNumber} name={"phone"} />
                                    </div>
                                </div>
                                <div className="form-group mb-0 w-100 text-center mt-5    ">
                                    <button type="submit" className=" btn-login shadow mx-auto mt-4 px-5"  > {userdata?.conditionStatus === 1003 ? "Save" : "Next"}</button>
                                </div>
                            </form>
                        </div>
                    </motion.div>
                }
                {
                    showPage === 2 && <motion.div className="login shadow py-5 px-4  " style={{ width: "500px" }}
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        exit={{ x: -300 }}
                        transition={{ duration: 0.5 }}>
                        <div className="logo w-100 d-flex justify-content-center">
                            <h1>Create Pin</h1>
                        </div>
                        <div className="logo w-100 d-flex justify-content-center">
                            <form className='w-100' onSubmit={resetPIN.handleSubmit}>
                                <div className="form-group mb-2 ">
                                    <label htmlFor="exampleInputEmail1" className='my-0'>New Pin</label>
                                    <div className="input_fild_main w-100 row px-0 mx-0">
                                        <div className="col-10 px-0">
                                            <input type={`${!showpassword.newpin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                                placeholder="Enter Pin" name='newPin' onChange={(e) => resetPIN.setFieldValue("newPin", e.target.value)} value={resetPIN.values.newPin}
                                                onInput={(e) => {
                                                    if (e.target.value.length > 4) {
                                                        e.target.value = e.target.value.slice(0, 4);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                            <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("newpin")}>
                                                {showpassword.newpin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}
                                            </button>
                                        </div>
                                    </div>
                                    <Errors formikfun={resetPIN} name={"newPin"} />
                                </div>
                                <div className="form-group mb-2 ">
                                    <label htmlFor="exampleInputEmail1" className='my-0'>Confirm New Pin</label>
                                    <div className="input_fild_main w-100 row px-0 mx-0">
                                        <div className="col-10 px-0">
                                            <input type={`${!showpassword.confirmpin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                                placeholder="Enter Pin" name='confirmPin' onChange={(e) => resetPIN.setFieldValue("confirmPin", e.target.value)} value={resetPIN.values.confirmPin}
                                                onInput={(e) => {
                                                    if (e.target.value.length > 4) {
                                                        e.target.value = e.target.value.slice(0, 4);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                            <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("confirmpin")}>
                                                {showpassword.confirmpin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}
                                            </button>

                                        </div>
                                    </div>
                                    <Errors formikfun={resetPIN} name={"confirmPin"} />
                                </div>
                                <div className="form-group mb-0 w-100 text-center    ">
                                    <button type="submit" className=" btn-login shadow mx-auto mt-4 px-5"  >save</button>
                                </div>
                            </form>
                        </div>
                    </motion.div>
                }
                {showPage === 3 && <motion.div className="login shadow py-5 px-4  " style={{ width: "500px" }}
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    exit={{ x: -300 }}
                    transition={{ duration: 0.5 }}>
                    <div className="logo w-100 d-flex justify-content-center">
                        <h3>Accepted</h3>
                    </div>
                    <svg className="animated-svg my-5" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                        <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                    </svg>
                    <div className="logo w-100 d-flex flex-column justify-content-center align-items-center ">
                        <p style={{ width: "80%" }} className="text-center mb-0">Your have  successfully accepted the invitation!</p>
                        <p>You may close the window </p>
                    </div>
                    <div>
                        <div className="form-group mb-0 w-100 text-center    ">
                            <button type="submit" className=" btn-login shadow mx-auto mt-4 px-5" onClick={backToLogin}  >Done</button>
                        </div>
                    </div>
                </motion.div>
                }
            </div>
        </>
    )
}

export default CreatePIN