import { axiosInstance } from "../Context/CreateAxios"
import { listspecialization,  insertspecialization, updatespecialization } from "../services/Api"
import handleError from "../utils/handleError"

export const SPECIALIZATION_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listspecialization(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_SPECIALIZATION = async (body) => {
    try {
        let response = await axiosInstance.post(insertspecialization(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_SPECIALIZATION = async (body) => {
    try {
        let response = await axiosInstance.post(updatespecialization(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const UPDATE_SPECIALIZATION = async (body) => {
    try {
        let response = await axiosInstance.post(updatespecialization(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}