import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { CreatePinValidation, ForgotValidation, Loginvalidation, VerifyOtpValidation } from '../../Validation/Validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RoleTitle, } from '../../Constants/Constants';
import { Errors } from '../../Component/ErrorShow';
import { motion } from 'framer-motion';
import Loader from '../../Component/Loading'
import { CREATE_PIN, FORGOT_PIN, LOGIN, RESEND_OTP, VERIFY_OTP } from '../../Repository/AuthRepo';
import { Button } from '@mui/material';
const Login = () => {
    const [loading, setLoading] = useState(false)
    const [showpassword, setShowPassword] = useState({
        oldpin: false,
        newpin: false,
        confirmpin: false,
        pin: false
    })
    const [showPage, setShowPage] = useState(1)
    const [ShowHideOTP, setShowHideOTP] = useState(false)
    const data = JSON.parse(localStorage.getItem('userInfodata'))
    const navigate = useNavigate();
    const [userForgotInfo, setUserForgotInfo] = useState({})
    const [timer, setTimer] = useState(60);
    useEffect(() => {
        if (data?.authToken) { navigate('/layout/dashboard') }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formik = useFormik({
        initialValues: {
            userName: "",
            password: ""
        },
        validationSchema: Loginvalidation,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            let payload = {
                userName: values?.userName.trim(),
                pin: values?.password.trim(),
            }
            localStorage.clear()
            sessionStorage.clear()
            try {
                let response = await LOGIN(payload)
                toast.success(response.message)
                localStorage.setItem('userInfodata', JSON.stringify(response.data))
                navigate("/layout/dashboard")
                resetForm()
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoading(false)
            }
        },
    })

    const ForgotPin = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: ForgotValidation,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            let payload = {
                email: values?.email.trim(),
                roleTitle: RoleTitle?.SUPER_ADMIN,
            }
            try {
                let response = await FORGOT_PIN(payload)
                toast.success(response.message)
                setUserForgotInfo(response.data)
                setShowPage(3)
                resetForm()
            } catch (error) {
                toast.error(error.message)
                setUserForgotInfo({})
            }
            finally {
                setLoading(false)
            }

        }
    })

    const VerifyOTP = useFormik({
        initialValues: {
            otp: ""
        },
        validationSchema: VerifyOtpValidation,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            let payload = {
                roleTitle: RoleTitle?.SUPER_ADMIN,
                userId: userForgotInfo?.userId,
                otp: values?.otp.trim(),
            }
            try {
                let response = await VERIFY_OTP(payload)
                toast.success(response.message)
                setShowPage(4)
                resetForm()
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoading(false)
            }

        }
    })

    const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);
    useEffect(() => {
        if (showPage === 3) {
            timer > 0 && setTimeout(timeOutCallback, 1000)
        }
    }, [timer, timeOutCallback, showPage])
    const resetTimer = () => {
        setTimer(60)
    }
    const resenOTP = async () => {
        setLoading(true)
        let payload = {
            email: userForgotInfo?.email,
            roleTitle: RoleTitle?.SUPER_ADMIN,
            userId: userForgotInfo?.userId
        }
        try {
            let response = await RESEND_OTP(payload)
            toast.success(response.message)
            resetTimer()
        } catch (error) {
            toast.error(error.message)
        }
        finally {
            setLoading(false)
        }

    };

    const resetPIN = useFormik({
        initialValues: {
            newPin: "",
            confirmPin: ""
        },
        validationSchema: CreatePinValidation,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true)
            let payload = {
                confirmPin: values?.confirmPin,
                pin: values?.newPin,
                roleTitle: RoleTitle?.SUPER_ADMIN,
                userId: userForgotInfo?.userId,
            }
            try {
                let response = await CREATE_PIN(payload)
                toast.success(response.message)
                setShowPage(1)
                setUserForgotInfo({})
                resetForm()
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoading(false)
            }

        }
    })


    const showhide = (active) => {
        setShowPassword((pre) => ({ ...pre, [active]: !pre[active] }))
    }
    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <div className="container login_section" style={{ overflowY: "hidden" }} >
                {
                    showPage === 1 && <motion.div className="login shadow py-5 px-4  " style={{ width: "500px" }}
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        exit={{ x: -300 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="logo w-100 d-flex justify-content-center">
                            <h1>Log In</h1>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group mb-4 ">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <div className="input_fild w-100 row px-0 mx-0">
                                    <div className="col-10 px-0">
                                        <input type="email" className="form-control" id="exampleInputPassword1" placeholder="Enter Email" name='userName'
                                            value={formik.values.userName} onChange={(e) => formik.setFieldValue("userName", e.target.value)}
                                            onInput={(e) => {
                                                if (e.target.value.length > 30) {
                                                    e.target.value = e.target.value.slice(0, 30);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                        <i className="fa-solid fa-envelope"></i>
                                    </div>
                                </div>
                                <Errors formikfun={formik} name={"userName"} />
                            </div>
                            <div className="form-group mb-4 ">
                                <label htmlFor="exampleInputEmail1">Enter PIN</label>
                                <div className="input_fild w-100 row px-0 mx-0">
                                    <div className="col-10 px-0">
                                        <input type={`${!showpassword?.pin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1" placeholder="Enter PIN" name='password'
                                            value={formik.values.password} onChange={(e) => formik.setFieldValue("password", e.target.value)}
                                            onInput={(e) => {
                                                if (e.target.value.length > 4) {
                                                    e.target.value = e.target.value.slice(0, 4);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                        <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("pin")}>
                                            {showpassword?.pin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}

                                        </button>
                                    </div>
                                </div>
                                <Errors formikfun={formik} name={"password"} />
                                <p className='text-end ' style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => setShowPage(2)} >Forgot PIN</p>
                            </div>
                            <div className="form-group mb-0 w-100 text-center my-5 ">
                                <button type="submit" className=" btn-login shadow mx-auto px-5"  >Login</button>
                            </div>
                        </form>
                    </motion.div>
                }
                {
                    showPage === 2 && <motion.div className="login shadow py-5 px-4  " style={{ width: "500px" }}
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        exit={{ x: -300 }}
                        transition={{ duration: 0.5 }} >
                        <div className="logo w-100 d-flex justify-content-center">
                            <h1>Forgot PIN</h1>
                        </div>
                        <form onSubmit={ForgotPin.handleSubmit}>
                            <p className=' my-4'>Enter Your Registered Email Address</p>
                            <div className="form-group mb-4 ">
                                <label htmlFor="exampleInputEmail1 " className='my-2'>Enter Email</label>
                                <div className="input_fild w-100 row px-0 mx-0">
                                    <div className="col-10 px-0">
                                        <input type="email" className="form-control" id="exampleInputPassword1" name='email'
                                            value={ForgotPin.values.email} onChange={(e) => ForgotPin.setFieldValue("email", e.target.value)}
                                            onInput={(e) => {
                                                if (e.target.value.length > 30) {
                                                    e.target.value = e.target.value.slice(0, 30);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                        <i className="fa-solid fa-envelope"></i>
                                    </div>
                                </div>
                                <Errors formikfun={ForgotPin} name={"email"} />

                            </div>
                            <div className="form-group mb-0 w-100 text-center mt-5 ">
                                <button type="submit" className=" btn-login shadow mx-auto mt-4 px-5">Continue</button>
                                <p className='text-center mt-3 ' style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => { setShowPage(1); setUserForgotInfo({}) }} >Back to <span className='text-dark'>Login</span></p>
                            </div>
                        </form>
                    </motion.div>
                }
                {
                    showPage === 3 && <motion.div className="login shadow py-5 px-4  " style={{ width: "500px" }}
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        exit={{ x: -300 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="logo w-100 d-flex justify-content-center">
                            <h1>Verify OTP</h1>
                        </div>
                        <div className="logo w-100 d-flex justify-content-center">
                            <form onSubmit={VerifyOTP.handleSubmit} className='w-100'>
                                <div className="form-group mb-4 ">
                                    <label htmlFor="exampleInputEmail1" className='my-2'>Enter OTP</label>
                                    <div className="input_fild_main w-100 row px-0 mx-0">
                                        <div className="col-10 px-0">
                                            <input maxLength={4} type={`${ShowHideOTP ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                                placeholder="Enter OTP" name='otp' onChange={(e) => VerifyOTP.setFieldValue("otp", e.target.value)} value={VerifyOTP.values.otp}
                                                onInput={(e) => { if (e.target.value.length > 4) e.target.value = e.target.value.slice(0, 4) }}
                                            />
                                        </div>
                                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                            <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => setShowHideOTP((pre) => !pre)}>
                                                {ShowHideOTP ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}
                                            </button>
                                        </div>
                                    </div>
                                    <Errors formikfun={VerifyOTP} name={"otp"} />
                                    <div className='w-100 text-end'>
                                        {timer > 0 && <p className="text-end " style={{ fontSize: "15px" }}  >{`OTP will expire in ${timer} Second`}</p>}
                                        {timer > 0 ? null : <Button variant="outlined" size='small' className='mx-auto' sx={{ padding: "2px 12px", fontSize: "10px", textTransform: "capitalize" }} onClick={resenOTP} >Resend OTP</Button>}
                                    </div>
                                </div>
                                <div className="form-group mb-0 w-100 text-center ">
                                    <button type="submit" className=" btn-login shadow mx-auto mt-4 px-5"  >Continue</button>
                                    <p className='text-center mt-3 ' style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => { setShowPage(1); setUserForgotInfo({}) }} >Back to <span className='text-dark'>Login</span></p>
                                </div>
                            </form>
                        </div>
                    </motion.div>
                }
                {
                    showPage === 4 && <motion.div className="login shadow py-5 px-4  " style={{ width: "500px" }}
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        exit={{ x: -300 }}
                        transition={{ duration: 0.5 }}>
                        <div className="logo w-100 d-flex justify-content-center">
                            <h1>Reset Pin</h1>
                        </div>
                        <div className="logo w-100 d-flex justify-content-center">
                            <form className='w-100' onSubmit={resetPIN.handleSubmit}>
                                {/* <div className="form-group mb-2 ">
                                    <label htmlFor="exampleInputEmail1 " className='my-0'>Old Pin</label>
                                    <div className="input_fild_main w-100 row px-0 mx-0">
                                        <div className="col-10 px-0">
                                            <input type={`${!showpassword.oldpin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                                placeholder="Enter Pin " name='oldPin' onChange={(e) => resetPIN.setFieldValue("oldPin", e.target.value)} value={resetPIN.values.oldPin}
                                                onInput={(e) => {
                                                    if (e.target.value.length > 4) {
                                                        e.target.value = e.target.value.slice(0, 4);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                            <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("oldpin")}>
                                                {showpassword.oldpin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}

                                            </button>

                                        </div>
                                    </div>
                                    <Errors formikfun={resetPIN} name={"oldPin"} />
                                </div> */}
                                <div className="form-group mb-2 ">
                                    <label htmlFor="exampleInputEmail1" className='my-0'>New Pin</label>
                                    <div className="input_fild_main w-100 row px-0 mx-0">
                                        <div className="col-10 px-0">
                                            <input type={`${!showpassword.newpin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                                placeholder="Enter Pin" name='newPin' onChange={(e) => resetPIN.setFieldValue("newPin", e.target.value)} value={resetPIN.values.newPin}
                                                onInput={(e) => {
                                                    if (e.target.value.length > 4) {
                                                        e.target.value = e.target.value.slice(0, 4);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                            <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("newpin")}>
                                                {showpassword.newpin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}
                                            </button>
                                        </div>
                                    </div>
                                    <Errors formikfun={resetPIN} name={"newPin"} />
                                </div>
                                <div className="form-group mb-2 ">
                                    <label htmlFor="exampleInputEmail1" className='my-0'>Confirm New Pin</label>
                                    <div className="input_fild_main w-100 row px-0 mx-0">
                                        <div className="col-10 px-0">
                                            <input type={`${!showpassword.confirmpin ? "password" : "number"}`} className="form-control" id="exampleInputPassword1"
                                                placeholder="Enter Pin" name='confirmPin' onChange={(e) => resetPIN.setFieldValue("confirmPin", e.target.value)} value={resetPIN.values.confirmPin}
                                                onInput={(e) => {
                                                    if (e.target.value.length > 4) {
                                                        e.target.value = e.target.value.slice(0, 4);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-2 text-center d-flex justify-content-center align-items-center">
                                            <button type='button' style={{ border: "none", backgroundColor: "transparent" }} onClick={() => showhide("confirmpin")}>
                                                {showpassword.confirmpin ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash" ></i>}
                                            </button>

                                        </div>
                                    </div>
                                    <Errors formikfun={resetPIN} name={"confirmPin"} />
                                </div>
                                <div className="form-group mb-0 w-100 text-center    ">
                                    <button type="submit" className=" btn-login shadow mx-auto mt-4 px-5"  >save</button>
                                    <p className='text-center mt-3 ' style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => { setShowPage(1); setUserForgotInfo({}) }} >Back to <span className='text-dark'>Login</span></p>
                                </div>
                            </form>
                        </div>
                    </motion.div>
                }
            </div>
        </>
    )
}

export default Login
