
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getlocalStorage } from "../../utils";
const Page404 = () => {
    const navigate = useNavigate()
    const userData = getlocalStorage("userInfodata")
    return <>
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 d-flex align-items-center pt-5">
                        <div>
                            <div className="col-lg-12">
                                <h style={{ color: "#2b124c", fontSize: "5.5rem" }} >
                                    <b>Oops!</b>
                                </h>
                            </div>
                            <div className="col-lg-12">
                                <p className="fs-3">
                                    We can't seem to find what you are looking for.
                                </p>
                            </div>
                            <div className="mt-5 d-flex">
                                <Button variant="contained" className="ms-2" onClick={() => { navigate("/") }}>{`Go Back ${userData?.userId ? "Dashboard" : "Home"}`}</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 pt-5 d-flex align-items-end">
                        <img src={require("../../Assets/animation-example.gif")} className="img-fluid mt-5" alt="" />
                    </div>
                </div>
            </div>
        </>
    </>;
};

export default Page404;
