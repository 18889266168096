import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { countryValidation } from '../../Validation/Validation';
import { Errors } from '../../Component/ErrorShow';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import Loader from '../../Component/Loading'
import { COUNTRY_LIST, EDIT_COUNTRY } from '../../Repository/CountryRepo';
import { FaArrowLeft } from 'react-icons/fa';
const Edit = () => {
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const { state } = useLocation();
    useEffect(() => {
        getCountryId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getCountryId = async () => {
        setLoading(true)
        let payload = { countryId: +id, activeStatus: true, }
        try {
            let response = await COUNTRY_LIST(payload)
            formik.setValues(response.data)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }
    const formik = useFormik({
        initialValues: {
            countryName: "",
            abbr: ""
        },
        validationSchema: countryValidation,
        onSubmit: async (values) => {
            setLoading(true)
            let payload = {
                countryId: +id,
                countryName: values.countryName,
                abbr: values?.abbr
            }
            try {
                let response = await EDIT_COUNTRY(payload)
                toast.success(response.message)
                formik.resetForm()
                navigate("/layout/country", { state: state })
                setLoading(false)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })
    return (
        <>
            {loading && <Loader />}
            <section className='d-flex align-items-center'>
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h4 className='PAGE-NAME'>Update Country</h4>
            </section>
            <div className="container-fluid shadow">
                <form className='mt-3' onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1" className='my-3'>Country<span className='Star_Mark'>*</span></label>
                            <TextField id="outlined-basic" variant="outlined" className='w-100' size='small'
                                value={formik.values.countryName} placeholder="Enter country"
                                onChange={(e) => formik.setFieldValue("countryName", e.target.value)}
                                onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                            />
                            <Errors formikfun={formik} name={"countryName"} />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1" className='my-3'>Abbreviation<span className='Star_Mark'>*</span></label>
                            <TextField id="outlined-basic" variant="outlined" className='w-100' size='small'
                                value={formik.values.abbr} placeholder="Enter abbreviation"
                                onChange={(e) => formik.setFieldValue("abbr", e.target.value)}
                                onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                            />
                            <Errors formikfun={formik} name={"abbr"} />
                        </div>
                        <div className="col-12 text-end">
                            <Button type="submit" variant="contained" className='me-2 mui_button_color  mt-4 mb-2' >update</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Edit

