import React, { useState } from 'react'

const Index = () => {
  const [draggedItem] = useState('Item 1');
  const [dropZoneContent, setDropZoneContent] = useState(Array(5).fill(null));

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', draggedItem);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const droppedItem = e.dataTransfer.getData('text/plain');
    const updatedContent = [...dropZoneContent];
    updatedContent[index] = droppedItem;
    setDropZoneContent(updatedContent);
  };
  return <>
   <div>
      <div
        draggable
        onDragStart={handleDragStart}
        style={{
          padding: '8px',
          border: '1px solid #ccc',
          margin: '4px',
          cursor: 'grab',
          backgroundColor: '#e0e0e0',
          display: 'inline-block',
        }}
      >
        {draggedItem}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
        {dropZoneContent.map((content, index) => (
          <div
            key={index}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            style={{
              width: '100px',
              height: '100px',
              border: '2px dashed #ccc',
              margin: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {content ? content : 'Drop Here'}
          </div>
        ))}
      </div>
    </div>
  </>
}

export default Index
