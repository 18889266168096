import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { viewfaqs } from '../../services/Api'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { commonMessages } from '../../Constants/Constants';
import { Button } from '@mui/material';

const View = () => {
    const [view, setView] = useState([])
    const navigate = useNavigate()
    const { id } = useParams()
    useEffect(() => {
        getviewfaqs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 

    const getviewfaqs = () => {
        axios.post(viewfaqs(), { faqsId: id }).then((res) => {
            if (res.data.status === "200") {
                setView(res.data.data)
            } else {
                toast.error(res.data.message)
            }

        }).catch(() => {
            toast.error(commonMessages.NetworkError)
        })
    }
    return (
        <>
            <ToastContainer />
            <div className="container-fluid shadow p-4">
                <h4>Question</h4>
                <p style={{ color: "gray" }}>{view[0]?.question}</p>
                <h4>Answer</h4>
                <p style={{ color: "gray" }}>{view[0]?.answer}</p>
                <Button type="submit" onClick={() => navigate("/faq")} className="my-3 mui_button_color">Back</Button>
            </div>
        </>
    )
}

export default View
