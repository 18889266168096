import React from 'react'
import PaginationCom from '../../../Component/pagination'
import Dialogs from '../../../Component/Dialog'
import useServices from './Hooks/useListServices';
import AddIcon from '@mui/icons-material/Add';
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import useTableSorting from '../../../Hooks/useTableSorting';
import Loading from '../../../Component/Loading';
import NoRecordFound from '../../../Component/NoRecordFound';
import userLogo from "../../../Assets/dummyimg.png"
import { ServiceImageFilePath } from '../../../services/Api';
import { BiArchiveIn } from 'react-icons/bi';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getlocalStorage, intialRole } from '../../../utils';
import { FaArrowLeft } from 'react-icons/fa6';
const List = () => {
    const data = useServices()
    const {
        open,
        servicesList,
        loading,
        controller,
        paginationTotalCount,
        DeletedData,
        dailogAllMessage,
        reset,
        setController,
        naviget,
        setOpen,
        handleClose,
        setDeletedData,
        headCells,
        handleChangeFilter, Filter, HandleSearchList
    } = data
    const userData = getlocalStorage("userInfodata")
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    if (!intialRole({ permission: "Services", userData: userData, section: 'EditServices' }) && !intialRole({ permission: "Expertise", userData: userData, section: "AbletoActive/In-ActiveServices" })) {
        headCells.pop()
    }
    return (
        <>
            {loading && <Loading />}
            <div className="row">
                <div className="col-12 d-flex align-items-center ">
                    <FaArrowLeft className='BACK_ICON' onClick={() => naviget(-1)} />
                    <h3 className='PAGE-NAME'>Services</h3>
                </div>
            </div>
            <div className="container-fluid">
                <section className='d-flex justify-content-between row'>
                    <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
                        {
                            intialRole({ permission: "Services", userData: userData, section: 'ViewServices' }) ?
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='w-100'
                                            size='small'
                                            name='archive'
                                            value={Filter?.archive}
                                            onChange={handleChangeFilter}
                                        >
                                            <MenuItem value={true}>Unarchive</MenuItem>
                                            <MenuItem value={false}>Archive</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="mt-3 mt-sm-0 col-lg-4 col-sm-6">
                                        <TextField id="outlined-basic" name='services' value={Filter?.services} onChange={handleChangeFilter} placeholder='Search service ' variant="outlined" className='w-100' size='small' />
                                    </div>
                                    <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                                        <Button variant="contained" className='me-2 mui_button_color' disabled={Filter?.disable_button} onClick={HandleSearchList}>search</Button>
                                        <Button variant="contained" className='mui_button_color' disabled={Filter?.disable_button} onClick={reset}>reset</Button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    {
                        intialRole({ permission: "Services", userData: userData, section: 'AddServices' }) ?
                            <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("add")}>
                                    Add Service
                                </Button>
                            </div>
                            : null
                    }
                </section>
                {
                    intialRole({ permission: "Services", userData: userData, section: 'ViewServices' }) ?
                        <>
                            <div className="row">
                                {
                                    servicesList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div>
                                }
                            </div>
                            <TableContainer className=" mt-4"  >
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={servicesList.length} />
                                    }
                                    <TableBody>
                                        {stableSort(servicesList, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={row?.serviceId}>
                                                    <TableRow id="hover_tr">
                                                        <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                            {row.image === null ? <img alt='' src={userLogo} width={45} height={45} style={{ borderRadius: "50%" }} /> : <img alt='' src={ServiceImageFilePath + row?.image} width={45} height={45} style={{ borderRadius: "50%" }} />}
                                                        </TableCell>
                                                        <TableCell id={labelId} align="left" >{row.serviceName}</TableCell>
                                                        <TableCell id={labelId} align="left" className={`${row?.variablePrice ? "py-0" : ""}`}> {<p className={`mb-0 ${row?.variablePrice ? "text-decoration-line-through" : ""}`}>{`${row.fixedPrice}`}</p>}
                                                            <p className="" style={{ marginBottom: "0px" }}>{`${row?.variablePrice === '' ? '' : ''}${row?.variablePrice ? row?.variablePrice : ""}`}</p></TableCell>
                                                        <TableCell id={labelId} align="left" style={{ whiteSpace: "wrap" }}>{row?.specializationName}</TableCell>
                                                        <TableCell id={labelId} align="left" style={{ whiteSpace: "wrap" }}>{`${row.description}`}</TableCell>
                                                        <TableCell id={labelId} align="right">
                                                            <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                            <ul className="dropdown-menu">
                                                                {
                                                                    intialRole({ permission: "Services", userData: userData, section: 'EditServices' }) ?
                                                                        <li><button className={`dropdown-item ${row?.activeStatus ? "" : "disabled"} `}
                                                                            onClick={() => {
                                                                                naviget(`edit/${row.serviceId}`)
                                                                            }} ><i className="fa-solid fa-pen-to-square all_icons_color me-2"></i>Edit Services</button>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    intialRole({ permission: "Services", userData: userData, section: 'AbletoActive/In-ActiveServices' }) ? <li><button className="dropdown-item"
                                                                        onClick={() => { setDeletedData(row); setOpen(!open) }}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus ? "Archive" : "Unarchive"} Services`}</button></li> : null
                                                                }
                                                            </ul>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <section className='w-100 d-flex justify-content-end'>
                                {
                                    paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </section>
                        </>
                        : null
                }
            </div>
            <Dialogs handleClose={handleClose} title={!DeletedData?.activeStatus ? dailogAllMessage?.Service?.UnArchive : dailogAllMessage?.Service?.Archive} open={open} data={DeletedData} />
        </>
    )
}

export default List
