import React from 'react'

const NoRecordFound = () => {
    // internetError
    // const network = JSON.parse(localStorage.getItem("network"))
    return (
        <>
            <div className='NoRecordFound'>
                <img src={require("../Assets/norecordfoundNew.1.png")} className='NoRecordFound_image' alt="" />
                {/* {
                    network ? <img src={require("../Assets/Images/internetError.png")} alt="" width={400} height={300} /> :
                        <img src={require("../Assets/Images/norecordfoundNew.1.png")} alt="" width={400} height={300} />
                } */}
                {/* {
                    network ? <h3 style={{ color: "gray", fontSize: "20px" }}>Not internet connection was found. Check <br /> your connection or  try again </h3> : <h3 style={{ color: "gray", fontSize: "20px" }}>No record found  </h3>
                } */}
                <h3 style={{ color: "gray", fontSize: "20px" }}>No record found  </h3>

            </div>

        </>
    )
}

export default NoRecordFound