import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Errors } from '../../Component/ErrorShow'
import { SelectBox, TextInput } from '../../Component/Inputs'
import Loader from '../../Component/Loading'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import { PolicyValidation } from '../../Validation/Validation'
import { useNavigate } from 'react-router-dom'
import { PlanTypeOption, RefundTypeOption, } from '../../Constants/Constants'
import { INSERT_CANCELLATION_POLICY } from '../../Repository/CancellationPolicyRepo'
import { FaArrowLeft } from 'react-icons/fa6'
const AddPolicy = () => {
    const [Loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            refundType: "",
            validity: "",
            days: "",
            cancellationCharger: "",
        },
        validationSchema: PolicyValidation,
        onSubmit: async (values) => {
            let payload = {
                ...values,
            }
            setLoading(true)
            try {
                let response = await INSERT_CANCELLATION_POLICY(payload)
                toast.success(response.message)
                setLoading(false)
                navigate("/layout/cancellation-policy")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })
    const handleChangeInput = (event) => {
        const { name, value } = event.target
        formik.setFieldValue(name, value)
    }

    return (
        <>
            {Loading && <Loader />}
            <section className='d-flex align-items-center'>
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'>Add Policy</h3>
            </section>
            <div className="container-fluid shadow py-3">
                <form className="row g-3 mt-4" onSubmit={formik.handleSubmit} >
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Refund Type<span className='Star_Mark'>*</span></label>
                        <SelectBox
                            placeholder="Select Title"
                            value={formik?.values?.refundType}
                            name="refundType"
                            keys="value"
                            label="label"
                            menuValeu="value"
                            handleChange={(e) => formik.setFieldValue("refundType", e.target.value)}
                            option={RefundTypeOption}
                        />
                        <Errors formikfun={formik} name={"refundType"} />
                    </div>

                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Validity<span className='Star_Mark'>*</span></label>
                        <SelectBox
                            placeholder="Select Title"
                            value={formik?.values?.validity}
                            name="validity"
                            keys="value"
                            label="label"
                            menuValeu="value"
                            handleChange={(e) => formik.setFieldValue("validity", e.target.value)}
                            option={PlanTypeOption}
                        />
                        <Errors formikfun={formik} name={"validity"} />
                    </div>
                    <div className="col-sm-6 mb-2 mt-0">
                        <label className="form-label mb-0">Days<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={10}
                            name="days"
                            value={formik.values.days}
                            type="number"
                            size='small'
                            minValue={0}
                        />
                        <Errors formikfun={formik} name={"days"} />
                    </div>
                    <div className="col-sm-6 mb-2 ms-0 mt-0 ">
                        <label className="form-label mb-0">Cancellation Charges<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={handleChangeInput}
                            maxLength={10}
                            name="cancellationCharger"
                            value={formik.values.cancellationCharger}
                            type="number"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"cancellationCharger"} />
                    </div>
                    <div className="col-12 text-end">
                        <Button variant="contained" type="submit" className="my-3 mui_button_color">Save</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddPolicy