import { axiosInstance } from "../Context/CreateAxios"
import { insertservice, listlistservice, updateservice, } from "../services/Api"
import handleError from "../utils/handleError"

export const SERICES_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listlistservice(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_SERICES = async (body) => {
    try {
        let response = await axiosInstance.post(updateservice(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_SERICES = async (body) => {
    try {
        let response = await axiosInstance.post(insertservice(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}