import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PaginationCom from '../../../Component/pagination'
import Dialogs from '../../../Component/Dialog'
import { toast } from 'react-toastify';
import { dailogAllMessage, permissionMessage } from '../../../Constants/Constants'
import { SpecializationImageFilePath, } from '../../../services/Api';
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, } from '@mui/material';
import useTableSorting from '../../../Hooks/useTableSorting';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../../../Component/Loading';
import NoRecordFound from '../../../Component/NoRecordFound';
import { EDIT_SPECIALIZATION, SPECIALIZATION_LIST } from '../../../Repository/Specialization';
import { BiArchiveIn } from 'react-icons/bi';
import userLogo from "../../../Assets/dummyimg.png"
import { getlocalStorage, intialRole } from '../../../utils';
import { FaArrowLeft } from 'react-icons/fa6';
const List = () => {
    const [open, setOpen] = useState(false);
    const [ListSpecialization, setListSpecialization] = useState([])
    const [DeletedData, setDeletedData] = useState({});
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [Filter, setFilter] = useState({
        archive: true,
        specialization: "",
        disable_button: true
    })
    const [loading, setLoading] = useState(false)
    const naviget = useNavigate()
    const userData = getlocalStorage("userInfodata")
    useEffect(() => {
        listspecializations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    const headCells = [
        { id: "Image", numeric: false, disablePadding: true, label: "Image", isShorted: false },
        { id: "specializationName", numeric: true, disablePadding: false, label: "Specialization", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    // const handleClose = (event, id, index) => {
    //     if (event === "YES") {
    //         setLoading(true)
    //         axiosInstance.post(deletespecialization(), { specializationId: id.toString() }).then((response) => {
    //             if (response.data.status === "200") {
    //                 toast.success(response.data.message)
    //                 setOpen(!open)
    //                 listspecializations()
    //             } else {
    //                 toast.error(response.data.message)
    //             }
    //             setLoading(false)
    //         }).catch(() => {
    //             toast.error(commonMessages.NetworkError)
    //             setLoading(false)
    //         })
    //     } else {
    //         setOpen(!open)
    //         setLoading(false)
    //     }
    // }
    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                specializationId: data?.specializationId,
                activeStatus: !data?.activeStatus
            }
            try {
                let response = await EDIT_SPECIALIZATION(payload)
                toast.success(response.message)
                setLoading(false)
                setOpen(false)
                if (ListSpecialization?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else listspecializations()
            } catch (error) {
                toast.error(error.message)
                setOpen(false)
                setLoading(false)
            }

        } else {
            setOpen(!open)
        }
    }
    async function listspecializations() {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, specialization } = Filter;
        const keyword = specialization.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
            providerId: userData?.userId
        };
        try {
            let response = await SPECIALIZATION_LIST(payload)
            setListSpecialization(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            if (error.message !== "No Specialization found") toast.error(error.message)
            setListSpecialization([])
            setPaginationTotalCount(0)
        }
        finally {
            setLoading(false)
        }
    }
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, specialization: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    if (!intialRole({ permission: "Expertise", userData: userData, section: 'EditSpecializationCategory' }) && !intialRole({ permission: "Expertise", userData: userData, section: "AbletoActive/In-ActiveSpecializationCategory" })) {
        headCells.pop()
    }

    return (
        <>
            {loading && <Loading />}
            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <FaArrowLeft className='BACK_ICON' onClick={() => naviget(-1)} />
                    <h3 className='PAGE-NAME'>Specialization</h3>
                </div>
            </div>
            <div className="container-fluid">
                <section className='d-flex justify-content-between row'>
                    <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
                        {
                            intialRole({ permission: "Expertise", userData: userData, section: 'ViewSpecializationCategory' }) ?
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='w-100'
                                            size='small'
                                            name='archive'
                                            value={Filter?.archive}
                                            onChange={(e) => handleChangeFilter(e)}
                                        >
                                            <MenuItem value={true}>Unarchive</MenuItem>
                                            <MenuItem value={false}>Archive</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="mt-3 mt-sm-0 col-lg-4 col-sm-6">
                                        <TextField id="outlined-basic" name='specialization' value={Filter?.specialization} onChange={handleChangeFilter} placeholder='Search specialization ' variant="outlined" className='w-100' size='small' />
                                    </div>
                                    <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                                        <Button variant="contained" disabled={Filter?.disable_button} className='me-2 mui_button_color' onClick={HandleSearchList}>search</Button>
                                        <Button variant="contained" disabled={Filter?.disable_button} className='mui_button_color' onClick={reset}>reset</Button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    {
                        intialRole({ permission: "Expertise", userData: userData, section: 'AddSpecializationCategory' }) ?
                            <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("add")}>
                                    Add Specialization
                                </Button>
                            </div>
                            : null
                    }
                </section>
                {
                    intialRole({ permission: "Expertise", userData: userData, section: 'ViewSpecializationCategory' }) ?
                        <>
                            <div className="row">
                                {
                                    ListSpecialization?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div>
                                }
                            </div>
                            <TableContainer className=" mt-4"  >
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={ListSpecialization.length} />
                                    }
                                    <TableBody>

                                        {stableSort(ListSpecialization, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={row?.specializationId}>
                                                    <TableRow id="hover_tr">
                                                        <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                            {row.image === null ? <img alt='' src={userLogo} width={45} height={45} style={{ borderRadius: "50%" }} /> : <img alt='' src={SpecializationImageFilePath + row?.image} width={45} height={45} style={{ borderRadius: "50%" }} />}
                                                        </TableCell>
                                                        <TableCell id={labelId} align="left" >{row.specializationName}</TableCell>
                                                        <TableCell id={labelId} align="right" >
                                                            {
                                                                intialRole({ permission: "Expertise", userData: userData, section: 'EditSpecializationCategory' }) ?
                                                                    row.activeStatus && <svg onClick={() => naviget(`edit/${row.specializationId}`)} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                    </svg>
                                                                    : null
                                                            }
                                                            {
                                                                intialRole({ permission: "Expertise", userData: userData, section: 'AbletoActive/In-ActiveSpecializationCategory' }) ? <BiArchiveIn title={`${row?.archive === false ? "Archive" : "Unarchive"}`} className="all_react_icons_color ms-2 " onClick={() => { setDeletedData(row); setOpen(!open) }} /> : null
                                                            }

                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <section className='w-100 d-flex justify-content-end'>
                                {
                                    paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </section>
                        </>
                        : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                }
            </div>
            <Dialogs handleClose={handleClose} title={!DeletedData?.activeStatus ? dailogAllMessage?.Specialization?.UnArchive : dailogAllMessage?.Specialization?.Archive} open={open} data={DeletedData} />

        </>
    )
}

export default List
