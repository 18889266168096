import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFormik } from 'formik';
import { setUpValidation } from '../../../Validation/Validation';
import { getlocalStorage } from '../../../utils';
import { EDIT_SETUP, INSERT_SETUP, SETUP_LIST } from '../../../Repository/SettingRepo';

const useSetup = (type, label, modalOpen) => {
    const [open, setOpen] = useState(false);
    const [setUpList, setSetupList] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [Filter, setFilter] = useState({
        archive: true,
        searchName: "",
        disable_button: true
    })
    const [openModal, setOpenModal] = useState({
        insertsymptom: false,
        insertAllergie: false,
        insertChronicDisease: false,
        insertTestRequired: false,
        archive: false,
        data: null
    })
    const [DeletedData, setDeletedData] = useState({});

    const naviget = useNavigate()
    const { state } = useLocation();
    const userData = getlocalStorage("userInfodata")
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "name", numeric: true, disablePadding: false, label: `${label} Name`, isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];

    // /////////////////////////// list allergies,symptom, chronic,text //////////////////////////

    async function getSetupList() {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, searchName } = Filter;
        const keyword = searchName.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: keyword ? undefined : page,
            count: keyword ? undefined : rowsPerPage,
            activeStatus: archive,
            providerId: userData?.userId,
            type,
        };
        try {
            let response = await SETUP_LIST(payload)
            setSetupList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoading(false)
        } catch (error) {
            if (error.message !== "Not Found") toast.error(error.message)
            setSetupList([])
            setPaginationTotalCount(0)
            setLoading(false)
        }
    }


    //////////////////////////// insert/update allergies,symptom, chronic,text /////////////////////////////

    const formik = useFormik({
        initialValues: {
            name: "",
            label
        },
        validationSchema: setUpValidation(label = label?.toLowerCase()),
        onSubmit: async (values) => {
            setLoading(true)
            let payload = {
                name: values?.name,
                type,
                providerId: openModal?.data ? undefined : userData?.userId,
                id: openModal?.data ? openModal?.data?.id : undefined
            }
            try {
                let response = openModal?.data ? await EDIT_SETUP(payload) : await INSERT_SETUP(payload)
                toast.success(response.message)
                setLoading(false)
                if (setUpList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getSetupList()

                handleClosedModal(modalOpen)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
    })

    ////////////////////////////// archive/unarchive allergies,symptom, chronic,text //////////////////////////
    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                activeStatus: !data.activeStatus,
                id: data?.id,
                type
            }
            try {
                let response = await EDIT_SETUP(payload)
                toast.success(response.message)
                setLoading(false)
                getSetupList()
                handleClosedModal("archive")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
        else {
            handleClosedModal("archive")
        }
    }
    const handleOpenModal = (type, data = null) => {
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
        if (data) {
            formik.setValues(data)
        }
    };
    const handleClosedModal = (type) => {
        formik.resetForm()
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }

    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, searchName: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    useEffect(() => {
        getSetupList()
        naviget({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])

    return {
        loading,
        paginationTotalCount,
        headCells,
        setUpList,
        controller,
        DeletedData,
        open,
        Filter,
        openModal,
        formik,
        HandleSearchList,
        setDeletedData,
        reset,
        naviget,
        setOpen,
        handleClose,
        setController,
        getSetupList,
        handleChangeFilter,
        handleOpenModal,
        handleClosedModal,
    }
}

export default useSetup