import { Button, Dialog, DialogContent, Slide, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PaginationCom from '../../Component/pagination';
import useTableSorting from '../../Hooks/useTableSorting';
// import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PAYMENT_HISTORY } from '../../Repository/SubcripstionPlanRepo';
import { toast } from 'react-toastify';
import NoRecordFound from '../../Component/NoRecordFound';
import Loading from '../../Component/Loading';
import moment from 'moment-timezone';
import { FaArrowLeft } from 'react-icons/fa';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const PaymentHistory = () => {
    const [historyList, setHistoryList] = useState([])
    const [loading, setLoading] = useState(false)
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [openModal, setOpenModal] = useState({
        data: null,
        viewHistory: false
    })
    const { state } = useLocation();
    const { id } = useParams()
    const navigate = useNavigate()
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10 } : state?.controller);
    const headCells = [
        { id: "currency", numeric: true, disablePadding: false, label: " Currency", isShorted: true },
        { id: "amount", numeric: true, disablePadding: false, label: "Amount", isShorted: true },
        { id: "Payment Mode", numeric: true, disablePadding: false, label: "Payment Mode", isShorted: true },
        { id: "Payment Date", numeric: true, disablePadding: false, label: "Payment Date", isShorted: true },
        { id: "Status", numeric: true, disablePadding: false, label: " Status", isShorted: true, showLeft: false, },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    async function getPaymentHistoryList() {
        setLoading(true)
        const { page, rowsPerPage } = controller;
        const payload = {
            page,
            count: rowsPerPage,
            providerId: id,
        };
        try {
            let response = await PAYMENT_HISTORY(payload)
            setHistoryList(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            toast.error(error.message)
            setHistoryList([])
            setPaginationTotalCount(0)
        } finally {
            setLoading(false)
        }
    }

    const handleClosedModal = (type) => {
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }

    // const handleOpenModal = (type, data) => {
    //     setOpenModal((prev) => ({
    //         ...prev,
    //         data,
    //         viewHistory: type === "viewHistory",
    //     }));
    // };

    useEffect(() => {
        getPaymentHistoryList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    return (
        <>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className=' d-flex align-items-center'>
                    <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                    <h3 className='PAGE-NAME'>Payment History</h3>
                </div>
                <div className="row">
                    {
                        historyList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                            <NoRecordFound />
                        </div>
                    }
                </div>
                <TableContainer className=" mt-4"   >
                    <Table
                        id="table_mui"
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table">
                        {
                            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={historyList.length} />
                        }
                        <TableBody>
                            {stableSort(historyList, getComparator(order, orderBy)).map(
                                (row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <React.Fragment key={index}>
                                        <TableRow id="hover_tr" >
                                            <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                {`${row?.currency}`}
                                            </TableCell>
                                            <TableCell id={labelId} align="left" > {row?.amount} </TableCell>
                                            <TableCell id={labelId} align="left" >{row?.paymentMethod}</TableCell>
                                            <TableCell id={labelId} align="left" >{row?.paymentDate ? moment(row?.paymentDate).format("DD/MM/YYYY") : "-"}</TableCell>
                                            <TableCell id={labelId} align="left" > {row?.paymentStatus}</TableCell>
                                            {/* <TableCell id={labelId} align="right" >
                                                <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <button className={`dropdown-item `} onClick={() => handleOpenModal("viewHistory", row)} >View History</button>
                                                    </li>
                                                </ul>
                                            </TableCell> */}
                                        </TableRow>
                                    </React.Fragment>
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <section className='w-100 d-flex justify-content-end'>
                    {
                        paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                    }
                </section>
                <Dialog
                    open={openModal.viewHistory}
                    keepMounted
                    TransitionComponent={Transition}
                    onClose={() => handleClosedModal("viewHistory")}
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogContent className='px-5'>
                        <div className="row">
                            <div className="col-12 text-center">
                                {/* <p>Assign Subscription Plan</p> */}
                            </div>
                            <div className="col-sm-6 mb-2 mt-0">
                                <label className="form-label mb-0">Start Date</label>
                                <div className="col-sm-12 mb-2  ">
                                    <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box' >
                                        <p>
                                            {openModal?.data?.startDate ? moment(openModal?.data?.startDate).format("DD/MM/YYYY") : '-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-2 mt-0">
                                <label className="form-label mb-0">End Date</label>
                                <div className="col-sm-12 mb-2  ">
                                    <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                                        <p>
                                            {openModal?.data?.endDate ? moment(openModal?.data?.endDate).format("DD/MM/YYYY") : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-2 mt-0">
                                <label className="form-label mb-0">Plan Type</label>
                                <div className="col-sm-12 mb-2  ">
                                    <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                                        <p>
                                            {openModal?.data?.planType}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-2 mt-0">
                                <label className="form-label mb-0">Price</label>
                                <div className="col-sm-12 mb-2  ">
                                    <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                                        <p>{openModal?.data?.price}</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-2 mt-0">
                                <label className="form-label mb-0"> No. Of Clinic</label>
                                <div className="col-sm-12 mb-2  ">
                                    <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                                        <p>{openModal?.data?.serviceNumber}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-2 mt-0">
                                <label className="form-label mb-0"> No. Of Service</label>
                                <div className="col-sm-12 mb-2  ">
                                    <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                                        <p>{openModal?.data?.serviceNumber}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-2 mt-0">
                                <label className="form-label mb-0">No. Of Provider</label>
                                <div className="col-sm-12 mb-2  ">
                                    <div className='d-flex w-100 align-items-center ps-2 py-1 custom_input_box'>
                                        <p>{openModal?.data?.providerNumber}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-end">
                                <Button onClick={() => handleClosedModal("viewHistory")} > Cancel</Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        </>
    )
}

export default PaymentHistory