import axios from 'axios'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { commonMessages } from '../../Constants/Constants'
import { FaqValidation } from '../../Validation/Validation'
import { ToastContainer, toast } from 'react-toastify';
import { getlistcountry, insertfaqs } from '../../services/Api'
import { Errors } from '../../Component/ErrorShow'
import { useNavigate } from 'react-router-dom'
import Loader from '../../Component/Loading'
import { Button } from '@mui/material'

const Add = () => {
    // const [listcountry, setListCountry] = useState([])
    const [Loading, setLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        listcountrys()
    }, [])

    function listcountrys() {
        axios.post(getlistcountry()).then((res) => {
            if (res.data.status === "200") {
                // setListCountry(res.data.data)
                // toast.success(res.data.message)

            } else {
                toast.error(res.data.message)
            }

        }).catch(() => {
            toast.error(commonMessages.NetworkError)
        })
    }

    const formik = useFormik({
        initialValues: {
            question: "",
            answer: ""
        },
        validationSchema: FaqValidation,
        onSubmit: (values) => {
            setLoading(true)
            axios.post(insertfaqs(), { question: values.question, answer: values.answer }).then((response) => {
                if (response.data.status === "200") {
                    toast.success(response.data.message)
                    formik.resetForm()
                    setLoading(false)
                    setTimeout(()=>navigate("/faq"))
                    
                } else {
                    toast.error(response.data.message)
                    setLoading(false)

                }
            }).catch(() => {
                toast.error(commonMessages.NetworkError)
                setLoading(false)

            })
        },
    })

    return (
        <>
            {Loading && <Loader />}
            <ToastContainer />
            <div className="container shadow">
                <form className="row g-3 mt-4" onSubmit={formik.handleSubmit}>
                    <div className="col-md-6">
                        <label htmlFor="inputEmail4" className="form-label">Question</label>
                        <input type="text" name='question' onChange={(e)=>formik.setFieldValue("question",e.target.value)} value={formik.values.question} className="form-control" id="exampleFormControlTextarea1" />
                        <Errors formikfun={formik} name={"question"} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputPassword4" className="form-label">Answer</label>
                        <textarea name='answer' onChange={(e)=>formik.setFieldValue("answer",e.target.value)} value={formik.values.answer} className="form-control" id="exampleFormControlTextarea1" rows="1"></textarea>
                        <Errors formikfun={formik} name={"answer"} />
                    </div>
                    <div className="col-4">
                    <Button type="button" className="my-3 mui_button_color" onClick={()=>navigate('/faq')}>Back</Button>
                        <Button type="submit" className="my-3 mui_button_color ms-2">save</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Add
