import { MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatNumber, getlocalStorage, intialRole } from '../../utils'
import { BiSolidDollarCircle } from "react-icons/bi";
import { FaCircleUser } from "react-icons/fa6";
import { HiCurrencyRupee } from "react-icons/hi2";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
// import { COUNTRY_LIST } from '../../Repository/CountryRepo';
import { toast } from 'react-toastify';
import Loading from '../../Component/Loading';
import { DASHBOARD_DETAILS, REPORT_GRAPH_DETAILS } from '../../Repository/DashboardRepo';
import moment from 'moment-timezone';
const Dashboard = () => {
  // const [listDatatacountry, setListDataCountry] = useState([])
  const [dashboardDetails, setDashboardDetails] = useState({})
  const [graphDetails, setgraphDetails] = useState([])
  const [filter, setFilter] = useState({
    country: "",
    paymentModes: "STRIPE",
    date: "MONTHLY"
  })
  const [loading, setLoading] = useState(false)
  const currentDate = moment().utc()
  const currentMonthStartDate = moment().startOf("month")?.utc()

  const currentStartDate = moment().startOf('day').utc();
  const currentEndDate = moment().endOf('day').utc();

  const currentYearStartDate = moment().startOf('year').utc();
  const currentYearEndDate = moment().endOf('year').utc();

  const lastMonthStartDate = moment().subtract(1, 'months').startOf('month').utc();
  const lastMonthEndDate = moment().subtract(1, 'months').endOf('month').utc();

  const getDateBydateFilter = (type) => {
    let date = { startDate: "", endDate: "" }
    if (type === "MONTHLY") {
      date.startDate = lastMonthStartDate
      date.endDate = lastMonthEndDate
    }
    if (type === "YEARLY") {
      date.startDate = currentYearStartDate
      date.endDate = currentYearEndDate
    }
    if (type === "DAILY") {
      date.startDate = currentStartDate
      date.endDate = currentEndDate
    }
    return date
  }
  const userData = getlocalStorage("userInfodata")


  // async function listcountrys() {
  //   setLoading(true)
  //   const payload = { activeStatus: true };
  //   try {
  //     let response = await COUNTRY_LIST(payload)
  //     setListDataCountry(response?.data)
  //     const findSelectedCountry = response?.data?.find((cur, index) => cur?.countryName.toLowerCase() === "india")
  //     setFilter((pre) => ({
  //       ...pre, country: findSelectedCountry?.id
  //     }))
  //   } catch (error) {
  //     toast.error(error.message)
  //     setListDataCountry([])
  //   }
  //   finally {
  //     setLoading(false)
  //   }
  // }
  const getDashboardDetails = async () => {
    setLoading(true)
    const payload = {
      // countryId: filter.country === "ALL_COUNTRY" ? undefined : filter.country,
      currentDateTime: currentDate,
      currentMonthStartDateTime: currentMonthStartDate,
      lastMonthStartDateTime: lastMonthStartDate
    };
    try {
      let response = await DASHBOARD_DETAILS(payload)
      setDashboardDetails(response?.data)
    } catch (error) {
      toast.error(error.message)
      setDashboardDetails({})
    }
    finally {
      setLoading(false)
    }
  }
  const getReportGraphDetails = async () => {
    let { startDate, endDate } = getDateBydateFilter(filter?.date)
    setLoading(true)
    const payload = {
      // countryId: filter.country === "ALL_COUNTRY" ? undefined : filter.country,
      startDateTime: startDate,
      endDateTime: endDate,
      dateFilter: filter?.date,
      paymentMeathodFilter: filter?.paymentModes
    };
    try {
      let response = await REPORT_GRAPH_DETAILS(payload)
      const formatString = filter.date === "MONTHLY" ? "MMM Do" : filter.date === "YEARLY" ? "YYYY" : "MMM Do";
      setgraphDetails(response?.data?.map(cur => ({
        ...cur,
        id: moment(cur?.id).format(formatString)
      })));
    } catch (error) {
      toast.error(error.message)
      setgraphDetails([])
    }
    finally {
      setLoading(false)
    }
  }

  const HnadleChangeCountry = (event) => {
    const { name, value } = event.target
    setFilter((pre) => ({
      ...pre, [name]: value
    }))
  }

  // useEffect(() => {
  //   listcountrys()
  // }, [])
  useEffect(() => {
    getDashboardDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getReportGraphDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const { activeUserCount, activeUserLeftCount, conversionUserCount, newSubscriberCount, paymentDetails, totalRevenuePhonepe, totalRevenueStripe } = dashboardDetails

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <h3 className='PAGE-NAME'>Dashboard</h3>
          </div>
        </div>
        {
          intialRole({ permission: "Dashboard", userData: userData, section: 'AbletoaccessDashboard' }) ?
            <>
              <section className='d-flex justify-content-between row'>
                {/* <div className='col-12 '>
            <div className="row d-flex justify-content-end ">
              <div className=" col-xxl-2 col-xl-4 col-md-5 col-sm-5 col-8 px-0">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className='w-100'
                  size='small'
                  name='country'
                  onChange={HnadleChangeCountry}
                  value={filter.country}
                >
                  {
                    listDatatacountry?.map((cur) => {
                      return <MenuItem key={cur?.id} value={cur?.id}>{cur?.countryName}</MenuItem>
                    })
                  }
                </Select>
              </div>
            </div>

          </div> */}
              </section>
              <div className="col-12 DASHBOARD">
                <div className="row ">
                  <div className="col-12 px-1">
                    <h3>Stripe Payement</h3>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3 ">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX PAYMENT">
                      <div className='SYMBAL'>
                        <BiSolidDollarCircle className='ICON' />
                      </div>
                      <div className='CONTENT'>
                        <h4 className='PRICE'>{formatNumber(paymentDetails?.totalRevenueStripe)}</h4>
                        <p className='TITLE'>Total Revenue Earned</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX PAYMENT">
                      <div className='SYMBAL'>
                        <BiSolidDollarCircle className='ICON' />
                      </div>
                      <div className='CONTENT'>
                        <h4 className='PRICE'>{formatNumber(paymentDetails?.totalRevenueStripe)}</h4>
                        <p className='TITLE'>Paid</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
              <div className="col py-3 shadow p-2 OVERVIEW_BOX PAYMENT">
                <div className='SYMBAL'>
                  <BiSolidDollarCircle className='ICON' />
                </div>
                <div className='CONTENT'>
                  <h4 className='PRICE'>{formatNumber(0)}</h4>
                  <p className='TITLE'>Refund</p>
                </div>
              </div>
            </div> */}
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX PAYMENT">
                      <div className='SYMBAL'>
                        <FaCircleUser className='ICON' />
                      </div>
                      <div className='CONTENT'>
                        <h4 className='PRICE'>{formatNumber(paymentDetails?.totalServiceProviders)}</h4>
                        <p className='TITLE'>Total Providers</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 px-1">
                    <h3>Razorpay Payement</h3>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3 ">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX PAYMENT">
                      <div className='SYMBAL'>
                        <HiCurrencyRupee className='ICON' />
                      </div>
                      <div className='CONTENT'>
                        <h4 className='PRICE'>{formatNumber(paymentDetails?.totalRevenuePhonepe)}</h4>
                        <p className='TITLE'>Total Revenue Earned</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX PAYMENT">
                      <div className='SYMBAL'>
                        <HiCurrencyRupee className='ICON' />
                      </div>
                      <div className='CONTENT'>
                        <h4 className='PRICE'>{formatNumber(paymentDetails?.totalRevenuePhonepe)}</h4>
                        <p className='TITLE'>Paid</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
              <div className="col py-3 shadow p-2 OVERVIEW_BOX PAYMENT">
                <div className='SYMBAL'>
                  <HiCurrencyRupee className='ICON' />
                </div>
                <div className='CONTENT'>
                  <h4 className='PRICE'>{formatNumber(0)}</h4>
                  <p className='TITLE'>Refund</p>
                </div>
              </div>
            </div> */}
                </div>
                <div className="row mt-3">
                  <div className="col-12 px-1">
                    <h3>Overview</h3>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3 ">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX OVERVIEW">
                      {/* <div className='PERCANTAGE RED '>
                  <p>10%</p>
                  <IoMdArrowDropdown className='ICON' />
                </div> */}
                      <p className='TITLE'>No. of New Subscribers</p>
                      <div className='row CONTENT mt-2'>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(newSubscriberCount?.thisMonthNewSubscriberCount)}</p>
                          <p>Current Month</p>
                        </div>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(newSubscriberCount?.lastMonthNewSubscriberCount)}</p>
                          <p>Last Month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX OVERVIEW">
                      {/* <div className='PERCANTAGE RED'>
                  <p>10%</p>
                  <IoMdArrowDropdown className='ICON' />
                </div> */}
                      <p className='TITLE'>No. of Active Users (Converted to provide)</p>
                      <div className='row CONTENT mt-2'>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(conversionUserCount?.thisMonthConversionCount)}</p>
                          <p>Current Month</p>
                        </div>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(conversionUserCount?.lastMonthConversionCount)}</p>
                          <p>Last Month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX OVERVIEW">
                      {/* <div className='PERCANTAGE GREEN'>
                  <p>10%</p>
                  <IoMdArrowDropup className='ICON' />
                </div> */}
                      <p className='TITLE'>No. of Active Users (Paid)</p>
                      <div className='row CONTENT mt-2'>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(activeUserCount?.thisMonthActiveUserCount)}</p>
                          <p>Current Month</p>
                        </div>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(activeUserCount?.lastMonthActiveUserCount)}</p>
                          <p>Last Month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX OVERVIEW">
                      {/* <div className='PERCANTAGE RED'>
                  <p>10%</p>
                  <IoMdArrowDropdown className='ICON' />
                </div> */}
                      <p className='TITLE'>No. of Active Users (Users Left)</p>
                      <div className='row CONTENT mt-2'>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(activeUserLeftCount?.thisMonthActiveUserLeftCount)}</p>
                          <p>Current Month</p>
                        </div>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(activeUserLeftCount?.lastMonthActiveUserLeftCount)}</p>
                          <p>Last Month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX OVERVIEW">
                      {/* <div className='PERCANTAGE GREEN'>
                  <p>10%</p>
                  <IoMdArrowDropup className='ICON' />
                </div> */}
                      <p className='TITLE'>Total Revenue Earned Stripe</p>
                      <div className='row CONTENT mt-2'>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(totalRevenueStripe?.thisMonthTotalRevenueStripe)}</p>
                          <p>Current Month</p>
                        </div>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(totalRevenueStripe?.pastMonthTotalRevenueStripe)}</p>
                          <p>Last Month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6  px-2 mb-3">
                    <div className="col py-3 shadow p-2 OVERVIEW_BOX OVERVIEW">
                      {/* <div className='PERCANTAGE RED'>
                  <p>10%</p>
                  <IoMdArrowDropdown className='ICON' />
                </div> */}
                      <p className='TITLE'>Total Revenue Earned Razorpay</p>
                      <div className='row CONTENT mt-2'>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(totalRevenuePhonepe?.thistMonthTotalRevenuePhonepe)}</p>
                          <p>Current Month</p>
                        </div>
                        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                          <p>{formatNumber(totalRevenuePhonepe?.pastMonthTotalRevenuePhonepe)}</p>
                          <p>Last Month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6 px-1">
                    <div className="row">
                      <div className="col-4"><h3>Growth</h3></div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col-6">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className='w-100'
                              size='small'
                              name='paymentModes'
                              value={filter?.paymentModes}
                              onChange={HnadleChangeCountry}
                            >
                              <MenuItem value="STRIPE">Stripe Payment</MenuItem>
                              <MenuItem value='RAZORPAY'>Razorpay Payment</MenuItem>
                            </Select>
                          </div>
                          <div className="col-6">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className='w-100'
                              size='small'
                              name='date'
                              value={filter?.date}
                              onChange={HnadleChangeCountry}
                            >
                              <MenuItem value="DAILY">Daily</MenuItem>
                              <MenuItem value="MONTHLY">Monthly</MenuItem>
                              <MenuItem value="YEARLY">Yearly</MenuItem>
                            </Select>
                          </div>

                        </div>
                      </div>
                      <div className="col-12">
                        <div style={{ width: '100%', height: 380 }}>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                              width={500}
                              height={200}
                              data={graphDetails}
                              margin={{
                                top: 20,
                                right: 1,
                                left: 1,
                                bottom: 5,
                              }}
                              barSize={20}
                            >
                              <defs>
                                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="0%" stopColor="#4B71B9" />
                                  <stop offset="100%" stopColor="#508eb0" />
                                </linearGradient>
                              </defs>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="id" />
                              <YAxis yAxisId="left" orientation="left" stroke="gray" />
                              <Tooltip />
                              <Tooltip />
                              <Bar yAxisId="left" dataKey="totalRevenue" fill="url(#gradient)" />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-1">
                  </div>

                </div>
              </div>
            </>
            : <div className='d-flex justify-content-center mt-5 me-5'><h5>You don't have permission to access</h5></div>
        }

      </div>
    </>
  )
}

export default Dashboard
