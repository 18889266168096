import { Tooltip } from '@mui/material';
import React from 'react'
import { RxCross2 } from 'react-icons/rx';

import useDeleteFile from '../Hooks/useDeleteFile';
const DeleteIcons = ({ setImgUrl, deletedData, isDeleted,setLoading }) => {
    const { deleteFileFun } = useDeleteFile(setImgUrl, deletedData, isDeleted,setLoading)
    return (
        <>
            <Tooltip title="Delete" placement="right" style={{ cursor: "pointer" }}>
                <span className="delete_icon_for_image" onClick={() => { deleteFileFun() }}><RxCross2 /></span>
            </Tooltip>
        </>
    )
}

export default DeleteIcons