import { axiosInstance } from "../Context/CreateAxios"
import {leadList, updateLead,insertLead, convertToProvider } from "../services/Api"
import handleError from "../utils/handleError"

export const LEAD_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(leadList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_LEAD = async (body) => {
    try {
        let response = await axiosInstance.post(insertLead(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_LEAD = async (body) => {
    try {
        let response = await axiosInstance.post(updateLead(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CONVERT_TO_PROVIDER = async (body) => {
    try {
        let response = await axiosInstance.post(convertToProvider(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}