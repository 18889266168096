/* eslint-disable no-useless-concat */
import * as Yup from 'yup';
export const pattern = {
  NAME: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
  NAMENumber: /^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
  CLAIMNUMBER: /^[ A-Za-z0-9]*$/,
  REPORTNAME: /^[ A-Za-z]*$/,
  CODE: /^[ A-Za-z_@./#&+-/'/"]*$/,
  DURATION: /^[0-9]{0,3}$/,
  PRICING: /^[0-9.]{0,30}$/,
  CITY: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
  EMAIL: /^(([^<>()\\[\]\\.,,:\s@"]+(\.[^<>()[\]\\.,,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  POSTAL_CODE: /^\d{5}-\d{4}|\d{4}|[A-Z]\d[A-Z] \d[A-Z]\d$/,
  PHONE_NO: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,4}$/,
  FIRM_NUMBER: /^[a-z0-9-]+$/,
  ALPHANUM: /^[a-zA-Z0-9]+$/,
  MOB_NO: /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$#!%*?&])[A-Za-z\d$@$!%*?&]{6,}/,
  DESCRIPTION: /^[ !@#$%^&*()~:;{}?'"=<>A-Za-z0-9_@./#&+-,-]*$/,
  REFNO: /^[ 0-9_@./#&+-,-]*$/,
  TASK_CODE: /^[0-9999]{1,4}$/,
  SUB_DOMAIN: /^[/a-z/A-Z][a-zA-Z0-9-]*[^/-/./0-9]$/,
  PHONE_NO_MASK: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  IVR_ACTION_KEY: /^[0-9]*$/,
  IVR_NUMBER: /^[1-9]*$/,
  RADIUS: /^[0-9]*(?:.)([0-9])+$/,
  LATLONG: /^\s*(\\-?\d+(\.\d+)?)$/,
  SSN: /^((\d{3}-?\d{2}-?\d{4})|(X{3}-?X{2}-?X{4}))$/,
  SSN_MASK: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  PRACTICE_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})/,
  USERNAME: /^[a-zA-Z0-9](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9]){1,14}[a-zA-Z0-9]$/,
  USERNAME_MIN_SIZ: /^[a-zA-Z0-9_](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9_]){4,18}[a-zA-Z0-9_]$/,
  WICARE_USERNAME: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{1,}/,
  YEAR_MASK: /d{4}/,
  DECIMAL: /\d+(\.\d{1,2})?/,
  NUMBERnDECIMAL: '^\\d+(\\.\\d+)?$',
  WHITESPACE: /^(?!\s).+(?<!\s)$/,
  MAXLENGTH: 50,
  MINLENGTH: 3,
  PASSWORDMINLENGTH: 6,
  PASSWORDMAXLENGTH: 15,
  MINIMUMVACANCY: 1,
  MAXIMUMVACANCY: 2,
  BACKSPACE: /^((?!\s{2,}).)*$/,
  URL: '^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$',
  PASSWORD_PIN: "(?=(.*?[0-9]){4})"
}
// login
export const Loginvalidation = Yup.object({
  userName: Yup.string().nullable().required("Please user name ").required("Please enter email address")
    .matches(pattern.EMAIL, "Please enter correct email"),
  password: Yup.string().required("Please enter PIN").matches(pattern.WHITESPACE, "White space is not required").min(4, "Minimum 4 digit ").matches(pattern.PASSWORD_PIN, "Please enter correct PIN"),
});

export const ForgotValidation = Yup.object({
  email: Yup.string().required("Please enter email address")
    .matches(pattern.EMAIL, "Please enter correct email")
});
export const VerifyOtpValidation = Yup.object({
  otp: Yup.string().trim().required("Please enter OTP").min(4, "Minimum 4 digit OTP").matches(pattern.PASSWORD_PIN, "Please enter correct OTP"),
});
export const ChangePinValidation = Yup.object({
  oldPin: Yup.string().required("Please enter old pin").matches(pattern.WHITESPACE, "White space is not required").matches(pattern.PASSWORD_PIN, "Please enter a numerical value"),
  newPin: Yup.string().required("Please enter new pin").matches(pattern.WHITESPACE, "White space is not required").matches(pattern.PASSWORD_PIN, "Please enter a numerical value"),
  confirmPin: Yup.string().required("Please enter confirm PIN").matches(pattern.WHITESPACE, "White space is not required").min(4, "Minimum 4 digit PIN").matches(pattern.PASSWORD_PIN, "Please enter a numerical value").oneOf(
    [Yup.ref("newPin"), null],
    "New PIN and confirm new PIN should match"
  ),
});
export const CreatePinValidation = Yup.object({
  newPin: Yup.string().required("Please enter new pin").matches(pattern.WHITESPACE, "White space is not required").matches(pattern.PASSWORD_PIN, "Please enter a numerical value"),
  confirmPin: Yup.string().required("Please enter confirm PIN").matches(pattern.WHITESPACE, "White space is not required").min(4, "Minimum 4 digit PIN").matches(pattern.PASSWORD_PIN, "Please enter a numerical value").oneOf(
    [Yup.ref("newPin"), null],
    "New PIN and confirm new PIN should match"
  ),
});
// country
export const countryValidation = Yup.object({
  countryName: Yup.string().nullable().required("Please enter country").matches(pattern.WHITESPACE, "White space is not required"),
  abbr: Yup.string().nullable().required("Please enter abbreviation").matches(pattern.WHITESPACE, "White space is not required")
})
// state
export const StateValidation = Yup.object({
  countryId: Yup.string().nullable().required("Please select country").matches(pattern.WHITESPACE, "White space is not required"),
  stateName: Yup.string().nullable().required("Please enter state").matches(pattern.WHITESPACE, "White space is not required"),
  abbr: Yup.string().nullable().required("Please enter abbreviation").matches(pattern.WHITESPACE, "White space is not required")
})
// specialization
export const SpecializationValidation = Yup.object({
  specialization: Yup.string().nullable().required("Please enter specialization").matches(pattern.WHITESPACE, "White space is not required")
})
//  Services
export const ServicesValidation = Yup.object({
  specializationId: Yup.array().nullable().min(1, "Please select specialization  "),
  serviceName: Yup.string().nullable().trim("White space is not required").strict(true).required("Please enter services name"),
  fixedPrice: Yup.string().nullable().required("Please enter price").test('greater-than-or-equal-to-zero', 'Fixed price  must be greater than zero or equal to zero', value => {
    const numericValue = Number(value);
    return !isNaN(numericValue) && numericValue >= 0;
  }),
  variablePrice: Yup.string().nullable().matches(pattern.WHITESPACE, "White space is not required").test('greater-than-or-equal-to-zero', 'Variable price  must be greater than zero or equal to zero', value => {
    const numericValue = Number(value);
    if (value) {
      return !isNaN(numericValue) && numericValue >= 0;
    } else {
      return true
    }
  }),
  description: Yup.string().nullable().trim("White space is not required").strict(true).required("Please enter description"),
  duration: Yup.string().nullable().required("Please select duration"),
  noOfPatients: Yup.number().nullable().required("Please enter number ").moreThan(0, 'Number must be greater than zero'),
})
// Blog
export const BlogValidation = Yup.object({
  image: Yup.string().nullable().required("Please select image").matches(pattern.WHITESPACE, "White space is not required"),
  description: Yup.string().nullable().required("Please enter description").matches(pattern.WHITESPACE, "White space is not required"),
  title: Yup.string().nullable().required("Please enter title").matches(pattern.WHITESPACE, "White space is not required")
})
// FAQ
export const FaqValidation = Yup.object({
  question: Yup.string().nullable().required("Please enter question").matches(pattern.WHITESPACE, "White space is not required"),
  answer: Yup.string().nullable().required("Please enter answer").matches(pattern.WHITESPACE, "White space is not required")
})
// category 
export const businessCategoryValidation = Yup.object({
  categoryName: Yup.string().nullable().required("Please enter category").matches(pattern.WHITESPACE, "White space is not required")
})
export const addLifeStyleValidation = Yup.object({
  lifeStyleName: Yup.string().nullable().required("Please enter habit").matches(pattern.WHITESPACE, "White space is not required"),
})

// Vaccination
export const CreaterecordValidation = Yup.object({
  category: Yup.string().nullable().required(" Please enter category").matches(pattern.WHITESPACE, "White space is not required"),
})
export const CreateCalendarFormValidation = Yup.object({
  calendarArray: Yup.array()
    .of(
      Yup.object().shape({
        vaccineName: Yup.string().nullable().required("Please enter vaccine name ").matches(pattern.WHITESPACE, "White space is not required"),
        brandName: Yup.string().nullable().required("Please enter  brand name ").matches(pattern.WHITESPACE, "White space is not required"),
        givenOn: Yup.string().nullable(),
        dueDate: Yup.string().nullable()
          .when('dueType', {
            is: (dueType) => dueType && dueType === "dueDate",
            then: Yup.string().nullable().required("Please enter due date  ").matches(pattern.WHITESPACE, "White space is not required"),
            otherwise: Yup.string().nullable().notRequired()
          }),
        dueType: Yup.string().nullable(),
        dueAge: Yup.string().nullable().when('dueType', {
          is: (dueType) => dueType && dueType === "dueAge",
          then: Yup.string().nullable().required("Please enter age  ").matches(pattern.WHITESPACE, "White space is not required").test('greater-than-or-equal-to-zero', 'Age  must be greater than zero', value => {
            const numericValue = Number(value);
            if (value) {
              return !isNaN(numericValue) && numericValue > 0;
            } else {
              return true
            }
          }),
          otherwise: Yup.string().nullable().notRequired()
        }),
        dueAgeDuration: Yup.string().nullable()
          .when('dueType', {
            is: (dueType) => dueType && dueType === "dueAge",
            then: Yup.string().nullable().required("Please select time frame"),
            otherwise: Yup.string().nullable().notRequired()
          }),
        comment: Yup.string().nullable().required("Please enter comment  ").matches(pattern.WHITESPACE, "White space is not required"),
        vaccinationSerialNumber: Yup.string().nullable().required("Please choose vaccination serial number"),
      })
    ),
  vaccinationFormName: Yup.string().nullable().required("Please enter form name").matches(pattern.WHITESPACE, "White space is not required"),
})

export const CreateSubcriptionPlanValidation = Yup.object({
  region: Yup.string().nullable().required("Please enter region"),
  planType: Yup.string().nullable().required("Please enter plan type"),
  title: Yup.string().nullable().required("Please enter title"),
  price: Yup.string().nullable().required("Please enter price").test('greater-than-or-equal-to-zero', 'Price must be greater than zero and equal to zero', value => {
    const numericValue = Number(value);
    return !isNaN(numericValue) && numericValue >= 0;
  }),
  discountType: Yup.string().nullable().required("Please select  discount type "),
  discount: Yup.string().nullable().when('discountType', {
    is: (discountType) => discountType,
    then: Yup.string().nullable().required("Please enter discount Value").matches(pattern.WHITESPACE, "White space is not required"),
    otherwise: Yup.string().nullable().notRequired()
  }),
})

export const updateProfileValidation = Yup.object({
  mobile: Yup.string().nullable().required("Please enter mobile number"),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  email: Yup.string().nullable().required("Please enter email").matches(pattern.EMAIL, "Please enter correct email")
})

export const AddTeamValidation = Yup.object({
  mobile: Yup.string().nullable().required("Please enter mobile number"),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  email: Yup.string().nullable().required("Please enter email").matches(pattern.EMAIL, "Please enter correct email"),
  roleId: Yup.string().nullable().required("Please select role")
})
export const AddLeadValidation = Yup.object({
  title: Yup.string().nullable().required("Please select title"),
  mobile: Yup.string().nullable().required("Please enter mobile number"),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  providerName: Yup.string().nullable().matches(pattern.WHITESPACE, "White space is not required"),
  businessCategoryId: Yup.string().nullable().matches(pattern.WHITESPACE, "White space is not required"),
  description: Yup.string().nullable().required("Please enter description").matches(pattern.WHITESPACE, "White space is not required"),
  email: Yup.string().nullable().required("Please enter email").matches(pattern.EMAIL, "Please enter correct email")
})
export const convertProviderValidation = Yup.object({
  title: Yup.string().nullable().required("Please select title"),
  mobile: Yup.string().nullable().required("Please enter mobile number"),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  providerName: Yup.string().nullable().required("Please enter provider name").matches(pattern.WHITESPACE, "White space is not required"),
  businessCategoryId: Yup.string().nullable().required("Please select category ").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  email: Yup.string().nullable().required("Please enter email").matches(pattern.EMAIL, "Please enter correct email")
})

export const businesscDetailsValidation = Yup.object({
  businessName: Yup.string().nullable().required("Please enter business name").matches(pattern.WHITESPACE, "White space is not required"),
  businessCategoryId: Yup.string().nullable().required("Please select category ").matches(pattern.WHITESPACE, "White space is not required"),
  about: Yup.string().nullable().required("Please enter about us ").trim("White space is not required").strict(true),
});
export const basicdetailsValidation = Yup.object({
  title: Yup.string().nullable().required("Please select title"),
  mobile: Yup.string().nullable().required("Please enter mobile number"),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  email: Yup.string().nullable().required("Please enter email").matches(pattern.EMAIL, "Please enter correct email")
});



export const leadAssignValidation = Yup.object({
  teamMember: Yup.string().nullable().required("Please select team member"),

})
export const assignPlanValidation = Yup.object({
  subscriptionPlan: Yup.string().nullable().required("Please select subscription plan"),

})

export const setUpValidation = (label) => Yup.object({
  name: Yup.string().nullable().required(`Please enter ${label}`).matches(pattern.WHITESPACE, "White space is not required"),
})

export const PolicyValidation = Yup.object({
  refundType: Yup.string().nullable().required("Please select refund type"),
  validity: Yup.string().nullable().required("Please select validity"),
  days: Yup.string().nullable().required("Please enter days").matches(pattern.WHITESPACE, "White space is not required").test('greater-than-or-equal-to-zero', 'Days must be greater than zero or equal to zero', value => {
    const numericValue = Number(value);
    return !isNaN(numericValue) && numericValue >= 0;
  }),
  cancellationCharger: Yup.string().nullable().required("Please enter cancellation charges").matches(pattern.WHITESPACE, "White space is not required").test('greater-than-or-equal-to-zero', 'Cancellation charges must be greater than zero or equal to zero', value => {
    const numericValue = Number(value);
    return !isNaN(numericValue) && numericValue >= 0;
  }),
})

export const rolesValidation = Yup.object({
  roleName: Yup.string().nullable().required("Please enter role name").matches(pattern.WHITESPACE, "White space is not required"),
})