import { axiosInstance } from "../Context/CreateAxios"
import { dashboardDetailsAPI, reportGraphAPI } from "../services/Api"
import handleError from "../utils/handleError"

export const DASHBOARD_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(dashboardDetailsAPI(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const REPORT_GRAPH_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(reportGraphAPI(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

