
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Button, TextField } from '@mui/material';
import { Errors } from '../../Component/ErrorShow';
import { businessCategoryValidation } from '../../Validation/Validation';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Component/Loading'
import { BUSINESS_CATEGORY_LIST, EDIT_BUSINESS_CATEGORY, } from '../../Repository/BusinessCategoryRepo';
import { FaArrowLeft } from 'react-icons/fa';
const Edit = () => {
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()

    const getbusinessCategoryDetails = async () => {
        setLoading(true)
        let payload = { businessCategoryId: +id, activeStatus: true, }
        try {
            let response = await BUSINESS_CATEGORY_LIST(payload)
            formik.setValues(response.data)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }
    const formik = useFormik({
        initialValues: {
            categoryName: ""
        },
        validationSchema: businessCategoryValidation,
        onSubmit: async (value) => {
            setLoading(true)
            let payload = {
                categoryName: value.categoryName,
                businessCategoryId: id
            }
            try {
                let response = await EDIT_BUSINESS_CATEGORY(payload)
                toast.success(response.message)
                formik.resetForm()
                setTimeout(() => navigate("/layout/business-category"), 1000)
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoading(false)
            }
        }
    })
    useEffect(() => {
        getbusinessCategoryDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading && <Loading />}
            <ToastContainer />
            <div className=' d-flex align-items-center'>
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'> Edit Business Category</h3>
            </div>
            <div className="container-fluid shadow">
                <form className="row g-3 mt-4" >
                    <div className="col-sm-6">
                        <label htmlFor="exampleInputEmail1" className='my-3'>Category<span className='Star_Mark'>*</span></label>
                        <TextField id="outlined-basic" variant="outlined" className='w-100' size='small'
                            value={formik?.values?.categoryName}
                            onChange={(e) => formik.setFieldValue("categoryName", e.target.value)}
                            onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                        />
                        <Errors formikfun={formik} name={"categoryName"} />
                    </div>
                    <div className="col-12 text-end">
                        <Button type="button" onClick={formik.handleSubmit} variant="contained" className='me-2 mui_button_color my-2' >update</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Edit