import { axiosInstance } from "../Context/CreateAxios"
import {addCancellationPolicy, cancellationPolicyList, updateCancellationPolicy } from "../services/Api"
import handleError from "../utils/handleError"

export const CANCELLATION_POLICY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(cancellationPolicyList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const INSERT_CANCELLATION_POLICY = async (body) => {
    try {
        let response = await axiosInstance.post(addCancellationPolicy(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_CANCELLATION_POLICY = async (body) => {
    try {
        let response = await axiosInstance.post(updateCancellationPolicy(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}