import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFormik } from 'formik';
import { CreaterecordValidation } from '../../../Validation/Validation';
import { EDIT_VACCINATION_CATEGORY, INSERT_VACCINATION_CATEGORY, VACCINATION_CATEGORY_LIST } from '../../../Repository/VaccinationRepo';
import { getlocalStorage } from '../../../utils';
import { RoleTitle } from '../../../Constants/Constants';

const useList = () => {
    const [open, setOpen] = useState(false);
    const [vaccinationCategoryList, setVaccinationCategoryList] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [Filter, setFilter] = useState({
        archive: true,
        country: "",
        category: "",
        disable_button: true
    })
    const [openModal, setOpenModal] = useState({
        insertCategory: false,
        archive: false,
        data: null
    })
    const [DeletedData, setDeletedData] = useState({});
    const naviget = useNavigate()
    const { state } = useLocation();
    const userData = getlocalStorage("userInfodata")
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "vaccinationCategoryName", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];

    // /////////////////////////// list category //////////////////////////
    async function getCategoryList() {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, category } = Filter;
        const keyword = category.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: keyword ? undefined : page,
            count: keyword ? undefined : rowsPerPage,
            activeStatus: archive,
            providerId: userData?.userId,
            [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
            roleTitle: userData?.rolesAndPermission?.roleName 
        };
        try {
            let response = await VACCINATION_CATEGORY_LIST(payload)
            setVaccinationCategoryList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoading(false)
        } catch (error) {
            if (error.message !== "No vaccination category found") toast.error(error.message)
            setVaccinationCategoryList([])
            setPaginationTotalCount(0)
            setLoading(false)
        }
    }

    const getVaccinationCategoryDetails = async (data) => {
        const payload = {
            providerId: userData?.userId,
            activeStatus: data?.activeStatus,
            vaccinationCategoryId: data?.vaccinationCategoryId,
            [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
            roleTitle: userData?.rolesAndPermission?.roleName
        };
        setLoading(true)
        try {
            let response = await VACCINATION_CATEGORY_LIST(payload)
            formik.setValues(response?.data)
            formik.setFieldValue("category", response?.data?.vaccinationCategoryName)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }

    //////////////////////////// insert category /////////////////////////////

    const formik = useFormik({
        initialValues: {
            category: ""
        },
        validationSchema: CreaterecordValidation,
        onSubmit: async (values) => {
            setLoading(true)
            let payload = {
                vaccinationCategoryName: values?.category,
                providerId: openModal?.data ? undefined : userData?.userId,
                vaccinationCategoryId: openModal?.data ? openModal?.data?.vaccinationCategoryId : undefined,
                [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
                roleTitle: userData?.rolesAndPermission?.roleName
            }
            try {
                let response = openModal?.data ? await EDIT_VACCINATION_CATEGORY(payload) : await INSERT_VACCINATION_CATEGORY(payload)
                toast.success(response.message)
                setLoading(false)
                getCategoryList()
                handleClosedModal("insertCategory")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
    })
    ////////////////////////////// archive/unarchive //////////////////////////

    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                activeStatus: !data.activeStatus,
                vaccinationCategoryId: data?.vaccinationCategoryId,
                [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
                roleTitle: userData?.rolesAndPermission?.roleName 
            }
            try {
                let response = await EDIT_VACCINATION_CATEGORY(payload)
                toast.success(response.message)
                setLoading(false)
                if (vaccinationCategoryList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getCategoryList()

                handleClosedModal("archive")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
        else {
            handleClosedModal("archive")
        }
    }

    useEffect(() => {
        getCategoryList()
        naviget({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])


    const handleOpenModal = (type, data = null) => {
        if (type === "insertCategory" && data) {
            getVaccinationCategoryDetails(data)
        }
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            insertCategory: type === "insertCategory",
            archive: type === "archive"
        }));
    };
    const handleClosedModal = (type) => {
        if (type === "insertCategory") {
            formik.resetForm()
        }
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }

    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, category: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }

    return {
        loading,
        paginationTotalCount,
        headCells,
        vaccinationCategoryList,
        controller,
        DeletedData,
        open,
        setDeletedData,
        reset,
        naviget,
        setOpen,
        handleClose,
        setController,
        getCategoryList,
        Filter,
        handleChangeFilter,
        openModal,
        handleOpenModal,
        handleClosedModal,
        formik,
        HandleSearchList
    }
}

export default useList