import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Dialogs(props) {

  return (
    <div>

      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
        fullWidth={true}
        
      >
        <DialogContent className='px-5'>
            <div className="row">
              <div className="col-12 text-center">
                <p>Are you sure?</p>
                <p>{props.title}</p>
              </div>
              <div className="col-12 text-end">
                <Button variant="outlined" onClick={() => props.handleClose('NO')} className=' mui_button_color me-3' size='small' > No</Button>
                <Button variant="contained" onClick={() => props.handleClose('YES', props?.data)} autoFocus className=' mui_button_color' size='small'>Yes</Button>
              </div>
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}