import { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { commonMessages } from '../../../../Constants/Constants'
import { ServicesValidation } from '../../../../Validation/Validation'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { SPECIALIZATION_LIST } from '../../../../Repository/Specialization';
import { INSERT_SERICES } from '../../../../Repository/ServicesRepo';
import useUploadImage from '../../../../Hooks/useUploadImage';
import { getlocalStorage } from '../../../../utils';
const useAddServices = () => {
    const [ListSpecialization, setListSpecialization] = useState([])
    const [Loading, setLoading] = useState(false)
    const [imgUrl, setImgUrl] = useState(null)
    const navigate = useNavigate()
    const { UploadImage } = useUploadImage("SERVICE", setLoading, setImgUrl)
    const userData = getlocalStorage("userInfodata")
    const FileRef = useRef()

    useEffect(() => {
        listSpecializationList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function listSpecializationList() {
        setLoading(true)
        let payload = {
            activeStatus: true,
            providerId: userData?.userId
        }
        try {
            let response = await SPECIALIZATION_LIST(payload)
            setListSpecialization(response?.data)
        } catch (error) {
            if (error.message === commonMessages?.NetworkError) {
                toast.error(error.message)
            }
            setListSpecialization([])
        }
        finally {
            setLoading(false)
        }
    }
    const formik = useFormik({
        initialValues: {
            serviceName: "",
            fixedPrice: "",
            variablePrice: "",
            description: "",
            isGroup: false,
            noOfPatients: "1",
            duration: "",
            specializationId: []
        },
        validationSchema: ServicesValidation,
        onSubmit: async (values) => {
            setLoading(true)
            let payload = {
                ...values,
                isGroup: Boolean(values?.isGroup),
                specializationId: values?.specializationId[0]?.specializationId,
                image: imgUrl
            }
            if (!values?.variablePrice.length) {
                payload.variablePrice =""
            }
            try {
                let response = await INSERT_SERICES(payload)
                toast.success(response.message)
                formik.resetForm()
                navigate("/layout/expertise/services")
            } catch (error) {
                toast.error(error.message)
            } finally {
                setLoading(false)
            }
        },
    })

    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "specializationId") {
            formik.setFieldValue(name, newValue)
        }
    }
    return {
        ListSpecialization,
        formik,
        Loading,
        FileRef,
        imgUrl,
        setImgUrl,
        handalSearchAbleSelectBox,
        UploadImage,
        setLoading,
        navigate
    }
}

export default useAddServices
