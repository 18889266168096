import { axiosInstance } from "../Context/CreateAxios"
import {insertTeam, resendInvitationEmailTeam, updatePhoneNumberApi, updateTeam } from "../services/Api"
import handleError from "../utils/handleError"

export const INSERT_TEAM = async (body) => {
    try {
        let response = await axiosInstance.post(insertTeam(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_TEAM = async (body) => {
    try {
        let response = await axiosInstance.post(updateTeam(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const RESEND_INVITATION_EMAIL_TEAM = async (body) => {
    try {
        let response = await axiosInstance.post(resendInvitationEmailTeam(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_STAFF_MOBILE_NUMBER = async (body) => {
    try {
        let response = await axiosInstance.post(updatePhoneNumberApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
