import { axiosInstance } from "../Context/CreateAxios"
import { getpermissionDetails, insertRolepermissions, permissionsList, roleList, updateRole, updateRolepermissions } from "../services/Api"
import handleError from "../utils/handleError"

export const PERMISSIONS_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(permissionsList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_ROLE_PERMISSIONS = async (body) => {
    try {
        let response = await axiosInstance.post(insertRolepermissions(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ROLE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(roleList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_ROLE_PERMISSIONS_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(getpermissionDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const EDIT_ROLE_PERMISSIONS = async (body) => {
    try {
        let response = await axiosInstance.post(updateRolepermissions(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const EDIT_ROLE = async (body) => {
    try {
        let response = await axiosInstance.post(updateRole(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}




