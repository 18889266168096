/* eslint-disable eqeqeq */
import React from 'react'
import { Errors } from '../../../Component/ErrorShow'
import Loader from '../../../Component/Loading'
import useEditServices from './Hooks/useEditServices';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { ServiceImageFilePath } from '../../../services/Api';
import { SearchAbleSelectBox, SelectBox, TextInput } from '../../../Component/Inputs';
import { durationOption } from '../../../Constants/Constants';
import DeleteIcons from '../../../Component/DeleteIcons';
import { FaArrowLeft } from 'react-icons/fa6';
const Edit = () => {
    const {
        ListSpecialization,
        formik,
        imgUrl,
        FileRef,
        Loading,
        id,
        setLoading,
        setImgUrl,
        UploadImage,
        navigate,
        handalSearchAbleSelectBox } = useEditServices()
    return (
        <>
            {Loading && <Loader />}
            <section className='d-flex align-items-center'>
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'> Update Service</h3>
            </section>
            <div className="ontainer-fluid shadow py-3 px-3">
                <form className="row g-3 mt-4" onSubmit={formik.handleSubmit}>
                    <div className="col-sm-12 m-auto">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-4 col-xl-3 col-sm-6 col-md-4  col-8">
                                <div className="bodrerer mb-4 d-flex justify-content-center align-items-center" style={{ border: `${!imgUrl ? "2px dashed #aaaaaa" : "none"}` }}>
                                    <label htmlFor="inputTag">
                                        <AiOutlineCloudUpload className="fs-4 " />
                                        {imgUrl && <img src={ServiceImageFilePath + imgUrl} alt="" />}
                                        <input ref={FileRef} id="inputTag" type="file" name="file" onChange={(e) => UploadImage(e, FileRef, imgUrl)} />
                                        <br /> Upload an Image<br />
                                        <span id="imageName"></span>
                                    </label>
                                    {imgUrl && <DeleteIcons setLoading={setLoading} setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "SERVICE", id: id }} isDeleted={true} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 mb-2">
                        <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={(e) => formik.setFieldValue("serviceName", e.target.value)}
                            maxLength={50}
                            name="serviceName"
                            value={formik.values.serviceName}
                            type="text"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"serviceName"} />
                    </div>
                    {/*  */}
                    <div className="col-sm-6 mb-2 ms-0">
                        <label className="form-label mb-0">Duration (in minutes)<span className='Star_Mark'>*</span></label>
                        <SelectBox
                            placeholder="Select Discount Type"
                            value={formik.values.duration}
                            name="duration"
                            keys="value"
                            label="label"
                            menuValeu="value"
                            handleChange={(e) => formik.setFieldValue("duration", e.target.value)}
                            option={durationOption}
                        />
                        <Errors formikfun={formik} name={"duration"} />
                    </div>
                    <div className="col-sm-6 mb-2 ms-0">
                        <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
                        <SearchAbleSelectBox
                            multiple={false}
                            option={ListSpecialization}
                            getOptionLabel="specializationName"
                            isOptionEqualToValue="specializationId"
                            value={formik?.values?.specializationId}
                            name="specializationId"
                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                        />

                        <Errors formikfun={formik} name={"specializationId"} />
                    </div>
                    <div className="col-sm-6 mt-3">
                        <label htmlFor="" className='form-label mb-0'>Group</label>
                        <div className="d-flex">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="isGroup"
                                row
                                value={formik.values.isGroup}
                                onChange={(e) => {
                                    formik.setFieldValue("isGroup", e.target.value);
                                    (e.target.value == "false" && formik.setFieldValue("noOfPatients", 1))
                                }}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3">
                        <label htmlFor="" className='form-label mb-0'>Number of patients</label>
                        <TextInput
                            disable={formik.values.isGroup == "true" ? false : true}
                            handleChange={(e) => formik.setFieldValue("noOfPatients", e.target.value)}
                            maxLength={12}
                            name="noOfPatients"
                            value={formik.values.noOfPatients}
                            type="number"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"noOfPatients"} />
                    </div>
                    {/*  */}
                    <div className="col-sm-6 mb-2">
                        <label className="form-label mb-0">Fixed Price<span className='Star_Mark'>*</span></label>
                        <TextInput
                            handleChange={(e) => formik.setFieldValue("fixedPrice", e.target.value)}
                            maxLength={12}
                            name="fixedPrice"
                            value={formik.values.fixedPrice}
                            type="number"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"fixedPrice"} />
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label className="form-label mb-0">Variable Price</label>
                        <TextInput
                            handleChange={(e) => formik.setFieldValue("variablePrice", e.target.value)}
                            maxLength={12}
                            name="variablePrice"
                            value={formik.values.variablePrice}
                            type="number"
                            size='small'
                        />
                        <Errors formikfun={formik} name={"variablePrice"} />
                    </div>
                    <div className="col-sm-12 mb-2">
                        <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
                        <textarea className="form-control INPUT-STYLE" cols="30" name="description" value={formik.values.description}
                            onChange={(e) => formik.setFieldValue("description", e.target.value)}>
                        </textarea>
                        <Errors formikfun={formik} name={"description"} />
                    </div>
                    <div className="col-12 text-end">
                        <Button variant="contained" type="submit" className="my-3 mui_button_color">update</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Edit