import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PaginationCom from '../../Component/pagination'
import Dialogs from '../../Component/Dialog'
import { toast } from 'react-toastify';
import { dailogAllMessage, permissionMessage } from '../../Constants/Constants'
import { Button, MenuItem, Select, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material'
import Loading from '../../Component/Loading'
import { Table } from 'react-bootstrap'
import useTableSorting from '../../Hooks/useTableSorting'
import AddIcon from '@mui/icons-material/Add';
import NoRecordFound from '../../Component/NoRecordFound'
import { BiArchiveIn } from 'react-icons/bi';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { getlocalStorage, intialRole } from '../../utils';
import { EDIT_ROLE, ROLE_LIST } from '../../Repository/Roles&PermissionRepo';
const List = () => {
    const [teamRoleList, setTeamRoleList] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [Filter, setFilter] = useState({
        archive: true,
        role: "",
        disable_button: true
    })
    const [openModal, setOpenModal] = useState({
        insertCategory: false,
        archive: false,
        data: null
    })
    const [loading, setLoading] = useState(false)
    const { state } = useLocation();
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
    const naviget = useNavigate()
    const userData = getlocalStorage("userInfodata")
    useEffect(() => {
        getRoleList()
        naviget({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    const headCells = [
        { id: "#", numeric: true, disablePadding: false, label: "S.No", isShorted: false },
        { id: "roleName", numeric: true, disablePadding: false, label: "Role Name", isShorted: true },
        { id: "Action", numeric: true, disablePadding: false, label: "Action", isShorted: false, showLeft: true, },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

    async function getRoleList() {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, role } = Filter;
        const keyword = role.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
        };

        try {
            let response = await ROLE_LIST(payload)
            setTeamRoleList(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            setTeamRoleList([])
            if (error.message !== "No roles found") toast.error(error.message)
            setPaginationTotalCount(0)
        } finally {
            setLoading(false)
        }
    }
    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                roleId: data?.roleId,
                activeStatus: !data?.activeStatus
            }
            try {
                let response = await EDIT_ROLE(payload)
                toast.success(response.message)
                setLoading(false)
                handleClosedModal("archive")
                if (teamRoleList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                }else getRoleList()
            
            } catch (error) {
                toast.error(error.message)
                handleClosedModal("archive")
                setLoading(false)
            }
        } else {
            handleClosedModal("archive")
        }
    }
    const handleOpenModal = (type, data = null) => {
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
    };
    const handleClosedModal = (type) => {
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }


    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, role: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    const editArchivePermission = (!intialRole({ permission: "Roles & permissions", userData: userData, section: 'EditRoles&Permissions' }) && !intialRole({ permission: "Roles & permissions", userData: userData, section: 'Active/In-Active' }))
    if (editArchivePermission) {
        headCells.pop()
    }
    return (
        <>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 px-0">
                        <h3 className='PAGE-NAME'>Roles</h3>
                    </div>
                </div>
                <section className='d-flex justify-content-between row'>
                    <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
                        {
                            intialRole({ permission: "Roles & permissions", userData: userData, section: 'AbletoviewRoles&PermissionsofSuperAdmin' }) ?
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='w-100'
                                            size='small'
                                            value={Filter?.archive}
                                            name='archive'
                                            onChange={handleChangeFilter}
                                        >
                                            <MenuItem value={true}>Unarchive</MenuItem>
                                            <MenuItem value={false}>Archive</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="mt-3 mt-sm-0 col-lg-4 col-sm-6">
                                        <TextField id="outlined-basic" name='role' value={Filter?.role} onChange={handleChangeFilter} placeholder='Search role.. ' variant="outlined" className='w-100' size='small' />
                                    </div>
                                    <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                                        <Button variant="contained" className='me-2 mui_button_color' disabled={Filter?.disable_button} onClick={HandleSearchList}>search</Button>
                                        <Button variant="contained" className='mui_button_color' disabled={Filter?.disable_button} onClick={reset}>reset</Button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    {
                        intialRole({ permission: "Roles & permissions", userData: userData, section: 'AbletoviewRoles&PermissionsofSuperAdmin' }) ?
                            <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                                <Button variant="contained" className='mui_button_color' onClick={() => naviget("add-roles")} startIcon={<AddIcon />}>
                                    add roles
                                </Button>
                            </div>
                            : null
                    }
                </section>
                {
                    intialRole({ permission: "Roles & permissions", userData: userData, section: 'AbletoviewRoles&PermissionsofSuperAdmin' }) ?
                        <>

                            <div className="row">
                                {
                                    teamRoleList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div>
                                }
                            </div>
                            <TableContainer className=" mt-4"  >
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={teamRoleList} />
                                    }
                                    <TableBody>

                                        {stableSort(teamRoleList, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={row?.roleId || index}>
                                                    <TableRow id="hover_tr" >
                                                        <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                            {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                        </TableCell>
                                                        <TableCell id={labelId} align="left" >{row?.roleName ? row?.roleName : "-"}</TableCell>
                                                        {
                                                            editArchivePermission ? null
                                                                :
                                                                <TableCell id={labelId} align="right" >
                                                                        <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                        <ul className="dropdown-menu">
                                                                            {
                                                                                intialRole({ permission: "Roles & permissions", userData: userData, section: 'EditRoles&Permissions' }) ?
                                                                                    <li>
                                                                                        <button className={`dropdown-item `} onClick={() => naviget(`edit-roles/${row?.roleId}`)} ><i className="fa-solid fa-pen-to-square all_icons_color me-2" />Edit Roles</button>
                                                                                    </li>
                                                                                    : null

                                                                            }
                                                                               {
                                                                                intialRole({ permission: "Roles & permissions", userData: userData, section: 'Active/In-Active' }) ?
                                                                                    <li><button className="dropdown-item" onClick={() => handleOpenModal("archive", row)}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus ? "Archive" : "Unarchive"} Role`}</button>
                                                                                    </li>
                                                                                    : null
                                                                            }
                                                                        </ul>
                                                                </TableCell>
                                                        }
                                                    </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <section className='w-100 d-flex justify-content-end'>
                                {
                                    paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </section>
                        </>
                        : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                }

            </div>
            <Dialogs handleClose={handleClose} title={openModal?.data?.activeStatus ? dailogAllMessage?.role?.Archive : dailogAllMessage?.role?.UnArchive} open={openModal?.archive} data={openModal?.data} />
        </>
    )
}
export default List