import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import useTableSorting from "../../Hooks/useTableSorting";
import Loading from "../../Component/Loading";
import NoRecordFound from "../../Component/NoRecordFound";
import useVaccinationList from "./Hooks/useVaccinationList";

const VaccinationList = () => {
    const { headCells, loader, vaccinationList } = useVaccinationList()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

    return (
        <>
            {loader && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-4">
                        <h4>Vaccination List</h4>
                    </div>
                </div>
                <div className="row">
                    {vaccinationList?.length === 0 &&
                        <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>
                    }
                    <Table
                        id="table_mui"
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                    >
                        {
                            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={vaccinationList?.length} />
                        }

                        <TableBody>
                            {stableSort(vaccinationList, getComparator(order, orderBy))?.map(
                                (row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <React.Fragment key={index}><TableRow hover id="hover_tr"  >
                                        <TableCell id={labelId} align="left" >{row?.vaccineName}</TableCell>
                                        <TableCell id={labelId} align="left"> {`${row?.givenOn ? row?.givenOn : "--"}`}</TableCell>
                                        <TableCell id={labelId} align="left"> {`${row?.dueDate ? row?.dueDate : "--"}`}</TableCell>
                                        <TableCell id={labelId} align="left"> {`${row?.age ? row?.age : "--"}${row?.timeFrame ? row?.timeFrame : ""} `}</TableCell>
                                        <TableCell id={labelId} align="left">{row?.comment}</TableCell>
                                    </TableRow>
                                    </React.Fragment>
                                }
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default VaccinationList;
