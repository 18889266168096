import { axiosInstance } from "../Context/CreateAxios"
import { insertbusinesscategories, listBusinessCategories, updateBusinessCategories } from "../services/Api"
import handleError from "../utils/handleError"

export const BUSINESS_CATEGORY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listBusinessCategories(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const EDIT_BUSINESS_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(updateBusinessCategories(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const INSERT_BUSINESS_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(insertbusinesscategories(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
