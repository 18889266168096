import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { countryValidation } from '../../Validation/Validation';
import { Errors } from '../../Component/ErrorShow';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import Loader from '../../Component/Loading'
import { INSERT_COUNTRY } from '../../Repository/CountryRepo';
import { FaArrowLeft } from 'react-icons/fa';
const Add = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            countryName: "",
            abbr: ""
        },
        validationSchema: countryValidation,
        onSubmit: async (values) => {
            let payload = {
                countryName: values.countryName,
                abbr: values?.abbr
            }
            setLoading(true)
            try {
                let response = await INSERT_COUNTRY(payload)
                toast.success(response.message)
                formik.resetForm()
                navigate("/layout/country")
                setLoading(false)
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })

    return (
        <>
            {loading && <Loader />}
            <section className='d-flex align-items-center'>
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h4 className='PAGE-NAME'>Add Country</h4>
            </section>
            <div className="container-fluid shadow">
                <form className='mt-3' onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1" className='my-3'>Country<span className='Star_Mark'>*</span></label>
                            <TextField id="outlined-basic" variant="outlined" className='w-100' size='small'
                                value={formik.values.countryName} placeholder="Enter country"
                                onChange={(e) => formik.setFieldValue("countryName", e.target.value)}
                                onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                            />
                            <Errors formikfun={formik} name={"countryName"} />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="exampleInputEmail1" className='my-3'>Abbreviation<span className='Star_Mark'>*</span></label>
                            <TextField id="outlined-basic" variant="outlined" className='w-100' size='small'
                                value={formik.values.abbr} placeholder="Enter abbreviation"
                                onChange={(e) => formik.setFieldValue("abbr", e.target.value)}
                                onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                            />

                            <Errors formikfun={formik} name={"abbr"} />
                        </div>
                        <div className="col-12 text-end">
                            <Button type="submit" variant="contained" className='me-2 mui_button_color mt-4 mb-2' >save</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Add
