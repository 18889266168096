
import React from 'react'
import useTableSorting from '../../Hooks/useTableSorting';
import Loading from '../../Component/Loading';
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import PaginationCom from '../../Component/pagination';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddIcon from '@mui/icons-material/Add';
import Dialogs from '../../Component/Dialog';
import { dailogAllMessage, permissionMessage } from '../../Constants/Constants';
import { BiArchiveIn } from 'react-icons/bi';
import NoRecordFound from '../../Component/NoRecordFound';
import useVaccinationCalendar from './Hooks/useVaccinationCalendar';
import { TbSwitchHorizontal } from "react-icons/tb";
import { getlocalStorage, intialRole } from '../../utils';
const VaccinationCalendar = () => {
    const {
        reset,
        setController,
        handleChangeFilter,
        handleOpenModal,
        naviget,
        loading,
        paginationTotalCount,
        headCells,
        vaccinationFormList,
        controller,
        Filter,
        openModal,
        handleClose,
        handlePublicForm,
        HandleSearchList
    } = useVaccinationCalendar()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const userData = getlocalStorage("userInfodata")
    return (
        <>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 px-0">
                        <h3 className='PAGE-NAME'>Vaccination Calendar</h3>
                    </div>
                </div>
                <section className='d-flex justify-content-between row'>
                    <div className='col-10  col-xxl-10 col-xl-10 col-lg-12   px-0 col-12'>
                        {
                            intialRole({ permission: "Vaccination calendar", userData: userData, section: 'ViewVaccinationCalendar' }) ?
                                <div className="row">
                                    <div className="col-sm-4 col-xxl-3 col-xl-3 col-lg-3 col-sm-4">
                                        <Select
                                            name='archive'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='w-100'
                                            size='small'
                                            value={Filter?.archive}
                                            onChange={handleChangeFilter}>
                                            <MenuItem value={true}>Unarchive</MenuItem>
                                            <MenuItem value={false}>Archive</MenuItem>
                                        </Select>
                                    </div>
                                    <div className=" col-sm-4 col-xxl-3 col-xl-3 col-lg-3 col-sm-4 mt-3 mt-sm-0">
                                        <Select
                                            name='publishStatus'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='w-100'
                                            size='small'
                                            value={Filter?.publishStatus}
                                            onChange={handleChangeFilter}>
                                            <MenuItem value={"ALL"}>All</MenuItem>
                                            <MenuItem value={false}>Draft</MenuItem>
                                            <MenuItem value={true}>Publish</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="mt-3 mt-sm-0  col-sm-4 col-xxl-3 col-xl-3 col-lg-3 col-sm-4">
                                        <TextField id="outlined-basic" value={Filter?.formName} name='formName' onChange={handleChangeFilter} placeholder='Search .. ' variant="outlined" className='w-100' size='small' />
                                    </div>
                                    <div className="col-xxl-3  col-xl-3 mt-3 mt-lg-0 col-lg-3 d-flex justify-content-lg-start  justify-content-end">
                                        <Button variant="contained" className='me-2 mui_button_color' disabled={Filter.disable_button} onClick={() => HandleSearchList()}>search</Button>
                                        <Button variant="contained" className='mui_button_color' disabled={Filter.disable_button} onClick={reset}>reset</Button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div className='col-xxl-2 col-xl-2 col-lg-12 col-12 d-flex justify-content-end pe-0 mt-3 mt-xl-0'>
                        {
                            intialRole({ permission: "Vaccination calendar", userData: userData, section: 'AddVaccinationCalendar' }) ?
                                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget(`add-vaccinaion-form`)}>
                                    Add Form
                                </Button>
                                : null
                        }
                    </div>
                </section>

                {
                    intialRole({ permission: "Vaccination calendar", userData: userData, section: 'ViewVaccinationCalendar' }) ?
                        <>
                            <div className="row">
                                {
                                    vaccinationFormList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <TableContainer className=" mt-4" style={{ minHeight: "300px" }} >
                                        <Table
                                            id="table_mui"
                                            aria-labelledby="tableTitle"
                                            size={"medium"}
                                            aria-label="enhanced table">
                                            {
                                                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={vaccinationFormList.length} />
                                            }
                                            <TableBody>
                                                {stableSort(vaccinationFormList, getComparator(order, orderBy)).map(
                                                    (row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return <React.Fragment key={row?.id || index}>
                                                            <TableRow id="hover_tr"
                                                                //  }
                                                                style={{ cursor: 'pointer' }}>
                                                                <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                                    {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                                </TableCell>
                                                                <TableCell id={labelId} align="left" >{row?.formName}</TableCell>
                                                                <TableCell id={labelId} align="left" >{row?.publishStatus ? "PUBLISH" : "DRAFT"}</TableCell>
                                                                <TableCell id={labelId} align="right"  >
                                                                    <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                    <ul className="dropdown-menu">
                                                                        {
                                                                            intialRole({ permission: "Vaccination calendar", userData: userData, section: 'EditVaccinationCalendar' }) ?
                                                                                <li><button className={`dropdown-item`} onClick={() => naviget(`update/${row?.vaccinationFormId}`, { state: { controller } })}><i className="fa-solid fa-pen-to-square all_icons_color me-2" />Edit Vaccination Form</button></li>
                                                                                : null
                                                                        }
                                                                        {
                                                                            intialRole({ permission: "Vaccination calendar", userData: userData, section: 'AbletoActive/In-ActiveVaccinationCalendar' }) ? <li><button className="dropdown-item"
                                                                                onClick={() => handleOpenModal("archive", row)}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus ? "Archive" : "Unarchive"} Vaccination Form`}</button>
                                                                            </li> : null
                                                                        }

                                                                        <li><button className="dropdown-item"
                                                                            onClick={() => handleOpenModal("public", row)}><TbSwitchHorizontal className="all_react_icons_color me-2" />{`${!row?.publishStatus ? " Publish" : "Draft"} Vaccination Form`}</button>
                                                                        </li>
                                                                    </ul>
                                                                </TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <section className='w-100 d-flex justify-content-end'>
                                {
                                    paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </section>
                        </>
                        : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                }
            </div>
            <Dialogs handleClose={handleClose} title={openModal?.data?.activeStatus ? dailogAllMessage?.vaccinationForm?.Archive : dailogAllMessage?.vaccinationForm?.UnArchive} open={openModal?.archive} data={openModal?.data} />
            <Dialogs handleClose={handlePublicForm} title={!openModal?.data?.publishStatus ? dailogAllMessage?.PublicVaccinationForm?.public : dailogAllMessage?.PublicVaccinationForm?.draft} open={openModal?.public} data={openModal?.data} />
        </>
    );
}

export default VaccinationCalendar
