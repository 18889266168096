import { axiosInstance } from "../Context/CreateAxios"
import {   timeZoneList } from "../services/Api"
import handleError from "../utils/handleError"

export const TIMEZONE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(timeZoneList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}